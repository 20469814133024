import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route, useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { useGlobalState } from '../../../state';
import { get } from '../../../services/Requests';
import Response from '../../../interfaces/Response';
import SimpleTheme from './Simple';
import Photographer_Client from './Client';
import { ThemeProvider } from 'styled-components';

export default function PortfolioView() {
    const params: any = useParams();
    const history = useHistory();
    const match = useRouteMatch();
    const [appLoading, setAppLoading] = useGlobalState('appLoading');
    const [portfolio, setPortfolio] = useGlobalState('portfolio');

    const [theme, setTheme] = useState({});

    useEffect(() => {
        setAppLoading(true);

        (async () => {
            try {
                let response: Response = await get(`portfolio/${params.slug}`, { useToken: false });
                setAppLoading(false);

                if (response.success) {
                    response = response as Response;

                    setPortfolio(response.data);
                    setTheme(response.data.template.colors);
                } else {
                    if (response.statusCode == 404) {
                        history.push('/');
                    }
                }
            } catch (err) {
                // TODO: handle error
            }
        })();
    }, []);

    const generateView = () => {
        switch (portfolio.template.theme.slug) {
            case 'simple':
                return <SimpleTheme />;
            default:
                return <div>Nie ma</div>;
        }
    };

    if (!portfolio.slug) return <div></div>;

    return (
        <ThemeProvider theme={theme}>
            <Router>
                <Switch>
                    <Route path={`${match.path}/client`}>
                        <Photographer_Client></Photographer_Client>
                    </Route>
                    <Route path={`${match.path}/`}>{generateView()}</Route>
                </Switch>
            </Router>
        </ThemeProvider>
    );
}
