import React from 'react';
import styled from 'styled-components';
import { useGlobalState } from '../../../../../state';
import { useLocation, NavLink } from 'react-router-dom';
import Photographer_Logo from '../../../../../domain/Photographer/Portfolio/components/Logo';
import Breakpoints from '../../../../../constants/Breakpoints';

const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;

    @media (max-width: ${Breakpoints.max.tablet}px) {
        flex-direction: column;
    }
`;

const NavContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin-left: 2em;

    @media (max-width: ${Breakpoints.max.tablet}px) {
        margin-top: 1em;
        flex-wrap: wrap;
        justify-content: center;
    }
`;

const NavElement = styled.div<any>`
    font-family: 'Roboto';
    margin-right: 1.5em;
    color: black;

    @media (max-width: ${Breakpoints.max.tablet}px) {
        margin-bottom: 0.5em;
    }
`;

const NavLinkStyled = styled(NavLink)`
    font-weight: 200;
    letter-spacing: 0.235px;

    &.is-active {
        font-weight: 400;
        letter-spacing: 0;
    }
`;

export default function SimpleTheme_Navbar() {
    const [portfolio] = useGlobalState('portfolio');
    const location = useLocation();

    return (
        <Container>
            <Photographer_Logo />
            <NavContainer>
                <NavLinkStyled exact activeClassName="is-active" to={`/user/${portfolio.slug}/`}>
                    <NavElement>portfolio</NavElement>
                </NavLinkStyled>
                <NavLinkStyled activeClassName="is-active" to={`/user/${portfolio.slug}/contact`}>
                    <NavElement>kontakt</NavElement>
                </NavLinkStyled>
                <NavLinkStyled activeClassName="is-active" to={`/user/${portfolio.slug}/calendar`}>
                    <NavElement>kalendarz</NavElement>
                </NavLinkStyled>
                <NavLinkStyled activeClassName="is-active" to={`/user/${portfolio.slug}/client`}>
                    <NavElement>strefa klienta</NavElement>
                </NavLinkStyled>
            </NavContainer>
        </Container>
    );
}
