import React, { useEffect, useRef, useState } from 'react';
import Heading from '../../../../../components/Heading';
import styled from 'styled-components';
import { useGlobalState } from '../../../../../state';
import { Tabs, Button, Table, message, Space, Popconfirm } from 'antd';
import deleteRequest from '../../../../../services/Requests/delete';
import CalendarConfigurationActivitiesAdd from './addType';
import { get } from '../../../../../services/Requests';

const Container = styled.div`
    margin-top: -1em;
`;

const Content = styled.div`
    margin-top: 2em;
`;

export default function PhotoshootsActivities() {
    const [appLoading, setAppLoading] = useGlobalState('appLoading');

    const [activities, setActivities] = useState([]);

    const [addTypeVisible, setAddTypeVisible] = useState(false);
    const [editType, setEditType]: [any, Function] = useState(false);

    const columns = [
        {
            title: 'Nazwa',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Opis',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Przybliżony czas sesji',
            dataIndex: 'duration',
            key: 'duration',
        },
        {
            title: 'Akcja',
            render: (data: any) => (
                <Space>
                    <Button size="small" onClick={() => handleEdit(data)}>
                        Edytuj
                    </Button>

                    <Popconfirm
                        title="Czy jesteś pewny/a, że chcesz usunąć tą aktywność?"
                        okText="Usuń"
                        cancelText="Anuluj"
                        onConfirm={() => handleDelete(data._id)}
                    >
                        <Button danger size="small">
                            Usuń
                        </Button>
                    </Popconfirm>
                </Space>
            ),
        },
    ];

    const handleDelete = async (_id: any) => {
        try {
            setAppLoading(true);

            let response = await deleteRequest(`photoshoots/types/${_id}`);

            if (!response.success) {
                message.error(response.message);
            } else {
                message.success('Pomyślnie usunięto aktywność.');
                loadTypes();
            }

            setAppLoading(false);
        } catch (err) {
            // TODO: HANDLE ERROR
        }
    };

    const handleEdit = (type: any) => {
        setEditType(type);
        setAddTypeVisible(true);
    };

    useEffect(() => {
        loadTypes();
    }, []);

    const loadTypes = () => {
        // Load added activities
        (async () => {
            try {
                setAppLoading(true);
                let response = await get('photoshoots/types');

                if (response.success) {
                    response = response as Response;
                    setActivities(response.data);
                    setAppLoading(false);
                }
            } catch (err) {
                // TODO: handle error
            }
        })();
    };

    const handleAddActivityClick = () => {
        setAddTypeVisible(true);
    };

    const hideModal = () => {
        setAddTypeVisible(false);
        setEditType(false);
    };

    return (
        <Container>
            <Heading size="small" weight="normal">
                Rodzaje sesji zdjęciowych
            </Heading>

            <Content>
                <div>
                    <Button onClick={handleAddActivityClick}>Dodaj rodzaj</Button>
                    <Table
                        dataSource={activities}
                        style={{ marginTop: 24 }}
                        pagination={false}
                        columns={columns}
                    ></Table>

                    <CalendarConfigurationActivitiesAdd
                        editType={editType}
                        loadTypesFunction={loadTypes}
                        hideModalFunction={hideModal}
                        visible={addTypeVisible}
                    />
                </div>
            </Content>
        </Container>
    );
}
