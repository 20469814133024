import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Colors from '../../../constants/Colors';
import LogoImage from '../../../resources/images/logo-blue_transparent.png';
import Heading from '../../../components/Heading';
import { Input, Button, Form, Col, Row, Alert } from 'antd';

import Shape_1 from '../../../domain/App/Login/resources/images/shape_1.png';
import Shape_2 from '../../../domain/App/Login/resources/images/shape_2.png';
import Breakpoints from '../../../constants/Breakpoints';
import UnderlinedText from '../../../components/UnderlinedText';
import { Store } from 'antd/lib/form/interface';

import { Link, useHistory } from 'react-router-dom';
import BackgroundElement from '../../../components/BackgroundElement';
import { post } from '../../../services/Requests';
import Response from '../../../interfaces/Response';

const { useForm } = Form;

const Container = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
`;

const LeftPanel = styled.div`
    width: 40%;
    height: 100%;
    background: #fff;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    position: relative;

    @media (max-width: ${Breakpoints.max.desktops}px) {
        width: 50%;
    }

    @media (max-width: ${Breakpoints.max.tablet}px) {
        width: 100%;
    }
`;

const RightPanel = styled.div`
    width: 60%;
    height: 100%;
    background: ${Colors.accents.main[500]};
    box-sizing: border-box;
    display: flex;
    align-items: center;
    position: relative;
    background: url('https://images.pexels.com/photos/610293/pexels-photo-610293.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940');
    background-position: 50% 0;
    background-repeat: no-repeat;
    background-size: cover;

    &::after {
        background-color: ${Colors.accents.main[500]};
        content: '';
        display: block;
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        z-index: 1;
        opacity: 0.9;
    }

    @media (max-width: ${Breakpoints.max.desktops}px) {
        width: 50%;
    }

    @media (max-width: ${Breakpoints.max.tablet}px) {
        display: none;
    }
`;

const LogoContainer = styled.div`
    width: 100%;
    padding: 32px;
    z-index: 5;
`;

const Logo = styled.img`
    width: 90px;
`;

const FormContainer = styled(Form)`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 5rem;
    z-index: 5;

    @media (max-width: ${Breakpoints.max.desktops}px) {
        padding: 0 2.5rem;
    }
`;

const InputsContainer = styled.div`
    margin: 2rem 0;
`;

const ButtonContainer = styled.div`
    margin-bottom: 2.5rem;
`;

const CatchPhraseContainer = styled.div`
    font-size: 3rem;
    font-weight: 500;
    font-family: 'Roboto';
    color: black;
    padding: 0 3rem;
    text-align: right;
    line-height: 3.7rem;
    z-index: 5;
`;

const SmallerPhraseContainer = styled.div`
    margin-top: 3rem;
`;

const SmallerPhrase = styled.div`
    font-size: 1.5rem;
    font-weight: 300;
    line-height: initial;
`;

export default function LoginView() {
    const [form] = useForm();
    const [loading, setLoading] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [success, setSuccess] = useState('');
    const history = useHistory();

    let timeout: number;

    useEffect(() => {
        return () => {
            clearTimeout(timeout);
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const formSubmit = async (values: any) => {
        setLoading(true);
        setDisabled(true);

        try {
            let response = await post('auth/login', values, { useToken: false });

            if (!response.success) {
                form.setFields([
                    // {
                    //     name: 'email',
                    //     errors: ['Konto z podanym adresem e-mail nie istnieje.'],
                    // },
                    {
                        name: 'password',
                        errors: ['Podane hasło jest niepoprawne.'],
                    },
                ]);

                setDisabled(false);
            } else {
                response = response as Response;
                const token = response.data.token;

                setSuccess('Pomyślnie zalogowano. Za chwilę nastąpi przekierowanie do aplikacji..');
                localStorage.setItem('jwt', token);

                timeout = setTimeout(() => {
                    history.push('/app');
                }, 2000);
            }

            setLoading(false);
        } catch (err) {
            // TODO: HANDLE ERROR
        }
    };

    return (
        <Container>
            <LeftPanel>
                <BackgroundElement left={0} top={560}>
                    <img alt="Kształt" src={Shape_1} />
                </BackgroundElement>
                <BackgroundElement right={100} top={0}>
                    <img alt="Kształt" src={Shape_2} />
                </BackgroundElement>
                <LogoContainer>
                    <Logo alt="Logo captic" src={LogoImage} />
                </LogoContainer>
                <FormContainer form={form} onFinish={formSubmit}>
                    <Heading>Zaloguj się</Heading>

                    {success && <Alert banner type="success" message={success} />}

                    <InputsContainer>
                        <Row gutter={[0, 12]}>
                            <Col span={24}>
                                <Form.Item
                                    name="email"
                                    help={null}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Musisz podać adres e-mail.',
                                        },
                                    ]}
                                >
                                    <Input disabled={disabled} placeholder="przyklad@email.pl" />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    name="password"
                                    help={null}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Musisz podać hasło.',
                                        },
                                    ]}
                                >
                                    <Input disabled={disabled} type="password" placeholder="Hasło" />
                                </Form.Item>
                                {/* <Link to="/app/password">Zapomniałeś/aś hasła?</Link> */}
                            </Col>
                        </Row>
                    </InputsContainer>

                    <ButtonContainer>
                        <Button
                            loading={loading}
                            disabled={disabled}
                            htmlType="submit"
                            type="primary"
                            size="large"
                            block={false}
                        >
                            Zaloguj
                        </Button>
                    </ButtonContainer>
                    <span>
                        Nie masz jeszcze konta? <Link to="/app/register">Zarejestruj się.</Link>
                    </span>
                </FormContainer>
            </LeftPanel>
            <RightPanel>
                <CatchPhraseContainer>
                    Praca fotografa jeszcze nigdy nie była taka przyjemna.
                    <SmallerPhraseContainer>
                        <SmallerPhrase>
                            <UnderlinedText>Zacznij już dziś.</UnderlinedText>
                        </SmallerPhrase>
                        <SmallerPhrase>
                            <UnderlinedText>Zaoszczędź czas i pieniądze.</UnderlinedText>
                        </SmallerPhrase>
                    </SmallerPhraseContainer>
                </CatchPhraseContainer>
            </RightPanel>
        </Container>
    );
}
