import React from 'react';
import styled from 'styled-components';

interface ShapesProps {
    left?: number;
    top?: number;
    right?: number;
    bottom?: number;
}

interface ShapesContainerProps {
    left?: string;
    right?: string;
    top?: string;
    bottom?: string;
}

const ShapesContainer = styled.div<ShapesContainerProps>`
    z-index: 0;
    position: absolute;
    left: ${(props: any) => props.left};
    top: ${(props: any) => props.top};
    right: ${(props: any) => props.right};
    bottom: ${(props: any) => props.bottom};
`;

export default function BackgroundElement(props: React.PropsWithChildren<ShapesProps>) {
    const left = typeof props.left === 'number' ? `${props.left}px` : 'initial';
    const right = typeof props.right === 'number' ? `${props.right}px` : 'initial';
    const bottom = typeof props.bottom === 'number' ? `${props.bottom}px` : 'initial';
    const top = typeof props.top === 'number' ? `${props.top}px` : 'initial';

    return (
        <ShapesContainer left={left} right={right} top={top} bottom={bottom}>
            {props.children}
        </ShapesContainer>
    );
}
