import { message } from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { post } from '../../../../../../services/Requests';
import { useGlobalState } from '../../../../../../state';

interface Client_ConfirmParams {
    id: string;
}

export default function Client_Confirm() {
    const match = useRouteMatch();
    const params: Client_ConfirmParams = useParams();
    const history = useHistory();

    const [appLoading, setAppLoading] = useGlobalState('appLoading');

    useEffect(() => {
        setAppLoading(true);

        if (typeof params.id != 'undefined') {
            (async () => {
                let success = true;

                const queryParams = new URLSearchParams(history.location.search);
                let token;

                if (queryParams.get('token') != null) {
                    token = queryParams.get('token');
                    let type = queryParams.get('type');

                    if (typeof type == 'undefined' || type == null) {
                        try {
                            let response = await post(
                                'conversations/confirm',
                                { message: params.id, token },
                                { useToken: false },
                            );

                            if (!response.success) {
                                message.error(`Nie udało się wysłać wiadomości: ${response.message}`);
                            } else {
                                message.success('Wiadomość została pomyślnie wysłana.');
                            }
                        } catch (err) {
                            message.error('Nie udało się wysłać wiadomości.');
                        }
                    } else if (type == 'photoshoot') {
                        try {
                            let response = await post(
                                'photoshoots/confirm',
                                { photoshoot: params.id, token },
                                { useToken: false },
                            );

                            if (!response.success) {
                                message.error(`Nie udało się potwierdzić rezerwacji: ${response.message}`);
                            } else {
                                message.success(
                                    `Rezerwacja została pomyślnie potwierdzona. ${
                                        response.data.isConfirmationRequired
                                            ? `Gdy fotograf zatwierdzi termin - zostaniesz o tym poinformowany/a.`
                                            : ''
                                    }`,
                                );
                            }
                        } catch (err) {
                            message.error('Nie udało się potwierdzić rezerwacji.');
                        }
                    }
                } else {
                    message.error('Nie udało się potwierdzić - brak tokenu.');
                }

                setAppLoading(false);
                history.push(`../client/`);
            })();
        }
    }, []);

    return <div></div>;
}
