import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Input, Checkbox, message } from 'antd';
import styled from 'styled-components';
import Label from '../../../../../../components/Label';
import Breakpoints from '../../../../../../constants/Breakpoints';
import { useGlobalState } from '../../../../../../state';
import { get } from '../../../../../../services/Requests';
import Response from '../../../../../../interfaces/Response';
import { Store } from 'antd/lib/form/interface';
import put from '../../../../../../services/Requests/put';
import validationHandler from '../../../../../../services/ValidationHandler';

const InputContainer = styled.div`
    max-width: ${Breakpoints.max.tablet}px;
`;

const CheckboxLabel = styled.span`
    font-weight: 500;
    margin-bottom: 0.7em;
`;

const Description = styled.div`
    font-weight: 400;
    font-size: 0.8em;
    margin-top: 0.6em;
`;

const { useForm } = Form;

export default function CalendarConfigurationMain() {
    const [form] = useForm();
    const [loading, setLoading] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [success, setSuccess] = useState('');

    const [appLoading, setAppLoading] = useGlobalState('appLoading');
    const [user] = useGlobalState('user');

    useEffect(() => {
        form.setFields([
            {
                name: 'listLongerPhotoshoots',
                value: user.calendar.settings.listLongerPhotoshoots ? ['true'] : [],
            },
            {
                name: 'isConfirmationRequired',
                value: user.calendar.settings.isConfirmationRequired ? ['true'] : [],
            },
            {
                name: 'useConfirmedPhotoshootsAsLimits',
                value: user.calendar.settings.useConfirmedPhotoshootsAsLimits ? ['true'] : [],
            },
        ]);
    }, []);

    const formSubmit = async (values: Store) => {
        setLoading(true);
        setDisabled(true);

        try {
            let response = await put('calendar', values);

            if (!response.success) {
                let errorMessage = validationHandler(response, form);
                // TODO: HANDLE ERROR

                setDisabled(false);
            } else {
                response = response as Response;

                message.success('Pomyślnie zaktualizowano ustawienia kalendarza.');
            }

            setLoading(false);
            setDisabled(false);
        } catch (err) {
            // TODO: HANDLE ERROR
        }
    };

    return (
        <Form id="calendar-configuration-main" form={form} onFinish={formSubmit}>
            <InputContainer>
                <Row gutter={[0, 12]}>
                    <Col span={24}>
                        <Form.Item name="listLongerPhotoshoots" help={null}>
                            <Checkbox.Group>
                                <Checkbox value="true" disabled={disabled}>
                                    <CheckboxLabel>
                                        Pozwalaj na rezerwację sesji dłuższej niż dostępny czas
                                    </CheckboxLabel>
                                </Checkbox>
                                <Description>
                                    Czy podczas rezerwacji, na liście rodzajów sesji mają być widoczne te sesje, które
                                    mają podany dłuższy czas trwania niż pozostały wolny czas tego dnia? Na przykład:
                                    sesja trwająca 60min nie pojawi się na liście (w przypadku opcji ustawionej na
                                    wyłączoną), gdy klient będzie próbował dokonać rezerwacji o godzinie 16:30, a
                                    kalendarz będzie ustawiony na koniec dnia o godzinie 17:00.
                                </Description>
                            </Checkbox.Group>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item name="isConfirmationRequired" help={null}>
                            <Checkbox.Group>
                                <Checkbox value="true" disabled={disabled}>
                                    <CheckboxLabel>Wymagaj potwierdzenia rezerwacji przez fotografa</CheckboxLabel>
                                </Checkbox>
                                <Description>Czy rezerwacja musi zostać potwierdzona przez fotografa?</Description>
                            </Checkbox.Group>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item name="useConfirmedPhotoshootsAsLimits" help={null}>
                            <Checkbox.Group>
                                <Checkbox value="true" disabled={disabled}>
                                    <CheckboxLabel>
                                        Nie pokazuj dostępności w terminach zaplanowanych sesji zdjęciowych
                                    </CheckboxLabel>
                                </Checkbox>
                                <Description>
                                    Czy terminy w których jest zaplanowana sesja zdjęciowa (potwierdzona) mają być
                                    niedostepne do rezerwacji? Czas trwania sesji zdjęciowej jest określny na podstawie
                                    rodzaju sesji.
                                </Description>
                            </Checkbox.Group>
                        </Form.Item>
                    </Col>
                </Row>
            </InputContainer>
        </Form>
    );
}
