import React from 'react';
import styled from 'styled-components';
import Colors from '../../../../../../constants/Colors';
import Gallery from '../../../../../../domain/Photographer/Portfolio/components/Gallery';
import { useGlobalState } from '../../../../../../state';

const Container = styled.div``;

const Image = styled.img`
    /* max-width: 200px;
    height: 100%; */
`;

const Disclaimer = styled.p`
    color: ${Colors.greys[700]};
    font-size: 0.8em;
    margin-top: 2em;
`;

export default function SimpleTheme_PortfolioView() {
    const [portfolio] = useGlobalState('portfolio');

    return (
        <Container>
            <Gallery images={portfolio.photos}></Gallery>
            {portfolio.slug == 'pawelgdak' && <Disclaimer>Wszystkie zdjęcia pochodzą ze strony pexels.com</Disclaimer>}
        </Container>
    );
}
