import React, { PropsWithChildren, useState } from 'react';
// import Masonry from 'react-masonry-component';
import { Masonry } from 'masonic';
import styled from 'styled-components';
import GalleryComponent from '../../../../../components/Gallery';

const ImageContainer = styled.div`
    /* padding: 24px; */

    & > img {
        width: 100%;
        cursor: pointer;
        transition: 0.1s all;

        &:hover {
            opacity: 0.9;
        }
    }
`;

const FullScreen = styled.div<any>`
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
    background: rgba(255, 255, 255, 0.8);
    display: flex;
    flex-direction: column;
`;

const FullScreenHeader = styled.div`
    height: 24px;
    display: flex;
    align-items: center;
`;

const FullScreenImageContainer = styled.div`
    padding: 24px;
    padding-top: 0;
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;

    & > img {
        max-height: 100%;
        max-width: 100%;
        object-fit: contain;
    }
`;

export default function Gallery(props: PropsWithChildren<any>) {
    const [fullScreenImage, setFullScreenImage] = useState('');

    const masonryOptions = {
        transitionDuration: 0,
    };

    const openFullScreen = (photo: any) => {
        setFullScreenImage(`${process.env.REACT_APP_API as string}/portfolio/photos/${photo._id}`);
    };

    const MasonryCard = ({ index, data, width }: { index: number; data: any; width: any }) => (
        <ImageContainer onClick={() => openFullScreen(data)}>
            <img src={`${process.env.REACT_APP_API as string}/portfolio/photos/${data.thumbnail._id}`} />
        </ImageContainer>
    );

    const fullScreenClose = (e: any) => {
        if (e.target.classList.contains('full-screen-image')) {
            return;
        }

        setFullScreenImage('');
    };

    return (
        <div>
            <GalleryComponent fullScreenImage={true} margin={6} lightBox={false} photos={props.images} />
        </div>
    );
}
