import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';

const Container = styled.div`
    font-weight: 500;
    margin-bottom: 0.7em;
`;

const Description = styled.div`
    font-weight: 400;
    font-size: 0.8em;
`;

interface LabelProps {
    description?: string | React.ReactNode;
}

export default function Label(props: PropsWithChildren<LabelProps>) {
    return (
        <Container>
            {props.children}
            {props.description && <Description>{props.description}</Description>}
        </Container>
    );
}
