import { ButtonSize } from 'antd/lib/button';
import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { useGlobalState } from '../../../../../state';

const Container = styled.div<any>`
    background-color: none;
    border: 3px solid rgba(0, 0, 0, 0.2);
    transition: 0.3s all;
    border-radius: 3px;

    &:hover {
        background-color: ${(props) => !props.disabled && props.theme.primary};
        border-color: ${(props) => !props.disabled && props.theme.primary};
    }
`;

const Button = styled.button<any>`
    background: none;
    outline: none;
    width: 100%;
    height: 100%;
    border: none;
    padding: ${(props) => (props.size === 'middle' ? '12px' : '4px 8px')};
    cursor: pointer;
    font-weight: 500;
    font-family: 'Roboto';
`;

interface SimpleTheme_ButtonComponentProps {
    onClick?: Function;
    disabled?: boolean;
    size?: ButtonSize;
}

export default function SimpleTheme_ButtonComponent(props: PropsWithChildren<SimpleTheme_ButtonComponentProps>) {
    const [portfolio] = useGlobalState('portfolio');

    let size = 'middle';
    if (typeof props.size != 'undefined') {
        size = props.size;
    }

    return (
        <Container size={size} disabled={props.disabled}>
            <Button size={size} disabled={props.disabled} type="submit" onClick={props.onClick}>
                {props.children}
            </Button>
        </Container>
    );
}
