import { ButtonSize } from 'antd/lib/button';
import React, { PropsWithChildren, useEffect, useLayoutEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import Colors from '../../../../../constants/Colors';
import { useOutsideAlerter } from '../../../../../hooks/useOutsideAlerter';
import { useGlobalState } from '../../../../../state';
import color from 'color';
import { FaArrowDown, FaChevronDown } from 'react-icons/fa';

const Container = styled.div<any>`
    background-color: none;
    border: 2px solid rgba(0, 0, 0, 0.2);
    transition: 0.3s all;
    border-radius: 3px;
    cursor: pointer;
    font-family: 'Roboto';
    position: relative;
    height: 36px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4px 8px;
    font-weight: 500;
    width: ${(props) => props.width};

    &:hover {
        background-color: ${(props) => !props.disabled && props.theme.primary};
        border-color: ${(props) => !props.disabled && props.theme.primary};
    }
`;

const Placeholder = styled.span`
    color: ${Colors.greys[800]};
`;

const OptionsContainer = styled.div<any>`
    position: absolute;
    top: 40px;
    left: 0;
    z-index: 9;
    flex-direction: column;
    /* border: 2px solid rgba(0, 0, 0, 0.2); */
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
    max-height: 250px;
    overflow: scroll;
    background: white;

    display: ${(props) => (props.visible ? 'flex' : 'none')};
`;

const Icon = styled(FaChevronDown)`
    color: ${Colors.greys[800]};
`;

const Option = styled.div<any>`
    padding: 8px 16px;
    /* border-bottom: 1px solid ${Colors.greys[500]}; */
    cursor: pointer;
    transition: 0.1s all;
    font-weight: 500;
    background-color: ${(props) => (props.selected ? color(props.theme.primary).darken(0.01).hex() : 'transparent')};

    &:hover {
        background-color: ${(props) => color(props.theme.primary).lighten(0.1).hex()};
    }

    ${(props) => {
        if (props.disabled) {
            return `
                background-color: ${Colors.greys[500]};
                cursor: default;
                font-weight: 300;

                &:hover {
                    background-color: ${Colors.greys[500]};
                }
            `;
        }
    }}
`;

interface SimpleTheme_SelectComponentProps {
    onChange?: Function;
    disabled?: boolean;
    options?: any[];
    name?: string;
    value?: any;
    width?: any;
    placeholder?: string;
    emptyPlaceholder?: string;
}

export default function SimpleTheme_SelectComponent(props: PropsWithChildren<SimpleTheme_SelectComponentProps>) {
    const [portfolio] = useGlobalState('portfolio');
    const [optionsVisible, setOptionsVisible] = useState(false);

    const optionsContainerRef = useRef(null);

    const [selectedOption, setSelectedOption] = useState();

    useOutsideAlerter(optionsContainerRef, () => {
        setOptionsVisible(false);
    });

    useEffect(() => {
        let item = props.options?.find((el: any) => el.value == props.value);
        setSelectedOption(item);
    }, [props.value, props.options]);

    const handleChange = (value: any) => {
        let item = props.options?.find((el: any) => el.value == value);

        if (typeof props.onChange !== 'undefined' && typeof item !== 'undefined') {
            props.onChange(item);
        } else {
            setSelectedOption(item);
        }

        setOptionsVisible(false);
    };

    // useEffect(() => {
    //     if (typeof props.onChange !== 'undefined' && typeof selectedOption !== 'undefined') {
    //         props.onChange(selectedOption);
    //     }
    // }, [selectedOption]);

    return (
        <div style={{ position: 'relative', width: props.width || 'auto' }}>
            <Container
                width={props.width || 'auto'}
                onClick={() => {
                    setOptionsVisible(true);
                }}
                disabled={props.disabled}
            >
                {
                    //@ts-ignore
                    selectedOption?.text || <Placeholder>{props.placeholder}</Placeholder> || ''
                }
                <Icon style={{ marginLeft: '1em' }} />
            </Container>

            <OptionsContainer ref={optionsContainerRef} visible={optionsVisible}>
                {props.options?.length == 0 && <Option disabled>{props.emptyPlaceholder || 'Brak pozycji'}</Option>}
                {props.options?.map((option: any, index: any) => {
                    let selected = false;
                    //@ts-ignore
                    if (typeof selectedOption != 'undefined' && option.value == selectedOption.value) {
                        selected = true;
                    }

                    return (
                        <Option key={props.name + index} selected={selected} onClick={() => handleChange(option.value)}>
                            {option.text}
                        </Option>
                    );
                })}
            </OptionsContainer>
        </div>
    );
}
