import React, { forwardRef, PropsWithChildren, useEffect, useState } from 'react';
import moment, { Moment } from 'moment';
import styled from 'styled-components';
import { getSlotsCountBetweenTimes } from '../../../../../services/Weeks';
import { capitalize } from '../../../../../services/Strings';
//@ts-ignore
import useDimensions from 'react-use-dimensions';
import { useGlobalState } from '../../../../../state';
import color from 'color';
import { post } from '../../../../../services/Requests';
import { message } from 'antd';
import Colors from '../../../../../constants/Colors';
import { useHistory } from 'react-router-dom';
import Breakpoints from '../../../../../constants/Breakpoints';
import { useWindowBreakpoint } from '../../../../../hooks/useWindowBreakpoint';

const AvailabilityCalendarContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 2em;
`;

const Table = styled.table<any>`
    table-layout: fixed;

    & tr:nth-child(1) {
        & td {
            &:nth-child(1) {
                border-top-left-radius: 8px;
            }

            &:nth-child(8) {
                border-top-right-radius: 8px;
            }
        }
    }

    & td {
        &:nth-child(1) {
            width: 36px;
        }

        width: ${(props) => props.cellWidth}px;
        max-width: ${(props) => props.cellWidth}px;
    }
`;

const Thead = styled.thead``;
const Tbody = styled.tbody``;
const HeaderTd = styled.td``;

const Row = styled.tr`
    &:nth-child(1) td {
        border-top: 1px solid ${(props) => color(props.theme.primary).saturate(0.1).hex()};

        &:nth-child(1) {
            border-top: 1px solid ${(props) => color(props.theme.primary).saturate(0.1).hex()} !important;
        }
    }
    &:nth-child(2n) td {
        border-bottom: 1px solid ${(props) => color(props.theme.primary).saturate(0.1).hex()};
    }
    &:nth-child(4n) td {
        border-bottom: 1px solid ${(props) => color(props.theme.primary).saturate(0.1).lighten(0.1).hex()};
    }
`;

const TimeSlot = styled.td<any>`
    height: 15px;
    font-size: 9px;

    border-right: 1px solid ${(props) => color(props.theme.primary).saturate(0.1).hex()};

    background-color: ${(props) => (props.available ? color(props.theme.primary).lighten(0.14).hex() : 'transparent')};

    @media (max-width: ${Breakpoints.max.tablet}px) {
        height: 40px;
    }

    &:nth-child(1) {
        border-bottom: none !important;
        border-top: none !important;
        border-right: none !important;

        position: relative;
        top: -8px;

        @media (max-width: ${Breakpoints.max.tablet}px) {
            top: -20px;
        }
    }

    &:nth-child(8) {
        border-right: none !important;
    }

    ${(props) => {
        if (props.available) {
            return `
                cursor: pointer;

                &:hover {
                    background: ${color(props.theme.primary).lighten(0.1).hex()}
                }
            `;
        }

        return ``;
    }}
`;

const HeaderRow = styled.tr`
    & td {
        background: ${(props) => color(props.theme.primary).lighten(0.1).hex()};
    }
`;

const DayHeader = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 1em;

    @media (max-width: ${Breakpoints.max.tablet}px) {
        & span {
            font-size: 0.8em;
        }
    }
`;

const DayHeaderName = styled.div`
    font-weight: 600;
`;

interface SimpleTheme_CalendarComponentProps {
    week: any;
    modal: JSX.Element;
    clientPanel?: boolean;
}

const slotSize = 15;

const SimpleTheme_CalendarComponent = forwardRef(
    (props: PropsWithChildren<SimpleTheme_CalendarComponentProps>, ref) => {
        const [portfolio] = useGlobalState('portfolio');
        const [appLoading, setAppLoading] = useGlobalState('appLoading');

        const history = useHistory();

        const [minAvailability, setMinAvailability] = useState('00:00');

        const [maxAvailability, setMaxAvailability] = useState('00:00');
        const [maxAvailabilitySlots, setMaxAvailabilitySlots] = useState(0);

        const [cellWidth, setCellWidth]: [any, Function] = useState('auto');
        const [containerRef, { x, y, width }] = useDimensions();

        const [modalVisible, setModalVisible] = useState(false);
        const [selectedDay, setSelectedDay] = useState(undefined);
        const [selectedTime, setSelectedTime] = useState(undefined);

        const [error, setError] = useState('');
        const [success, setSuccess] = useState('');

        const { isLessEqDesktop } = useWindowBreakpoint();

        useEffect(() => {
            setCellWidth((width - 36) / 7);
        }, [width]);

        const calculateSlotSizes = (daysToCalculate: any) => {
            let maxSlots = 0;
            let minAv, maxAv;

            for (let day of daysToCalculate) {
                if (!day.enabled) continue;

                if (typeof minAv === 'undefined' || moment(day.start, 'HH:mm').isBefore(moment(minAv, 'HH:mm'))) {
                    minAv = day.start;
                }

                if (typeof maxAv === 'undefined' || moment(day.end, 'HH:mm').isAfter(moment(maxAv, 'HH:mm'))) {
                    maxAv = day.end;
                }
            }

            if (typeof minAv !== 'undefined' && typeof maxAv !== 'undefined') {
                maxSlots = getSlotsCountBetweenTimes(slotSize, minAv, maxAv);
            }

            setMaxAvailabilitySlots(maxSlots);
            setMinAvailability(minAv);

            if (typeof maxAv !== 'undefined') {
                setMaxAvailability(maxAv);
            }
        };

        const showBorder = (minutes: any) => {
            switch (minutes) {
                case 30:
                case 0:
                    return true;
                default:
                    return false;
            }
        };

        const showMinutes = (minutes: any) => {
            switch (minutes) {
                case 0:
                case 10:
                case 20:
                case 30:
                case 40:
                case 50:
                    return true;
                default:
                    return false;
            }
        };

        useEffect(() => {
            calculateSlotSizes(props.week.days);
        }, [props.week]);

        const openBookModal = (day: any, time: any) => {
            setSelectedDay(day);
            setSelectedTime(time);
            setModalVisible(true);
        };

        const closeBookModal = () => {
            setModalVisible(false);
        };

        const handleBook = async (data: any) => {
            setAppLoading(true);
            try {
                const response = await post(
                    `calendar/book`,
                    //@ts-ignore
                    { ...data, time: selectedTime, day: selectedDay?._id, fromClientPanel: props.clientPanel || false },
                    { client: true, optionalToken: true },
                );

                if (!response.success) {
                    message.error('Wystąpił błąd podczas rezerwacji terminu.');
                    setAppLoading(false);
                    return;
                }

                if (props.clientPanel) {
                    history.push(`/user/${portfolio.slug}/client/sesje/${response.data}`);
                } else {
                    setSuccess('Na podany adres e-mail zostało wysłane potwierdzenie rezerwacji.');
                }
                setAppLoading(false);
            } catch (err) {
                message.error('Wystąpił błąd podczas rezerwacji terminu.');
            }
        };

        if (typeof props.week == 'undefined') {
            return <div />;
        }

        return (
            <AvailabilityCalendarContainer ref={containerRef}>
                {React.cloneElement(props.modal, {
                    day: selectedDay,
                    time: selectedTime,
                    visible: modalVisible,
                    handleOk: handleBook,
                    handleCancel: closeBookModal,
                    success,
                    error,
                })}
                <Table cellWidth={cellWidth}>
                    <Thead>
                        <HeaderRow>
                            <td></td>
                            {props.week?.days.map((day: any) => {
                                return (
                                    <HeaderTd>
                                        <DayHeader>
                                            <DayHeaderName>
                                                {capitalize(moment(day.date).format(isLessEqDesktop ? 'dd' : 'dddd'))}
                                            </DayHeaderName>
                                            <span>{moment(day.date).format('DD.MM')}</span>
                                        </DayHeader>
                                    </HeaderTd>
                                );
                            })}
                        </HeaderRow>
                    </Thead>
                    <Tbody>
                        {[...Array(Math.ceil(30 / slotSize))].map((any, index) => {
                            let count = Math.ceil(30 / slotSize);
                            let date = moment(minAvailability, 'HH:mm').subtract((count - index) * slotSize, 'minutes');

                            return (
                                <Row>
                                    {showMinutes(date.minutes()) && index >= Math.ceil(count / 2) ? (
                                        <TimeSlot>{date.format('HH:mm')}</TimeSlot>
                                    ) : (
                                        <TimeSlot></TimeSlot>
                                    )}

                                    {props.week.days.map((day: any) => {
                                        return <TimeSlot bordered={showBorder(date.minutes())}></TimeSlot>;
                                    })}
                                </Row>
                            );
                        })}
                        {[...Array(maxAvailabilitySlots)].map((any, index) => {
                            let date = moment(minAvailability, 'HH:mm').add(index * slotSize, 'minutes');

                            return (
                                <Row>
                                    {showMinutes(date.minutes()) ? (
                                        <TimeSlot>{date.format('HH:mm')}</TimeSlot>
                                    ) : (
                                        <TimeSlot></TimeSlot>
                                    )}

                                    {props.week.days.map((day: any) => {
                                        let dayDate = moment(
                                            moment(day.date).format('YYYY-MM-DD') + ' ' + date.format('HH:mm'),
                                        );

                                        let available = true;

                                        if (!day.enabled) {
                                            available = false;
                                        }

                                        if (dayDate.isBefore(moment())) {
                                            available = false;
                                        }

                                        if (
                                            date.isBefore(moment(day.start, 'HH:mm')) ||
                                            date.isSameOrAfter(moment(day.end, 'HH:mm'))
                                        ) {
                                            available = false;
                                        }

                                        for (let limit of day.limits) {
                                            if (
                                                date.isBetween(
                                                    moment(limit.start, 'HH:mm'),
                                                    moment(limit.end, 'HH:mm'),
                                                ) ||
                                                date.isSame(moment(limit.start, 'HH:mm'))
                                            ) {
                                                available = false;
                                            }
                                        }

                                        return (
                                            <TimeSlot
                                                available={available}
                                                onClick={() => {
                                                    if (available) {
                                                        openBookModal(day, moment(date).format('HH:mm'));
                                                    }
                                                }}
                                                bordered={showBorder(date.minutes())}
                                            ></TimeSlot>
                                        );
                                    })}
                                </Row>
                            );
                        })}
                        {[...Array(Math.ceil(30 / slotSize))].map((any, index) => {
                            let count = Math.ceil(30 / slotSize);
                            let date = moment(maxAvailability, 'HH:mm').add(index * slotSize, 'minutes');

                            return (
                                <Row>
                                    {showMinutes(date.minutes()) ? (
                                        <TimeSlot>{date.format('HH:mm')}</TimeSlot>
                                    ) : (
                                        <TimeSlot></TimeSlot>
                                    )}

                                    {props.week.days.map((day: any) => {
                                        return <TimeSlot bordered={showBorder(date.minutes())}></TimeSlot>;
                                    })}
                                </Row>
                            );
                        })}
                    </Tbody>
                </Table>
            </AvailabilityCalendarContainer>
        );
    },
);

export default SimpleTheme_CalendarComponent;
