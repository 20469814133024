import React, { PropsWithChildren, useEffect, useState } from 'react';
import { useGlobalState } from '../../../../../state';
import { useHistory, useRouteMatch, useParams } from 'react-router-dom';
import { get } from '../../../../../services/Requests';
import { SocketIOProvider } from 'use-socketio';

export default function Chat_AuthProvider(props: PropsWithChildren<any>) {
    const [client, setClient] = useGlobalState('client');

    const history = useHistory();
    const match = useRouteMatch();
    const params = useParams() as any;

    const [loading, setLoading] = useState(true);
    const [authorized, setAuthorized] = useState(false);

    useEffect(() => {
        (async () => {
            const token = localStorage.getItem('jwt-client');

            if (typeof token === 'undefined' || token === null) {
                setAuthorized(false);
                setLoading(false);
            }

            try {
                let response = await get(`clients/me`, { client: true });

                if (!response.success) {
                    setLoading(false);
                    setAuthorized(false);
                    localStorage.removeItem('jwt-client');
                    history.push(`${match.url}/login`);
                } else {
                    response = response as Response;
                    if (typeof response.data._id != 'undefined') {
                        setClient(response.data);
                        setAuthorized(true);
                    } else {
                        localStorage.removeItem('jwt-client');
                        setAuthorized(false);
                        history.push(`${match.url}/login`);
                    }
                }

                setLoading(false);
            } catch (err) {
                localStorage.removeItem('jwt-client');
                history.push(`${match.url}/login`);
            }
        })();
    }, []);

    useEffect(() => {
        if (!loading && !authorized) {
            history.push(`${match.url}/login`);
        }
    }, [loading, authorized, history, props.redirectTo]);

    if (authorized && !loading) {
        return (
            <SocketIOProvider
                url={process.env.REACT_APP_API as string}
                opts={{
                    query: {
                        token: localStorage.getItem('jwt-client'),
                    },
                }}
            >
                <div style={{ height: '100%' }}>{props.children}</div>
            </SocketIOProvider>
        );
    }

    return <div></div>;
}
