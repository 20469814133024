import React from 'react';
import styled from 'styled-components';
import { useGlobalState } from '../../../../../state';

const LogoText = styled.div`
    @import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@600&display=swap');

    max-width: 350px;
    font-family: 'Playfair Display', serif;
    font-size: 2.1em;
    color: black;
    font-weight: 600;
    line-height: 1em;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 2.5px;
`;

export default function Photographer_Logo() {
    const [portfolio] = useGlobalState('portfolio');

    if (!portfolio) {
        return <div />;
    }

    return <LogoText>{portfolio.title}</LogoText>;
}
