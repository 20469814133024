import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';

const Container = styled.div`
    font-weight: 300;
    margin-bottom: 0.3em;
`;

const Description = styled.div`
    font-weight: 400;
    font-size: 0.8em;
`;

export default function SubLabel(props: PropsWithChildren<any>) {
    return <Container>{props.children}</Container>;
}
