import React, { useEffect, useRef, useState } from 'react';
import Heading from '../../../../../components/Heading';
import styled from 'styled-components';
import { useGlobalState } from '../../../../../state';
import { Tabs, Button, Badge, Table, message, Popover, Space, Popconfirm, Descriptions } from 'antd';
import { get, post } from '../../../../../services/Requests';
import moment from 'moment';
import { Link, useHistory, useParams } from 'react-router-dom';
import deleteRequest from '../../../../../services/Requests/delete';
import { Photoshoot, PhotoshootStatus, translatePhotoshootStatus } from '../../../../../interfaces/Photoshoot';
import Client from '../../../../../interfaces/Client';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import PhotoshootsPhotoshootAddPhotos from './addPhotos';
import GalleryComponent from '../../../../../components/Gallery';

const Container = styled.div`
    margin-top: -1em;
`;

const Content = styled.div`
    margin-top: 2em;
`;

export default function PhotoshootsPhotoshoot() {
    const [user] = useGlobalState('user');
    const [appLoading, setAppLoading] = useGlobalState('appLoading');
    const [photoshootsAwaitingConfirmation, setPhotoshootsAwaitingConfirmation] = useGlobalState(
        'photoshootsAwaitingConfirmation',
    );
    const match = useRouteMatch();

    //@ts-ignore
    const [photoshoot, setPhotoshoot]: [Photoshoot, Function] = useState({});
    const history = useHistory();
    const params: any = useParams();

    const [buttons, setButtons] = useState([]);

    useEffect(() => {
        loadPhotoshoot(params.id);
    }, [params]);

    const handleConfirm = async (id: any, status: any) => {
        setAppLoading(true);
        try {
            let response = await post(`photoshoots/${id}/confirm`);
            if (!response.success) {
                let error = response.message;
                message.error(`Wystąpił błąd podczas potwierdzania sesji: ${error}`);
                setAppLoading(false);
                return;
            }

            message.success('Pomyślnie potwierdzono sesję.');
            setAppLoading(false);
            if (status == PhotoshootStatus.AWAITING_USER_CONFIRMATION) {
                setPhotoshootsAwaitingConfirmation((photoshoots) => photoshoots - 1);
            }

            loadPhotoshoot(photoshoot._id);
        } catch (err) {
            message.error(`Wystąpił błąd podczas potwierdzania sesji. Spróbuj ponownie później.`);
            setAppLoading(false);
        }
    };

    const handleReject = async (id: any, status: any) => {
        setAppLoading(true);
        try {
            let response = await post(`photoshoots/${id}/reject`);
            if (!response.success) {
                let error = response.message;
                message.error(`Wystąpił błąd podczas odrzucania sesji: ${error}`);
                setAppLoading(false);
                return;
            }

            message.success('Pomyślnie odrzucono sesję.');
            setAppLoading(false);
            if (status == PhotoshootStatus.AWAITING_USER_CONFIRMATION) {
                setPhotoshootsAwaitingConfirmation((photoshoots) => photoshoots - 1);
            }

            loadPhotoshoot(photoshoot._id);
        } catch (err) {
            message.error(`Wystąpił błąd podczas odrzucania sesji. Spróbuj ponownie później.`);
            setAppLoading(false);
        }
    };

    const handleCancel = async (id: any) => {
        setAppLoading(true);
        try {
            let response = await post(`photoshoots/${id}/cancel`);
            if (!response.success) {
                let error = response.message;
                message.error(`Wystąpił błąd podczas anulowania sesji: ${error}`);
                setAppLoading(false);
                return;
            }

            message.success('Pomyślnie anulowano sesję.');
            setAppLoading(false);

            loadPhotoshoot(photoshoot._id);
        } catch (err) {
            message.error(`Wystąpił błąd podczas anulowania sesji. Spróbuj ponownie później.`);
            setAppLoading(false);
        }
    };

    const handleFinish = async (id: any) => {
        setAppLoading(true);
        try {
            let response = await post(`photoshoots/${id}/finish`);
            if (!response.success) {
                let error = response.message;
                message.error(`Wystąpił błąd podczas zakańczania sesji: ${error}`);
                setAppLoading(false);
                return;
            }

            message.success('Pomyślnie wysłano zdjęcia klientowi. Sesja zmieniła status na zakończony.');
            setAppLoading(false);

            loadPhotoshoot(photoshoot._id);
        } catch (err) {
            message.error(`Wystąpił błąd podczas zakańczania sesji. Spróbuj ponownie później.`);
            setAppLoading(false);
        }
    };

    useEffect(() => {
        let btns: any = [];

        if (photoshoot.status == PhotoshootStatus.AWAITING_USER_CONFIRMATION) {
            btns = [
                ...btns,
                (record: any) => (
                    <Popconfirm
                        cancelText="Anuluj"
                        okType="danger"
                        okText="Odrzuć"
                        title="Czy jesteś pewny/a, że chcesz odrzucić tą sesję zdjęciową?"
                        onConfirm={() => handleReject(record._id, record.status)}
                    >
                        <Button danger>Odrzuć sesję</Button>
                    </Popconfirm>
                ),
            ];
        }

        if (
            photoshoot.status == PhotoshootStatus.AWAITING_USER_CONFIRMATION ||
            photoshoot.status == PhotoshootStatus.REJECTED
        ) {
            btns = [
                ...btns,
                (record: any) => (
                    <Popconfirm
                        cancelText="Anuluj"
                        okText="Potwierdź"
                        title="Czy jesteś pewny/a, że chcesz potwierdzić tą sesję zdjęciową?"
                        onConfirm={() => handleConfirm(record._id, record.status)}
                    >
                        <Button>Potwierdź sesję</Button>
                    </Popconfirm>
                ),
            ];
        }

        if (photoshoot.status == PhotoshootStatus.CONFIRMED) {
            btns = [
                ...btns,
                (record: any) => (
                    <Popconfirm
                        cancelText="Nie"
                        okType="danger"
                        okText="Tak"
                        title="Czy jesteś pewny/a, że chcesz anulować tą sesję zdjęciową?"
                        onConfirm={() => handleCancel(record._id)}
                    >
                        <Button danger>Anuluj sesję</Button>
                    </Popconfirm>
                ),
            ];
        }

        if (photoshoot.status == PhotoshootStatus.CANCELED) {
            btns = [
                ...btns,
                (record: any) => (
                    <Popconfirm
                        cancelText="Anuluj"
                        okText="Przywróć"
                        title="Czy jesteś pewny/a, że chcesz przywrócić tą sesję zdjęciową?"
                        onConfirm={() => handleConfirm(record._id, record.status)}
                    >
                        <Button>Przywróć sesję</Button>
                    </Popconfirm>
                ),
            ];
        }

        if (photoshoot.status == PhotoshootStatus.CONFIRMED || photoshoot.status == PhotoshootStatus.FINISHED) {
            btns = [
                ...btns,
                (record: any) => (
                    <Button onClick={() => history.push(`/app/photoshoots/${photoshoot._id}/addPhotos`)}>
                        Dodaj zdjęcia
                    </Button>
                ),
            ];
        }

        if (photoshoot.status == PhotoshootStatus.CONFIRMED) {
            btns = [
                ...btns,
                (record: any) => (
                    <Popconfirm
                        cancelText="Anuluj"
                        okText="Wyślij"
                        title="Czy jesteś pewny/a, że chcesz wysłać zdjęcia klientowi? Sesja zmieni status na zakończony."
                        onConfirm={() => handleFinish(record._id)}
                    >
                        <Button>Wyślij zdjęcia klientowi</Button>
                    </Popconfirm>
                ),
            ];
        }

        setButtons(btns);
    }, [photoshoot]);

    const loadPhotoshoot = async (id: any) => {
        setAppLoading(true);
        try {
            let response = await get(`photoshoots/${id}`);

            if (!response.success) {
                message.error('Nie udało się załadować danych o sesji. Spróbuj ponownie później.');
                return;
            }

            setPhotoshoot(response.data);
            setAppLoading(false);
        } catch (err) {
            message.error('Wystąpił błąd podczas ładowania danych o sesji.');
        }
    };

    const handleDelete = async () => {
        setAppLoading(true);
        try {
            let response = await deleteRequest(`photoshoots/${photoshoot._id}`);

            if (!response.success) {
                message.error('Nie udało się usunąć sesji. Spróbuj ponownie później.');
                return;
            }

            history.push(`/app/photoshoots`);

            setAppLoading(false);
        } catch (err) {
            message.error('Wystąpił błąd podczas usuwania sesji.');
        }
    };

    if (!photoshoot._id) {
        return <div />;
    }

    return (
        <Container>
            <Heading size="small" weight="normal">
                {photoshoot.type.name} ({translatePhotoshootStatus(photoshoot.status)})
            </Heading>

            <Content>
                <Switch>
                    <Route path={`${match.url}/addPhotos`}>
                        <PhotoshootsPhotoshootAddPhotos photoshoot={photoshoot} />
                    </Route>
                    <Route path={`${match.url}`}>
                        <Space>
                            {buttons.map((btn: any, index: any) => {
                                return btn(photoshoot);
                            })}
                        </Space>

                        <Descriptions style={{ marginTop: '2em' }} title="Dane o sesji">
                            <Descriptions.Item label="Data">
                                {moment(photoshoot.datetime).format('YYYY-MM-DD HH:mm')}
                            </Descriptions.Item>
                            <Descriptions.Item label="Klient">
                                <Link to={`/app/clients/${(photoshoot.client as Client)._id}`}>
                                    {(photoshoot.client as Client).fullNameAlias
                                        ? (photoshoot.client as Client).fullNameAlias
                                        : (photoshoot.client as Client).email}
                                </Link>
                            </Descriptions.Item>
                        </Descriptions>

                        <Descriptions
                            style={{ marginTop: '2em' }}
                            title={`Zdjęcia ${
                                photoshoot.photos.length > 0 ? `(${photoshoot.photos.length} zdjęć)` : ``
                            }`}
                        ></Descriptions>
                        {photoshoot.photos && photoshoot.photos.length > 0 ? (
                            <GalleryComponent photos={photoshoot.photos} />
                        ) : (
                            <div>Brak zdjęć</div>
                        )}
                    </Route>
                </Switch>
            </Content>
        </Container>
    );
}
