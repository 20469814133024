import React, { useEffect, useRef, useState } from 'react';
import Heading from '../../../../../components/Heading';
import styled from 'styled-components';
import { useGlobalState } from '../../../../../state';
import { Tabs, Button, Badge, Table, message, Popover, Space, Popconfirm, Descriptions } from 'antd';
import { get } from '../../../../../services/Requests';
import Client from '../../../../../interfaces/Client';
import { useHistory, useParams } from 'react-router-dom';
import deleteRequest from '../../../../../services/Requests/delete';
import AddClient from '../List/addClient';
import { Photoshoot } from '../../../../../interfaces/Photoshoot';
import PhotoshootsListComponent from '../../../../../components/PhotoshootsList';

const Container = styled.div`
    margin-top: -1em;
`;

const Content = styled.div`
    margin-top: 2em;
`;

export default function ClientsClient() {
    const [user] = useGlobalState('user');
    const [appLoading, setAppLoading] = useGlobalState('appLoading');

    const [editClientModalVisible, setEditClientModalVisible] = useState(false);
    const [addClientModalVisible, setAddClientModalVisible] = useState(false);

    //@ts-ignore
    const [client, setClient]: [Client, Function] = useState({});
    const [photoshoots, setPhotoshoots]: [Photoshoot[], Function] = useState([]);

    const history = useHistory();
    const params: any = useParams();

    const hideModal = () => {
        setEditClientModalVisible(false);
        setAddClientModalVisible(false);
    };

    useEffect(() => {
        loadClient(params.id);
        loadPhotoshoots(params.id);
    }, [params]);

    const loadClient = async (id: any) => {
        setAppLoading(true);
        try {
            let response = await get(`clients/${id}`);

            if (!response.success) {
                message.error('Nie udało się załadować danych klienta. Spróbuj ponownie później.');
                return;
            }

            setClient(response.data);

            setAppLoading(false);
        } catch (err) {
            message.error('Wystąpił błąd podczas ładowania danych klienta.');
        }
    };

    const loadPhotoshoots = async (client: any) => {
        setAppLoading(true);
        try {
            let response = await get(`photoshoots`);

            if (!response.success) {
                message.error('Nie udało się załadować sesji. Spróbuj ponownie później.');
                return;
            }

            setPhotoshoots(
                response.data.filter((photoshoot: Photoshoot) => (photoshoot.client as Client)._id == client),
            );

            setAppLoading(false);
        } catch (err) {
            message.error('Wystąpił błąd podczas ładowania sesji.');
        }
    };

    const handleDelete = async () => {
        setAppLoading(true);
        try {
            let response = await deleteRequest(`clients/${client._id}`);

            if (!response.success) {
                message.error('Nie udało się usunąć klienta. Spróbuj ponownie później.');
                return;
            }

            history.push(`/app/clients`);

            setAppLoading(false);
        } catch (err) {
            message.error('Wystąpił błąd podczas usuwania klienta.');
        }
    };

    const handleEdit = () => {
        setEditClientModalVisible(true);
    };

    if (!client._id) {
        return <div />;
    }

    return (
        <Container>
            <Heading size="small" weight="normal">
                {client.confirmedByUser ? `${client.fullNameAlias} (${client.email})` : client.email}
            </Heading>

            <Content>
                {client.confirmedByUser ? (
                    <Space>
                        <Button onClick={() => history.push(`/app/messages?client=${client._id}`)}>
                            Napisz wiadomość
                        </Button>
                        <Button onClick={handleEdit}>Edytuj</Button>
                        <Popconfirm
                            title="Czy jesteś pewny/a, że chcesz usunąć tego klienta? Operacja ta jest nieodwracalna."
                            okText="Usuń"
                            cancelText="Anuluj"
                            onConfirm={() => handleDelete()}
                        >
                            <Button danger>Usuń</Button>
                        </Popconfirm>
                    </Space>
                ) : (
                    <Space>
                        <Button onClick={() => history.push(`/app/messages?client=${client._id}`)}>
                            Napisz wiadomość
                        </Button>
                        <Button onClick={() => setAddClientModalVisible(true)}>Dodaj jako nowego klienta</Button>
                    </Space>
                )}

                <Descriptions style={{ marginTop: '2em' }} title="Sesje zdjęciowe"></Descriptions>
                <PhotoshootsListComponent clients={false} photoshoots={photoshoots} />
            </Content>

            <AddClient
                edit={client}
                onFinished={(client: Client) => setClient(client)}
                new={false}
                email={client.email}
                visible={editClientModalVisible}
                hideModalFunction={hideModal}
            />

            <AddClient
                onFinished={(client: Client) => setClient(client)}
                new={false}
                email={client.email}
                visible={addClientModalVisible}
                hideModalFunction={hideModal}
            />
        </Container>
    );
}
