import { Spin } from 'antd';
import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export default function Spinner() {
    return (
        <Container>
            <Spin />
        </Container>
    );
}
