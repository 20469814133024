import React, { PropsWithChildren, useRef, useState } from 'react';
import { FaEllipsisV } from 'react-icons/fa';
import styled from 'styled-components';
import Breakpoints from '../../constants/Breakpoints';
import Colors from '../../constants/Colors';
import { useOutsideAlerter } from '../../hooks/useOutsideAlerter';

interface DotsMenuProps {
    icon?: JSX.Element;
    menuItems?: MenuItem[];
}

interface MenuItem {
    onClick?: Function;
    title: string;
    icon?: any;
}

const Container = styled.div<any>`
    position: relative;
`;

const DotsContainer = styled.div<any>`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 8px;
    border-radius: 50%;
    cursor: pointer;
    transition: 0.1s background-color;

    background: ${(props) => (props.clicked ? 'rgba(0, 0, 0, 0.08)' : 'transparent')};
`;

const MenuContainer = styled.div<any>`
    position: absolute;
    top: 100%;
    right: 0;
    display: ${(props) => (props.visible ? 'flex' : 'none')};
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
    /* border-radius: 6px; */
    background: white;
`;

const MenuItemContainer = styled.div`
    padding: 4px 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    font-size: 12px;
    min-width: 200px;
    cursor: pointer;

    &:hover {
        background: ${Colors.accents.main[200]};
    }

    @media (max-width: ${Breakpoints.max.tablet}px) {
        padding: 8px 16px;
    }
`;

const IconContainer = styled.div`
    margin-right: 6px;
    display: flex;
    align-items: center;
`;

export default function DotsMenu(props: PropsWithChildren<DotsMenuProps>) {
    const [visible, setVisible] = useState(false);

    const handleClick = () => {
        setVisible((visible) => !visible);
    };

    const containerRef = useRef(null);
    useOutsideAlerter(containerRef, (e: any) => {
        setVisible(false);
    });

    return (
        <Container ref={containerRef}>
            <DotsContainer onClick={handleClick} clicked={visible}>
                <FaEllipsisV />
            </DotsContainer>

            <MenuContainer visible={visible}>
                {props.menuItems?.map((item: MenuItem, index: number) => {
                    return (
                        <MenuItemContainer
                            key={`dotsmenu-item-${index}`}
                            onClick={() => {
                                setVisible(false);
                                if (item.onClick) {
                                    item.onClick();
                                }
                            }}
                        >
                            {item.icon ? <IconContainer>{item.icon}</IconContainer> : ''}
                            {item.title}
                        </MenuItemContainer>
                    );
                })}
            </MenuContainer>
        </Container>
    );
}
