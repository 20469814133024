import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Colors from '../../../constants/Colors';
import Heading from '../../../components/Heading';
import { useParams, Link } from 'react-router-dom';
import { useGlobalState } from '../../../state';
import { Alert, message } from 'antd';
import { post } from '../../../services/Requests';
import BadRequestException from '../../../interfaces/BadRequestException';

const Background = styled.div`
    background-color: ${Colors.accents.main[500]};
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Container = styled.div`
    background: ${Colors.accents.main[700]};
    display: flex;
    height: fit-content;
    padding: 2rem 5rem;
    position: relative;
    z-index: 2;
    flex-direction: column;
    align-items: center;
    margin: 16px;

    &::before {
        transform: translate(5px, 5px);
        background: white;
        z-index: -1;
        left: 0;
        top: 0;
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
    }
`;

const Content = styled.div`
    position: relative;
    animation: enter 1s;
    width: 100%;
    margin-top: 24px;

    @keyframes enter {
        from {
            top: 20px;
            opacity: 0;
        }
        to {
            top: 0px;
            opacity: 1;
        }
    }
`;

export default function ActivateView() {
    const { token }: { token: any } = useParams();
    const [isAppLoading, setAppLoading] = useGlobalState('appLoading'); // eslint-disable-line @typescript-eslint/no-unused-vars
    const [activationSuccess, setActivationSuccess] = useState(false);
    const [error, setError] = useState('');

    useEffect(() => {
        setAppLoading(true);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setError('');

        (async () => {
            try {
                let response = await post('auth/activate', { token }, { useToken: false });

                if (!response.success) {
                    response = response as BadRequestException;

                    if (typeof response.message === 'string') {
                        setError(response.message as string);
                    } else if (typeof response.message === 'object' && response.message[0].property === 'token') {
                        setError(response.message[0].messages[0] as string);
                    } else throw new Error('todo: handle error');

                    setAppLoading(false);

                    return;
                }

                setActivationSuccess(true);
                setAppLoading(false);
            } catch (err) {
                message.error('Wystąpił błąd podczas próby aktywacji konta. Spróbuj ponownie później.');
                setAppLoading(false);
            }
        })();
    }, [token]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Background>
            <Container>
                <Heading>Aktywacja konta</Heading>
                {activationSuccess && <Content>{<Alert banner type="success" message={<SuccessMessage />} />}</Content>}
                {error !== '' && (
                    <Content>
                        <Alert type="error" message={error} banner />
                    </Content>
                )}
            </Container>
        </Background>
    );
}

function SuccessMessage() {
    return (
        <div>
            <span>Pomyślnie aktywowano konto. </span>
            <Link to="/app">
                <strong>Kliknij tutaj aby przejść do aplikacji</strong>
            </Link>
        </div>
    );
}
