import { PhotoshootPhoto } from '../../interfaces/Photoshoot';

export default function getImageURL(photo: PhotoshootPhoto | string, thumbnail = true) {
    if (typeof photo == 'object') {
        photo = photo as PhotoshootPhoto;

        if (typeof photo.thumbnail != 'undefined' && photo.thumbnail._id && thumbnail) {
            return `${process.env.REACT_APP_API as string}/photoshoots/photos/${photo.thumbnail._id}`;
        } else {
            //@ts-ignore
            if (typeof photo.uid != 'undefined') {
                //@ts-ignore
                return `${process.env.REACT_APP_API as string}/photoshoots/photos/${photo.uid}`;
            }

            return `${process.env.REACT_APP_API as string}/photoshoots/photos/${photo._id}`;
        }
    }

    return `${process.env.REACT_APP_API as string}/photoshoots/photos/${photo}`;
}
