import React, {
    forwardRef,
    PropsWithChildren,
    useEffect,
    useImperativeHandle,
    useReducer,
    useRef,
    useState,
} from 'react';
import moment, { Moment } from 'moment';
import styled from 'styled-components';
import { getSlotsCountBetweenTimes } from '../../../../../../services/Weeks';
import Colors from '../../../../../../constants/Colors';
import { capitalize } from '../../../../../../services/Strings';
//@ts-ignore
import useDimensions from 'react-use-dimensions';
import { Button } from 'antd';
import AvailabilityCalendarSetupDay from './SetupDay';
import { useWindowSize } from '../../../../../../hooks/useWindowSize';
import { useWindowBreakpoint } from '../../../../../../hooks/useWindowBreakpoint';
import { FaCog } from 'react-icons/fa';
import Breakpoints from '../../../../../../constants/Breakpoints';

const AvailabilityCalendarContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 2em;
`;

const Table = styled.table<any>`
    table-layout: fixed;

    & tr:nth-child(1) {
        & td {
            &:nth-child(1) {
                border-top-left-radius: 8px;
            }

            &:nth-child(8) {
                border-top-right-radius: 8px;
            }
        }
    }

    & td {
        &:nth-child(1) {
            width: 36px;
        }

        width: ${(props) => props.cellWidth}px;
        max-width: ${(props) => props.cellWidth}px;
    }
`;

const Thead = styled.thead``;
const Tbody = styled.tbody``;

const Row = styled.tr`
    &:nth-child(1) td {
        border-top: 1px solid ${Colors.accents.main[300]};

        &:nth-child(1) {
            border-top: 1px solid ${Colors.accents.main[300]} !important;
        }
    }
    &:nth-child(2n) td {
        border-bottom: 1px solid ${Colors.accents.main[300]};
    }
    &:nth-child(4n) td {
        border-bottom: 1px solid ${Colors.accents.main[200]};
    }
`;

const SettingsButton = styled(Button)`
    display: flex;
    margin-top: 8px;
    padding: 0 0.5em;
    font-size: 0.8em;
    align-items: center;
`;

const HeaderTd = styled.td`
    background: red;
`;

const TimeSlot = styled.td<any>`
    height: 15px;
    font-size: 9px;

    border-right: 1px solid ${Colors.accents.main[300]};

    background-color: ${(props) => (props.available ? Colors.accents.main[100] : 'transparent')};

    &:nth-child(1) {
        border-bottom: none !important;
        border-top: none !important;
        border-right: none !important;

        position: relative;
        top: -8px;
    }

    &:nth-child(8) {
        border-right: none !important;
    }

    /* border-top: ${(props) => (props.bordered ? `1px solid ${Colors.accents.main[300]}` : 'none')}; */
`;

const HeaderRow = styled.tr`
    & td {
        background: ${Colors.accents.main[200]};
    }
`;

const DayHeader = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 1em;

    @media (max-width: ${Breakpoints.max.tablet}px) {
        & span {
            font-size: 0.8em;
        }
    }
`;

const DayHeaderName = styled.div`
    font-weight: 600;
`;

interface AvailabilitySetupCalendarProps {
    week: Moment | undefined;
}

const slotSize = 15;

const AvailabilitySetupCalendar = forwardRef((props: PropsWithChildren<AvailabilitySetupCalendarProps>, ref) => {
    const [minAvailability, setMinAvailability] = useState('00:00');
    const [maxAvailability, setMaxAvailability] = useState('00:00');
    const [maxAvailabilitySlots, setMaxAvailabilitySlots] = useState(0);

    const [cellWidth, setCellWidth]: [any, Function] = useState('auto');
    const [containerRef, { x, y, width }] = useDimensions();

    const { isLessEqDesktop } = useWindowBreakpoint();

    const [selectedDay, setSelectedDay]: [any, Function] = useState(null);

    useEffect(() => {
        setCellWidth((width - 36) / 7);
    }, [width]);

    useImperativeHandle(ref, () => ({
        getDays() {
            return days;
        },
    }));

    const initialState: any = [];
    const daysActionTypes = {
        setDays: 'SET_DAYS',
        updateDay: 'UPDATE_DAY',
    };

    const calculateSlotSizes = (daysToCalculate: any) => {
        let maxSlots = 0;
        let minAv, maxAv;

        for (let day of daysToCalculate) {
            if (!day.enabled) continue;

            if (typeof minAv === 'undefined' || moment(day.start, 'HH:mm').isBefore(moment(minAv, 'HH:mm'))) {
                minAv = day.start;
            }

            if (typeof maxAv === 'undefined' || moment(day.end, 'HH:mm').isAfter(moment(maxAv, 'HH:mm'))) {
                maxAv = day.end;
            }
        }

        if (typeof minAv !== 'undefined' && typeof maxAv !== 'undefined') {
            maxSlots = getSlotsCountBetweenTimes(slotSize, minAv, maxAv);
        }

        setMaxAvailabilitySlots(maxSlots);
        setMinAvailability(minAv);

        if (typeof maxAv !== 'undefined') {
            setMaxAvailability(maxAv);
        }
    };

    function daysReducer(prevState = initialState, action: any) {
        switch (action.type) {
            case daysActionTypes.setDays:
                return action.payload;
            case daysActionTypes.updateDay:
                let index = prevState.findIndex((d: any) => d._id === action.payload._id);
                if (index !== -1) {
                    prevState[index] = action.payload;
                }

                calculateSlotSizes(prevState);

                return prevState;
            default:
                return prevState;
        }
    }

    const [days, dispatch] = useReducer(daysReducer, initialState);

    const showBorder = (minutes: any) => {
        switch (minutes) {
            case 30:
            case 0:
                return true;
            default:
                return false;
        }
    };

    const showMinutes = (minutes: any) => {
        switch (minutes) {
            case 0:
            case 10:
            case 20:
            case 30:
            case 40:
            case 50:
                return true;
            default:
                return false;
        }
    };

    useEffect(() => {
        dispatch({ type: daysActionTypes.setDays, payload: props.week?.days });
    }, [props.week]);

    useEffect(() => {
        calculateSlotSizes(days);
    }, [days]);

    const openDaySetupModal = (_id: any) => {
        setSelectedDay(days.find((d: any) => d._id === _id));
    };

    const handleUpdateDay = (day: any) => {
        dispatch({ type: daysActionTypes.updateDay, payload: day });
        setSelectedDay(null);
    };

    return (
        <AvailabilityCalendarContainer ref={containerRef}>
            <Table cellWidth={cellWidth}>
                <Thead>
                    <HeaderRow>
                        <td></td>
                        {days.map((day: any) => {
                            return (
                                <HeaderTd>
                                    <DayHeader>
                                        <DayHeaderName>
                                            {capitalize(moment(day.date).format(isLessEqDesktop ? 'dd' : 'dddd'))}
                                        </DayHeaderName>
                                        <span>{moment(day.date).format('DD.MM')}</span>
                                        <SettingsButton
                                            size="small"
                                            onClick={() => openDaySetupModal(day._id)}
                                            type="default"
                                        >
                                            {isLessEqDesktop ? <FaCog /> : 'Ustawienia'}
                                        </SettingsButton>
                                    </DayHeader>
                                </HeaderTd>
                            );
                        })}
                    </HeaderRow>
                </Thead>
                <Tbody>
                    {[...Array(Math.ceil(30 / slotSize))].map((any, index) => {
                        let count = Math.ceil(30 / slotSize);
                        let date = moment(minAvailability, 'HH:mm').subtract((count - index) * slotSize, 'minutes');

                        return (
                            <Row>
                                {showMinutes(date.minutes()) && index >= Math.ceil(count / 2) ? (
                                    <TimeSlot>{date.format('HH:mm')}</TimeSlot>
                                ) : (
                                    <TimeSlot></TimeSlot>
                                )}

                                {days.map((day: any) => {
                                    return <TimeSlot bordered={showBorder(date.minutes())}></TimeSlot>;
                                })}
                            </Row>
                        );
                    })}
                    {[...Array(maxAvailabilitySlots)].map((any, index) => {
                        let date = moment(minAvailability, 'HH:mm').add(index * slotSize, 'minutes');

                        return (
                            <Row>
                                {showMinutes(date.minutes()) ? (
                                    <TimeSlot>{date.format('HH:mm')}</TimeSlot>
                                ) : (
                                    <TimeSlot></TimeSlot>
                                )}

                                {days.map((day: any) => {
                                    let available = true;

                                    if (!day.enabled) {
                                        available = false;
                                    }

                                    if (
                                        date.isBefore(moment(day.start, 'HH:mm')) ||
                                        date.isSameOrAfter(moment(day.end, 'HH:mm'))
                                    ) {
                                        available = false;
                                    }

                                    for (let limit of day.limits) {
                                        if (
                                            date.isBetween(moment(limit.start, 'HH:mm'), moment(limit.end, 'HH:mm')) ||
                                            date.isSame(moment(limit.start, 'HH:mm'))
                                        ) {
                                            available = false;
                                        }
                                    }

                                    return (
                                        <TimeSlot
                                            available={available}
                                            bordered={showBorder(date.minutes())}
                                        ></TimeSlot>
                                    );
                                })}
                            </Row>
                        );
                    })}
                    {[...Array(Math.ceil(30 / slotSize))].map((any, index) => {
                        let count = Math.ceil(30 / slotSize);
                        let date = moment(maxAvailability, 'HH:mm').add(index * slotSize, 'minutes');

                        return (
                            <Row>
                                {showMinutes(date.minutes()) ? (
                                    <TimeSlot>{date.format('HH:mm')}</TimeSlot>
                                ) : (
                                    <TimeSlot></TimeSlot>
                                )}

                                {days.map((day: any) => {
                                    return <TimeSlot bordered={showBorder(date.minutes())}></TimeSlot>;
                                })}
                            </Row>
                        );
                    })}
                </Tbody>
            </Table>

            <AvailabilityCalendarSetupDay
                cancel={() => {
                    setSelectedDay(null);
                }}
                day={selectedDay}
                updateDay={handleUpdateDay}
            />
        </AvailabilityCalendarContainer>
    );
});

export default AvailabilitySetupCalendar;
