import React, { useEffect, useRef, useState } from 'react';
import Heading from '../../../../../components/Heading';
import styled from 'styled-components';
import { useGlobalState } from '../../../../../state';
import { Form, Row, Col, Input, DatePicker, Button, Select, Space, message } from 'antd';
import deleteRequest from '../../../../../services/Requests/delete';
import { get, post } from '../../../../../services/Requests';
import Label from '../../../../../components/Label';
import Breakpoints from '../../../../../constants/Breakpoints';
import { types } from 'util';
import AddClient from '../../Clients/List/addClient';
import Client from '../../../../../interfaces/Client';
import validationHandler from '../../../../../services/ValidationHandler';
import { useHistory } from 'react-router-dom';

const Container = styled.div`
    margin-top: -1em;
`;
const InputContainer = styled.div`
    max-width: ${Breakpoints.max.tablet}px;
`;

const Content = styled.div`
    margin-top: 2em;
`;

const { useForm } = Form;
const { Option } = Select;

export default function PhotoshootsNew() {
    const [appLoading, setAppLoading] = useGlobalState('appLoading');

    const [types, setTypes] = useState([]);
    const [clients, setClients] = useState([]);
    const [addClient, setAddClient] = useState(false);
    const [clientsLoading, setClientsLoading] = useState(false);
    const [typesLoading, setTypesLoading] = useState(false);

    const [form] = useForm();
    const [loading, setLoading] = useState(false);
    const [disabled, setDisabled] = useState(false);

    const history = useHistory();

    useEffect(() => {
        loadTypes();
        loadClients();
    }, []);

    const loadTypes = () => {
        // Load added types
        (async () => {
            try {
                setTypesLoading(true);
                let response = await get('photoshoots/types');

                if (response.success) {
                    response = response as Response;
                    setTypes(response.data);

                    setTypesLoading(false);
                }
            } catch (err) {
                // TODO: handle error
            }
        })();
    };

    const loadClients = () => {
        (async () => {
            try {
                setClientsLoading(true);
                let response = await get('clients');

                if (response.success) {
                    response = response as Response;
                    setClients(response.data);

                    setClientsLoading(false);
                }
            } catch (err) {
                // TODO: handle error
            }
        })();
    };

    const addClientHide = () => {
        setAddClient(false);
    };

    const handleAddClientFinished = async (client: Client) => {
        await loadClients();
        form.setFields([
            {
                name: 'client',
                value: client._id,
            },
        ]);
    };

    const formSubmit = async (values: any) => {
        try {
            setDisabled(true);
            let response = await post('photoshoots', values);

            if (!response.success) {
                let errorMessage = validationHandler(response, form);
                if (errorMessage) {
                    message.error('Nie udało się dodać nowej sesji: ' + errorMessage);
                }

                setDisabled(false);
            } else {
                response = response as Response;
                message.success('Pomyślnie dodano sesję.');
                history.push(`/app/photoshoots/${response.data._id}`);
            }

            setDisabled(false);
        } catch (err) {
            message.error('Wystąpił błąd podczas próby edycji klienta. Spróbuj ponownie później.');
        }
    };

    return (
        <Container>
            <Heading
                size="small"
                weight="normal"
                button={
                    <Button form="new-photoshoot" htmlType="submit" type="primary">
                        Dodaj
                    </Button>
                }
            >
                Nowa sesja zdjęciowa
            </Heading>

            <Content>
                <Form id="new-photoshoot" form={form} onFinish={formSubmit}>
                    <InputContainer>
                        <Row gutter={[0, 6]}>
                            <Col span={24}>
                                <Label>Termin sesji</Label>
                                <Form.Item name="datetime" help={null}>
                                    <DatePicker
                                        showSecond={false}
                                        placeholder="Wybierz datę"
                                        style={{ width: '100%' }}
                                        disabled={disabled}
                                        format="YYYY-MM-DD HH:mm"
                                        showNow={false}
                                        showTime
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Label>Rodzaj sesji</Label>
                                <Form.Item name="type" help={null}>
                                    <Select
                                        loading={typesLoading}
                                        placeholder="Wybierz rodzaj sesji"
                                        disabled={disabled}
                                    >
                                        {types.map((types: any) => {
                                            return <Option value={types._id}>{types.name}</Option>;
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Label>Klient</Label>
                                <Row gutter={[8, 0]}>
                                    <Col span={20}>
                                        <Form.Item name="client" help={null}>
                                            <Select
                                                loading={clientsLoading}
                                                showSearch
                                                placeholder="Wybierz klienta"
                                                disabled={disabled}
                                                filterOption={(input, option) =>
                                                    //@ts-ignore
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                            >
                                                {clients.map((clients: any) => {
                                                    return <Option value={clients._id}>{clients.fullNameAlias}</Option>;
                                                })}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={4}>
                                        <Button style={{ width: '100%' }} onClick={() => setAddClient(true)}>
                                            Dodaj klienta
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </InputContainer>
                </Form>
            </Content>

            <AddClient onFinished={handleAddClientFinished} hideModalFunction={addClientHide} visible={addClient} />
        </Container>
    );
}
