import React, { useState } from 'react';
import Heading from '../../../../components/Heading';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import CalendarAvailabilityView from './Availability';
import CalendarConfigurationView from './Configuration';

export default function AppCalendar() {
    const match = useRouteMatch();

    return (
        <div>
            <Heading weight="strong">Kalendarz</Heading>
            <div style={{ marginTop: 24 }}>
                <Switch>
                    <Route path={`${match.url}/availability`}>
                        <CalendarAvailabilityView />
                    </Route>
                    <Route path={`${match.url}`}>
                        <CalendarConfigurationView />
                    </Route>
                </Switch>
            </div>
        </div>
    );
}
