import React, { PropsWithChildren, useEffect, useState } from 'react';
import { SocketIOProvider, useSocket } from 'use-socketio';
import { get } from '../../services/Requests';
import { useHistory } from 'react-router-dom';
import { useGlobalState } from '../../state';
import { useDispatch } from 'react-redux';

interface AuthProviderProps {
    redirectTo?: string;
}

export default function AuthProvider(props: PropsWithChildren<AuthProviderProps>) {
    const [authorized, setAuthorized] = useState(false);
    const [loading, setLoading] = useState(true);
    const history = useHistory();
    const [user, setUser] = useGlobalState('user'); // eslint-disable-line @typescript-eslint/no-unused-vars
    const [photoshootsAwaitingConfirmation, setPhotoshootsAwaitingConfirmation] = useGlobalState(
        'photoshootsAwaitingConfirmation',
    );

    const dispatch = useDispatch();

    useEffect(() => {
        (async () => {
            const token = localStorage.getItem('jwt');
            if (!token) {
                setAuthorized(false);
                setLoading(false);
                return;
            }

            try {
                let response = await get('users/me');

                if (!response.success) {
                    setLoading(false);
                    setAuthorized(false);
                    localStorage.removeItem('jwt');
                    history.push('/app/login');
                } else {
                    response = response as Response;
                    if (typeof response.data._id != 'undefined') {
                        setUser(response.data);
                        setAuthorized(true);

                        response = await get('photoshoots/awaitingConfirmation');
                        if (response.success) {
                            setPhotoshootsAwaitingConfirmation(response.data.length);
                        }

                        response = await get('conversations/unread');
                        if (response.success) {
                            dispatch({ type: 'SET_UNREAD_CONVERSATIONS', payload: response.data });
                        }
                    } else {
                        localStorage.removeItem('jwt');
                        setAuthorized(false);
                        history.push('/app/login');
                    }
                }

                setLoading(false);
            } catch (err) {
                // localStorage.removeItem('jwt');
                // history.push('/app/login');
            }
        })();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!loading && !authorized) {
            if (typeof props.redirectTo !== 'undefined') {
                history.push(props.redirectTo);
            }
        }
    }, [loading, authorized, history, props.redirectTo]);

    if (authorized && !loading) {
        return (
            <SocketIOProvider
                url={process.env.REACT_APP_API as string}
                opts={{
                    query: {
                        token: localStorage.getItem('jwt'),
                    },
                }}
            >
                <div style={{ height: '100%' }}>{props.children}</div>
            </SocketIOProvider>
        );
    }

    if (loading) {
        return <div></div>;
    }

    return <div>401</div>;
}
