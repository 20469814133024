import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { WeightType } from '../../types/WeightType';
import { SizeType } from '../../types/SizeType';

interface HeadingProps {
    size?: SizeType;
    weight?: WeightType;
    button?: JSX.Element;
}

const Container = styled.span<{ weight: number; size: number }>`
    font-family: 'Roboto';
    font-size: ${(props) => props.size}rem;
    line-height: 2.5rem;
    font-weight: ${(props) => props.weight};
    margin-bottom: 1rem;
    color: black;
    display: flex;
    justify-content: space-between;
`;

export default function Heading(props: PropsWithChildren<HeadingProps>) {
    let weight, size;
    switch (props.weight) {
        case 'light':
            weight = 200;
            break;
        case 'normal':
            weight = 400;
            break;
        case 'strong':
            weight = 600;
            break;
        default:
            weight = 600;
    }

    switch (props.size) {
        case 'extraSmall':
            size = 1.2;
            break;
        case 'small':
            size = 1.5;
            break;
        case 'normal':
            size = 2;
            break;
        case 'large':
            size = 2.5;
            break;
        default:
            size = 2.5;
    }

    return (
        <Container weight={weight} size={size}>
            {props.children}
            {props.button && props.button}
        </Container>
    );
}
