import React, { PropsWithChildren, useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import Colors from '../../../../../constants/Colors';
import { FaEllipsisV, FaImage, FaPaperPlane, FaUserPlus } from 'react-icons/fa';
import Message from '../Message';
import { Message as MessageInterface } from '../../../../../interfaces/Conversation';
import { useSocket } from 'use-socketio';
import { get, post } from '../../../../../services/Requests';
import { message as antdMessage } from 'antd';
import { useGlobalState } from '../../../../../state';
import { useDispatch } from 'react-redux';
import DotsMenu from '../../../../../components/DotsMenu';
import AddClient from '../../../../../views/App/App/Clients/List/addClient';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
`;

const Header = styled.div`
    padding: 1em 2em;
    border-bottom: 2px solid ${Colors.greys[200]};
    font-size: 1.2em;
    font-weight: 600;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const Messages = styled.div`
    height: 0px;
    overflow: auto;
    padding: 1em 2em;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
`;

const MessageBox = styled.div`
    border-top: 2px solid ${Colors.greys[200]};
    display: flex;
    flex-direction: row;
    padding: 0.5em 0.5em;
`;

const MessageInputContainer = styled.div`
    width: 100%;
    background: ${Colors.greys[200]};
    border-radius: 8px;
    padding: 0.2em 0.5em;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const MessageInput = styled.textarea<any>`
    border: none;
    outline: none;
    width: 100%;
    resize: none;
    background: none;
`;

const IconContainer = styled.div`
    background: ${Colors.accents.main[200]};
    border-radius: 8px;
    height: 42px;
    width: 42px;
    margin-right: 0.5em;
    font-size: 1.2em;
    /* color: white; */
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.1s;

    &:hover {
        background: ${Colors.accents.main[400]};
    }
`;

const SendButton = styled.div<any>`
    margin-right: 1em;
`;

interface ConversationProps {
    conversation: any;
    updateConversationFunction?: Function;
    addConversation?: Function;
}

export default function Conversation(props: PropsWithChildren<ConversationProps>) {
    const [messages, setMessages] = useState([]) as any;
    const [message, setMessage] = useState('');
    const [disabled, setDisabled] = useState(false);

    const dispatch = useDispatch();

    const [moreMessagesAvailable, setMoreMessagesAvailable] = useState(true);
    const [messagesLoading, setMessagesLoading] = useState(false);
    const [appLoading, setAppLoading] = useGlobalState('appLoading');

    const [skip, setSkip] = useState(0);

    const chatRef = useRef(null);

    const textareaRef = useRef(null);

    const [menuItems, setMenuItems]: [any, Function] = useState([]);
    const [addClientModalVisible, setAddClientModalVisible] = useState(false);

    const { socket, subscribe, unsubscribe } = useSocket('message', (data) => {
        if (data.conversation == props.conversation._id) {
            setMessages([...messages, data.message]);
            scrollToBottom();

            socket.emit('conversation-read', { conversation: props.conversation._id });
        }

        dispatch({ type: 'UPDATE_LAST_MESSAGE', payload: data });
    });

    const loadMessages = async (firstLoad = false) => {
        if (props.conversation === null || props.conversation.new) {
            return;
        }

        if ((moreMessagesAvailable && !messagesLoading) || firstLoad) {
            setMessagesLoading(true);
            setAppLoading(true);

            try {
                const response = await get(
                    `conversations/${props.conversation._id}/messages?skip=${firstLoad ? 0 : skip}&limit=${20}`,
                );

                if (!response.success) {
                    antdMessage.error(response.message);
                    return;
                }

                if (firstLoad) {
                    setMessages((messages: MessageInterface[]) => [...response.data]);
                    setSkip((skip) => 20);
                    setMoreMessagesAvailable(true);
                } else {
                    setMessages((messages: MessageInterface[]) => [...response.data, ...messages]);
                    setSkip((skip) => skip + 20);
                }

                if (response.data.length < 20) {
                    setMoreMessagesAvailable(false);
                }

                setAppLoading(false);
                setMessagesLoading(false);
            } catch (err) {
                antdMessage.error(err.message);
                setMessagesLoading(false);
            }
        }
    };

    let defaultMenuItems: any = [];
    useEffect(() => {
        (async () => {
            // Load messages
            await loadMessages(true);
            scrollToBottom();
        })();

        if (props.conversation && !props.conversation.client.confirmedByUser) {
            setMenuItems([
                ...defaultMenuItems,
                {
                    title: 'Dodaj jako nowego klienta',
                    icon: <FaUserPlus />,
                    onClick: handleAddClientClick,
                },
            ]);
        }
    }, [props.conversation]);

    useEffect(() => {
        if (textareaRef.current !== null) {
            //@ts-ignore
            textareaRef.current.focus();
        }
    }, [textareaRef]);

    const keyPress = (event: any) => {
        if (event.key == 'Enter') {
            if (event.shiftKey) {
                return;
            }

            event.preventDefault();
            sendMessage();
        }
    };

    const scrollToBottom = () => {
        if (chatRef != null && chatRef.current != null) {
            //@ts-ignore
            chatRef.current.scrollTop = chatRef.current.scrollHeight;
        }
    };

    const sendMessage = async () => {
        let msg = message;
        setMessages([
            ...messages,
            {
                sender: 'user',
                message: msg,
                read: false,
                date: moment(),
            },
        ]);

        setMessage('');

        if (props.conversation.new) {
            try {
                let response = await post(`conversations`, {
                    message: msg,
                    email: props.conversation.client.email,
                    fullName: props.conversation.client.fullName || props.conversation.client.fullNameAlias,
                });

                if (!response.success) {
                    setDisabled(true);
                    // antdMessage.error(response.message);
                } else {
                    if (typeof props.updateConversationFunction != 'undefined') {
                        props.updateConversationFunction(response.data);
                    }
                    if (typeof props.addConversation != 'undefined') {
                        props.addConversation(response.data);
                    }
                    console.log(response.data);
                }
            } catch (err) {
                antdMessage.error(err.message);
            }
        } else {
            try {
                let response = await post(`conversations/message`, { message: msg, to: props.conversation.client._id });

                if (!response.success) {
                    setDisabled(true);
                    // antdMessage.error(response.message);
                }

                dispatch({
                    type: 'UPDATE_LAST_MESSAGE',
                    payload: {
                        conversation: props.conversation._id,
                        message: {
                            sender: 'user',
                            message: msg,
                            read: false,
                            date: moment(),
                        },
                    },
                });
            } catch (err) {
                antdMessage.error(err.message);
            }
        }

        scrollToBottom();
    };

    const handleScroll = async (e: any) => {
        const element = e.target;

        if (element.scrollTop < 1) {
            // Load more messages
            //@ts-ignore
            const height = chatRef.current.scrollHeight;

            await loadMessages();

            //@ts-ignore
            chatRef.current.scrollTop = chatRef.current.scrollHeight - height;
        }
    };

    const handleAddClientClick = () => {
        setAddClientModalVisible(true);
    };

    const hideAddClientModal = () => {
        setAddClientModalVisible(false);
    };

    if (!props.conversation) return <div />;

    return (
        <Container>
            <Header>
                {props.conversation.client.confirmedByUser
                    ? `${props.conversation.client.fullNameAlias} (${props.conversation.client.email})`
                    : props.conversation.client.email}
                <DotsMenu menuItems={menuItems} />
            </Header>
            <Messages onScroll={handleScroll} ref={chatRef}>
                {messages.map((message: any, index: any, arr: any) => {
                    const previousItem = arr[index - 1];
                    return (
                        <Message
                            key={`message-${index}`}
                            message={message}
                            previousMessage={previousItem && previousItem}
                        />
                    );
                })}
            </Messages>
            <MessageBox>
                {/* <IconContainer>
                    <FaImage></FaImage>
                </IconContainer> */}
                <MessageInputContainer>
                    <MessageInput
                        disabled={disabled}
                        onChange={(e: any) => {
                            setMessage(e.target.value);
                        }}
                        value={message}
                        onKeyPress={keyPress}
                        maxRows={8}
                        ref={textareaRef}
                        placeholder="Napisz wiadomość.."
                    ></MessageInput>
                    <SendButton>
                        <FaPaperPlane></FaPaperPlane>
                    </SendButton>
                </MessageInputContainer>
            </MessageBox>

            <AddClient
                onFinished={() => window.location.reload()}
                email={props.conversation.client.email}
                new={false}
                hideModalFunction={hideAddClientModal}
                visible={addClientModalVisible}
            />
        </Container>
    );
}
