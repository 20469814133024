import { Empty } from 'antd';
import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import Spinner from '../../../../../components/Spinner';
import { Conversation, MessageStatus } from '../../../../../interfaces/Conversation';
//@ts-ignore
import * as contrast from 'contrast';
import Colors from '../../../../../constants/Colors';
import Client from '../../../../../interfaces/Client';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { getDaysAndTimeLeft } from '../../../../../services/Date';
import { useHistory } from 'react-router-dom';

interface RecentMessagesPros {
    conversations: any;
    loading?: boolean;
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

const ConversationContainer = styled.div`
    display: flex;
    flex-direction: column;
    background: white;
    border-radius: 10px;
    width: 100%;
    padding: 8px;
    margin-bottom: 4px;
    cursor: pointer;

    &:hover {
        background-color: ${Colors.greys[150]};
    }
`;

const DateContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    color: ${Colors.greys[700]};
    font-size: 0.8em;
`;

const LeftPanelContainer = styled.div`
    height: 100%;
    margin-right: 1em;
`;

const RightPanelContainer = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
`;

const Avatar = styled.div<any>`
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background: ${(props) => props.color};
    color: ${(props) => {
        if (contrast(props.color) === 'light') return '#000';
        return '#fff';
    }};

    font-weight: 600;
    justify-content: center;
    align-items: center;
    display: flex;
    text-transform: uppercase;
`;

const ContentContainer = styled.div`
    display: flex;
    flex-direction: row;
`;

const Email = styled.div<any>`
    font-weight: ${(props) => (props.read ? 400 : 800)};
`;

const MessageContainer = styled.div<any>`
    font-weight: ${(props) => (props.read ? 400 : 700)};
    font-size: 0.8em;
    color: ${Colors.greys[800]};
`;

export default function RecentMessages(props: PropsWithChildren<RecentMessagesPros>) {
    let { conversations } = props;
    const unreadConversations = useSelector((state: any) => state.unreadConversations);
    const lastMessages = useSelector((state: any) => state.lastMessage);

    const history = useHistory();

    if (props.loading) {
        return <Spinner />;
    }

    if (!conversations || conversations.length == 0) {
        return <Empty description="Brak wiadomości" />;
    }

    const handleClick = (_id: any) => {
        history.push(`/app/messages?client=${_id}`);
    };

    return (
        <Container>
            {conversations.map((conversation: Conversation, index: any) => {
                if (!conversation.messages.length || index > 4) {
                    return <div />;
                }

                let read = !unreadConversations.includes(conversation._id);
                let c = lastMessages.find((c: any) => c.conversation == conversation._id);
                let lastMessage = {} as any;

                if (typeof c != 'undefined') {
                    lastMessage = c.message;
                }

                return (
                    <ConversationContainer
                        onClick={() => handleClick((conversation.client as Client)._id)}
                        key={`recent-messages-${index}`}
                    >
                        <DateContainer>{getDaysAndTimeLeft(conversation.messages[0].createdAt)}</DateContainer>
                        <ContentContainer>
                            <LeftPanelContainer>
                                <Avatar color={(conversation.client as Client).color}>
                                    {(conversation.client as Client).confirmedByUser
                                        ? (conversation.client as Client).fullNameAlias.substring(0, 1)
                                        : (conversation.client as Client).email.substring(0, 1)}
                                </Avatar>
                            </LeftPanelContainer>
                            <RightPanelContainer>
                                <Email read={read}>
                                    {(conversation.client as Client).confirmedByUser
                                        ? (conversation.client as Client).fullNameAlias
                                        : (conversation.client as Client).email}
                                </Email>
                                {lastMessage && typeof lastMessage.sender != 'undefined' && (
                                    <MessageContainer read={read}>
                                        {lastMessage.sender === 'user' && 'Ty: '}
                                        {lastMessage.message.substring(0, 255)}
                                        {lastMessage.message.length > 255 && '...'}
                                    </MessageContainer>
                                )}
                            </RightPanelContainer>
                        </ContentContainer>
                    </ConversationContainer>
                );
            })}
        </Container>
    );
}
