import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import RegisterView from './Register';
import LoginView from './Login';
import ActivateView from './Activate';
import AuthProvider from '../../components/AuthProvider';
import AppView from './App';

export default function AppRoute() {
    return (
        <Router>
            <Switch>
                <Route path="/app/login">
                    <LoginView />
                </Route>
                <Route path="/app/register">
                    <RegisterView />
                </Route>
                <Route path="/app/activate/:token">
                    <ActivateView />
                </Route>
                <Route path="/app">
                    <AuthProvider redirectTo="/app/login">
                        <AppView />
                    </AuthProvider>
                </Route>
            </Switch>
        </Router>
    );
}
