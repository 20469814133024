import { createGlobalState } from 'react-hooks-global-state';
import User from './interfaces/User';
import Portfolio from './interfaces/Portfolio';
import Client from './interfaces/Client';
import { CalendarSettings } from './interfaces/Calendar';

export const defaultState = {
    appLoading: false,
    user: {} as User,
    portfolio: {} as Portfolio,
    fullScreenImage: '',
    searchBarVisible: true,
    mainPanelPadding: '3em 3em',
    client: {} as Client,
    calendarSettings: {} as CalendarSettings,
    photoshootsAwaitingConfirmation: 0,
};

export const { useGlobalState } = createGlobalState(defaultState);
