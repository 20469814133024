import axios from 'axios';
import BadRequestException from '../../interfaces/BadRequestException';
import Response from '../../interfaces/Response';

interface PostOptions {
    useToken?: boolean;
    client?: boolean;
    optionalToken?: boolean;
}

export default async function post(endpoint: string, body?: any, options?: PostOptions) {
    options = { ...{ useToken: true, client: false, optionalToken: false }, ...options };

    let token;
    if (options.useToken) {
        if (options.client === true) {
            token = localStorage.getItem('jwt-client');
        } else {
            token = localStorage.getItem('jwt');
        }

        if (!token && !options.optionalToken) {
            throw new Error('No user token');
        }
    }

    let response;
    try {
        response = await axios.post(
            `${process.env.REACT_APP_API as string}/${endpoint}`,
            body || null,
            options.useToken
                ? {
                      headers: { Authorization: `Bearer ${token}` },
                  }
                : {},
        );
    } catch (err) {
        if (err.response && err.response.data && err.response.status === 400) {
            return { success: false, ...err.response.data } as BadRequestException;
        }

        if (err.response && err.response.data && err.response.status === 401) {
            return { success: false, ...err.response.data };
        }

        throw new Error(err.message);
    }

    return { success: true, ...response } as Response;
}
