import React, { useEffect } from 'react';
import { useGlobalState } from '../../../../../../state';
import ClientLayout from '../../components/ClientLayout';
import { BrowserRouter as Router, Switch, Route, useRouteMatch, useParams, useHistory } from 'react-router-dom';
import Client_Messages from '../Messages';
import Client_Photoshoots from '../Photoshoots';
import Client_Calendar from '../Calendar';

export default function Client_Home() {
    const match = useRouteMatch();

    const Logout = () => {
        useEffect(() => {
            localStorage.removeItem('jwt-client');
            window.location.reload();
        }, []);
        return <div />;
    };

    return (
        <ClientLayout>
            <Switch>
                <Route path={`${match.url}/logout`}>
                    <Logout />
                </Route>
                <Route path={`${match.url}/kalendarz`}>
                    <Client_Calendar />
                </Route>
                <Route path={`${match.url}/sesje`}>
                    <Client_Photoshoots />
                </Route>
                <Route path={`${match.url}/`}>
                    <Client_Messages />
                </Route>
            </Switch>
        </ClientLayout>
    );
}
