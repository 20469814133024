import React from 'react';
import styled from 'styled-components';
import { useGlobalState } from '../../state';

const FullScreen = styled.div<any>`
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
    background: rgba(255, 255, 255, 0.8);
    display: flex;
    flex-direction: column;
`;

const FullScreenHeader = styled.div`
    height: 24px;
    display: flex;
    align-items: center;
`;

const FullScreenImageContainer = styled.div`
    padding: 24px;
    padding-top: 0;
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;

    & > img {
        max-height: 100%;
        max-width: 100%;
        object-fit: contain;
    }
`;

export default function FullScreenImage() {
    const [fullScreenImage, setFullScreenImage] = useGlobalState('fullScreenImage');

    const fullScreenClose = (e: any) => {
        if (e.target.classList.contains('full-screen-image')) {
            return;
        }

        setFullScreenImage('');
    };

    return (
        <div>
            {fullScreenImage !== '' && (
                <FullScreen onClick={fullScreenClose}>
                    <FullScreenHeader></FullScreenHeader>
                    <FullScreenImageContainer>
                        <img className="full-screen-image" src={fullScreenImage}></img>
                    </FullScreenImageContainer>
                </FullScreen>
            )}
        </div>
    );
}
