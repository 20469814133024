import React, { useState } from 'react';
import Heading from '../../../../components/Heading';
import { Tabs, Button } from 'antd';
import PortfolioMain from './MainTab';
import PortfolioTemplate from './TemplateTab';
import PortfolioPhotos from './PhotosTab';

export default function AppPortfolio() {
    const { TabPane } = Tabs;
    const [activeTab, setActiveTab]: [string, Function] = useState('portfolio-main');

    const onTabChange = (activeTab: string) => {
        setActiveTab(activeTab);
    };

    return (
        <div>
            <Heading weight="strong">Portfolio</Heading>
            <div style={{ marginTop: 24 }}>
                <Tabs
                    onChange={onTabChange}
                    tabBarExtraContent={
                        <Button form={activeTab} htmlType="submit" type="primary">
                            Zapisz
                        </Button>
                    }
                    defaultActiveKey={activeTab}
                    tabBarStyle={{ marginBottom: '3em' }}
                >
                    <TabPane tab="Ogólne" key="portfolio-main">
                        <PortfolioMain />
                    </TabPane>
                    <TabPane tab="Szablon" key="portfolio-template">
                        <PortfolioTemplate />
                    </TabPane>
                    <TabPane tab="Zdjęcia" key="portfolio-photos">
                        <PortfolioPhotos />
                    </TabPane>
                    {/* <TabPane tab="Domena" key="4" disabled>
                        domena
                    </TabPane> */}
                </Tabs>
            </div>
        </div>
    );
}
