import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Input, Checkbox, Button, message, Radio } from 'antd';
import styled from 'styled-components';
import Label from '../../../../../components/Label';
import Breakpoints from '../../../../../constants/Breakpoints';
import { useGlobalState } from '../../../../../state';
import { get } from '../../../../../services/Requests';
import Response from '../../../../../interfaces/Response';
import { Store } from 'antd/lib/form/interface';
import put from '../../../../../services/Requests/put';
import validationHandler from '../../../../../services/ValidationHandler';
import SubLabel from '../../../../../components/SubLabel';
import ColorInput from '../../../../../components/ColorInput';
//@ts-ignore
import { SketchPicker } from 'react-color';
import Colors from '../../../../../constants/Colors';

const InputContainer = styled.div`
    max-width: ${Breakpoints.max.tablet}px;

    & .sketch-picker {
        /* max-width: 200px; */
        width: 100% !important;
        box-shadow: unset !important;
        position: relative;
        background: ${Colors.greys[200]} !important;
        box-sizing: border-box !important;
    }
`;

const { useForm } = Form;

export default function PortfolioTemplate() {
    const [form] = useForm();
    const [loading, setLoading] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [success, setSuccess] = useState('');

    const [appLoading, setAppLoading] = useGlobalState('appLoading');
    const [photoshootTypes, setPhotoshootTypes] = useState([]);
    const [themes, setThemes] = useState([]);

    const [backgroundColorPicker, setBackgroundColorPicker] = useState('');
    const [primaryColorPicker, setPrimaryColorPicker] = useState('');
    const [secondaryColorPicker, setSecondaryColorPicker] = useState('');
    const [accentsColorPicker, setAccentsColorPicker] = useState('');

    useEffect(() => {
        // Load available themes
        (async () => {
            try {
                setAppLoading(true);
                let response = await get('themes');

                if (response.success) {
                    response = response as Response;

                    setThemes(response.data);
                    setAppLoading(false);
                }
            } catch (err) {
                // TODO: handle error
            }
        })();

        // Load portfolio data
        (async () => {
            try {
                setAppLoading(true);
                let response = await get('portfolio/template');

                if (response.success) {
                    response = response as Response;

                    form.setFieldsValue({
                        theme: response.data.theme._id,
                        'colors.background': response.data.colors.background,
                        'colors.primary': response.data.colors.primary,
                        'colors.secondary': response.data.colors.secondary,
                        'colors.accents': response.data.colors.accents,
                    });

                    setBackgroundColorPicker(response.data.colors.background);
                    setPrimaryColorPicker(response.data.colors.primary);
                    setSecondaryColorPicker(response.data.colors.secondary);
                    setAccentsColorPicker(response.data.colors.accents);

                    setAppLoading(false);
                }
            } catch (err) {
                // TODO: handle error
            }
        })();
    }, []);

    const formSubmit = async (values: any) => {
        setLoading(true);
        setDisabled(true);

        try {
            let response = await put('portfolio/template', values);

            if (!response.success) {
                let errorMessage = validationHandler(response, form);
                // TODO: HANDLE ERROR

                setDisabled(false);
            } else {
                response = response as Response;

                message.success('Pomyślnie zaktualizowano portfolio.');
            }

            setLoading(false);
            setDisabled(false);
        } catch (err) {
            // TODO: HANDLE ERROR
        }
    };

    return (
        <Form id="portfolio-template" form={form} onFinish={formSubmit}>
            <InputContainer>
                <Row gutter={[0, 12]}>
                    <Col span={24}>
                        <Label>Szablon</Label>
                        <Form.Item name="theme" help={null} rules={[]}>
                            <Radio.Group style={{ width: '100%' }}>
                                <Row gutter={[0, 6]}>
                                    {themes.map((theme: any, index: number) => {
                                        return (
                                            <Col key={`themes-${index}`} xs={12} md={8} lg={6}>
                                                <Radio disabled={disabled} value={theme._id}>
                                                    {theme.name}
                                                </Radio>
                                            </Col>
                                        );
                                    })}
                                </Row>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[0, 12]}>
                    <Col span={24}>
                        <Label>Kolory</Label>
                        <Row gutter={[24, 12]}>
                            <Col md={12} lg={6}>
                                <SubLabel>Kolor tła</SubLabel>
                                <Form.Item
                                    name="colors.background"
                                    help={null}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Musisz podać kolor tła',
                                        },
                                    ]}
                                >
                                    <Input type="hidden" disabled={disabled} placeholder="" />
                                    <SketchPicker
                                        color={backgroundColorPicker}
                                        onChange={(color) => {
                                            setBackgroundColorPicker(color.hex);
                                            form.setFieldsValue({ 'colors.background': color.hex });
                                        }}
                                    ></SketchPicker>
                                </Form.Item>
                            </Col>
                            <Col md={12} lg={6}>
                                <SubLabel>Kolor podstawowy</SubLabel>
                                <Form.Item
                                    name="colors.primary"
                                    help={null}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Musisz podać kolor podstawowy',
                                        },
                                    ]}
                                >
                                    <Input type="hidden" disabled={disabled} placeholder="" />
                                    <SketchPicker
                                        color={primaryColorPicker}
                                        onChange={(color) => {
                                            setPrimaryColorPicker(color.hex);
                                            form.setFieldsValue({ 'colors.primary': color.hex });
                                        }}
                                    ></SketchPicker>
                                </Form.Item>
                            </Col>
                            <Col md={12} lg={6}>
                                <SubLabel>Kolor drugorzędny</SubLabel>
                                <Form.Item
                                    name="colors.secondary"
                                    help={null}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Musisz podać kolor drugorzędny',
                                        },
                                    ]}
                                >
                                    <Input type="hidden" disabled={disabled} placeholder="" />
                                    <SketchPicker
                                        color={secondaryColorPicker}
                                        onChange={(color) => {
                                            setSecondaryColorPicker(color.hex);
                                            form.setFieldsValue({ 'colors.secondary': color.hex });
                                        }}
                                    ></SketchPicker>
                                </Form.Item>
                            </Col>
                            <Col md={12} lg={6}>
                                <SubLabel>Kolor akcentów</SubLabel>
                                <Form.Item
                                    name="colors.accents"
                                    help={null}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Musisz podać kolor akcentów',
                                        },
                                    ]}
                                >
                                    <Input type="hidden" disabled={disabled} placeholder="" />
                                    <SketchPicker
                                        color={accentsColorPicker}
                                        onChange={(color) => {
                                            setAccentsColorPicker(color.hex);
                                            form.setFieldsValue({ 'colors.accents': color.hex });
                                        }}
                                    ></SketchPicker>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </InputContainer>
        </Form>
    );
}
