import React, { useState } from 'react';
import Heading from '../../../../components/Heading';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import ClientsList from './List';
import ClientsClient from './Client';

export default function AppClients() {
    const match = useRouteMatch();

    return (
        <div>
            <Heading weight="strong">Klienci</Heading>
            <div style={{ marginTop: 24 }}>
                <Switch>
                    <Route path={`${match.url}/:id`}>
                        <ClientsClient />
                    </Route>
                    <Route path={`${match.url}`}>
                        <ClientsList />
                    </Route>
                </Switch>
            </div>
        </div>
    );
}
