import React, { useState } from 'react';
import Heading from '../../../../components/Heading';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import PhotoshootsList from './List';
import PhotoshootsActivities from './Types';
import PhotoshootsNew from './New';
import PhotoshootsPhotoshoot from './Photoshoot';

export default function AppPhotoshoots() {
    const match = useRouteMatch();

    return (
        <div>
            <Heading weight="strong">Sesje</Heading>
            <div style={{ marginTop: 24 }}>
                <Switch>
                    <Route path={`${match.url}/activities`}>
                        <PhotoshootsActivities />
                    </Route>
                    <Route path={`${match.url}/new`}>
                        <PhotoshootsNew />
                    </Route>
                    <Route path={`${match.url}/:id`}>
                        <PhotoshootsPhotoshoot />
                    </Route>
                    <Route path={`${match.url}`}>
                        <PhotoshootsList />
                    </Route>
                </Switch>
            </div>
        </div>
    );
}
