import React, { PropsWithChildren, ReactElement, useEffect } from 'react';
import { FaCalendar, FaComment, FaImages, FaSignOutAlt } from 'react-icons/fa';
import { useHistory, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import { useGlobalState } from '../../../../../state';
import color from 'color';

interface NavigationElement {
    title: string;
    address: string;
    icon?: ReactElement;
    exactPath?: boolean;
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 2em 1.5em;
`;

const ListItemContainer = styled.div<any>`
    width: 100%;
    padding: 0.7em 1em;
    background-color: ${(props) => (props.active ? props.theme.primary : 'transparent')};
    border-radius: 8px;
    font-family: 'Roboto';
    font-weight: 500;
    display: flex;
    align-items: center;
    transition: 0.15s all;
    cursor: pointer;
    margin-top: 4px;

    &:first-of-type {
        margin-top: 0;
    }

    &:hover {
        background: ${(props) => !props.active && props.hoverColor};
        background-color: ${(props) => !props.active && color(props.theme.primary).lighten(0.15).hex()};
    }
`;

const ListItemIcon = styled.div`
    display: flex;
    align-items: center;
    margin-right: 1em;
`;

export default function Client_Navigation() {
    const history = useHistory();
    const match = useRouteMatch();
    const [portfolio] = useGlobalState('portfolio');

    const navigateTo = (location: string) => {
        history.push(location);
    };

    const ListItem = (props: PropsWithChildren<any>) => {
        return (
            <ListItemContainer active={props.active}>
                {props.item.icon && <ListItemIcon>{props.item.icon}</ListItemIcon>}
                {props.item.title}
            </ListItemContainer>
        );
    };

    const navigationElements: NavigationElement[] = [
        {
            title: 'Wiadomości',
            address: `/user/${portfolio.slug}/client`,
            icon: <FaComment />,
            exactPath: true,
        },
        {
            title: 'Sesje',
            address: `/user/${portfolio.slug}/client/sesje/`,
            icon: <FaImages />,
        },
        {
            title: 'Kalendarz',
            address: `/user/${portfolio.slug}/client/kalendarz/`,
            icon: <FaCalendar />,
        },
        {
            title: 'Wyloguj',
            address: `/user/${portfolio.slug}/client/logout/`,
            icon: <FaSignOutAlt />,
        },
    ];

    return (
        <Container>
            {navigationElements.map((item, index) => {
                let active = false;

                if (item.exactPath) {
                    active =
                        history.location.pathname == item.address || history.location.pathname + '/' == item.address;
                } else {
                    active = history.location.pathname.includes(item.address);
                }

                return (
                    <div key={`menu-item-${index}`} style={{ width: '100%' }} onClick={() => navigateTo(item.address)}>
                        <ListItem active={active} item={item} />
                    </div>
                );
            })}
        </Container>
    );
}
