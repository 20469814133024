import React, { PropsWithChildren, useCallback, useState } from 'react';
import styled from 'styled-components';
import { PhotoshootPhoto } from '../../interfaces/Photoshoot';
import Gallery from 'react-photo-gallery';
import { getImageURL } from '../../services/Paths';
//@ts-ignore
import { SRLWrapper } from 'simple-react-lightbox';
import { useGlobalState } from '../../state';

interface GalleryProps {
    photos: PhotoshootPhoto[];
    lightBox?: boolean;
    margin?: number;
    fullScreenImage?: boolean;
}

const Container = styled.div``;

export default function GalleryComponent(props: PropsWithChildren<GalleryProps>) {
    const [fullScreen, setFullScreen] = useGlobalState('fullScreenImage');

    let { photos, lightBox, margin, fullScreenImage } = props;
    if (!photos || photos.length == 0) {
        return <div />;
    }

    if (typeof lightBox == 'undefined') {
        lightBox = true;
    }

    if (typeof margin == 'undefined') {
        margin = 2;
    }

    if (typeof fullScreenImage == 'undefined') {
        fullScreenImage = false;
    }

    const handleGalleryClick = (event: any, data: any) => {
        if (fullScreenImage) {
            setFullScreen(data.photo.fullSize);
        }
    };

    return (
        <Container>
            {lightBox ? (
                <SRLWrapper>
                    <Gallery
                        margin={margin}
                        renderImage={(data: any) => {
                            return (
                                <a href={data.photo.fullSize} data-attribute="SRL">
                                    <img
                                        style={{
                                            width: `${data.photo.width}px`,
                                            height: `${data.photo.height}px`,
                                            margin: `${data.margin}px`,
                                        }}
                                        src={data.photo.src}
                                    />
                                </a>
                            );
                        }}
                        photos={photos.map((photo: PhotoshootPhoto) => {
                            return {
                                src: getImageURL(photo),
                                width: photo.width,
                                height: photo.height,
                                fullSize: getImageURL(photo, false),
                            };
                        })}
                    />
                </SRLWrapper>
            ) : (
                <Gallery
                    onClick={handleGalleryClick}
                    margin={margin}
                    photos={photos.map((photo: PhotoshootPhoto) => {
                        return {
                            src: getImageURL(photo),
                            width: photo.width,
                            height: photo.height,
                            fullSize: getImageURL(photo, false),
                        };
                    })}
                />
            )}
        </Container>
    );
}
