import React, { PropsWithChildren, useEffect, ReactElement } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import Colors from '../../../../../../../constants/Colors';
import { FaTachometerAlt, FaBriefcase, FaImages, FaComment, FaCalendar, FaUsers } from 'react-icons/fa';
import { Badge } from 'antd';
import { useGlobalState } from '../../../../../../../state';
import { useSelector } from 'react-redux';

interface NavigationElement {
    title: string | JSX.Element;
    activeExactAddress?: boolean;
    address: string;
    icon?: ReactElement;
    children?: NavigationElement[];
    addon?: JSX.Element;
}

interface ListItemProps {
    item: NavigationElement;
    active: boolean;
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 0 1.5em;
`;

const ListItemContainer = styled.div<{ active: boolean }>`
    width: 100%;
    padding: 0.7em 1em;
    background: ${(props) => (props.active ? Colors.accents.main[300] : 'transparent')};
    border-radius: 8px;
    font-family: 'Roboto';
    font-weight: 500;
    display: flex;
    align-items: center;
    transition: 0.15s all;
    cursor: pointer;
    margin-top: 4px;
    font-weight: ${(props) => (props.active ? '600' : '500')};

    &:first-of-type {
        margin-top: 0;
    }

    &:hover {
        background: ${(props) => !props.active && Colors.accents.main[100]};
    }
`;

const ElementContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

const ChildContainer = styled.div``;
const ChildElement = styled.div<any>`
    padding: 0.7em 1em;
    padding-left: calc(1em + 14px + 1em);
    border-radius: 6px;
    cursor: pointer;

    font-weight: ${(props) => (props.active ? '600' : '300')};

    &:hover {
        background: ${Colors.greys[200]};
    }
`;

const ListItemIcon = styled.div`
    display: flex;
    align-items: center;
    margin-right: 1em;
`;

const ListItemAddon = styled.div`
    margin-left: 0.5em;
`;

const ListItem = (props: PropsWithChildren<ListItemProps>) => {
    useEffect(() => {});

    return (
        <ListItemContainer active={props.active}>
            {props.item.icon && <ListItemIcon>{props.item.icon}</ListItemIcon>}
            {props.item.title}
            {props.item.addon && <ListItemAddon>{props.item.addon}</ListItemAddon>}
        </ListItemContainer>
    );
};

export default function Navigation() {
    const history = useHistory();

    const [photoshootsAwaitingConfirmation] = useGlobalState('photoshootsAwaitingConfirmation');
    const unreadConversations = useSelector((state: any) => state.unreadConversations);

    const navigationElements: NavigationElement[] = [
        {
            title: 'Panel',
            address: '/app',
            icon: <FaTachometerAlt />,
            activeExactAddress: true,
        },
        {
            title: 'Portfolio',
            address: '/app/portfolio',
            icon: <FaBriefcase />,
        },
        {
            title: 'Kalendarz',
            address: '/app/calendar',
            icon: <FaCalendar />,
            children: [
                {
                    title: 'Ustawienia',
                    address: '/app/calendar',
                    activeExactAddress: true,
                },
                {
                    title: 'Dostępność',
                    address: '/app/calendar/availability',
                },
            ],
        },
        {
            title: 'Sesje',
            addon: (
                <Badge
                    style={{
                        backgroundColor: Colors.accents.main[800],
                        paddingLeft: '8px',
                        paddingRight: '8px',
                        borderColor: 'transparent',
                    }}
                    size="small"
                    count={photoshootsAwaitingConfirmation}
                />
            ),
            address: '/app/photoshoots',
            icon: <FaImages />,
            children: [
                {
                    title: 'Przegląd',
                    address: '/app/photoshoots',
                    activeExactAddress: true,
                },
                {
                    title: 'Nowa sesja',
                    address: '/app/photoshoots/new',
                },
                {
                    title: 'Rodzaje sesji',
                    address: '/app/photoshoots/activities',
                },
            ],
        },
        {
            title: 'Klienci',
            address: '/app/clients',
            icon: <FaUsers />,
        },
        {
            title: 'Wiadomości',
            address: '/app/messages',
            icon: <FaComment />,
            addon: (
                <Badge
                    style={{
                        backgroundColor: Colors.accents.main[800],
                        paddingLeft: '8px',
                        paddingRight: '8px',
                        borderColor: 'transparent',
                    }}
                    size="small"
                    count={unreadConversations.length}
                />
            ),
        },
    ];

    const navigateTo = (location: string) => {
        history.push(location);
    };

    return (
        <Container>
            {navigationElements.map((item, index) => {
                let active = history.location.pathname.includes(item.address);
                if (item.activeExactAddress === true) {
                    active = history.location.pathname === item.address;
                }

                return (
                    <ElementContainer key={`menu-item-${index}`}>
                        <div style={{ width: '100%' }} onClick={() => navigateTo(item.address)}>
                            <ListItem active={active} item={item} />
                        </div>

                        {active &&
                            item.children &&
                            item.children.map((child, childIndex) => {
                                let childActive = history.location.pathname.includes(child.address);
                                if (child.activeExactAddress === true) {
                                    childActive = history.location.pathname === child.address;
                                }

                                return (
                                    <ChildContainer
                                        key={`menu-item-${index}-child-${childIndex}`}
                                        onClick={() => navigateTo(child.address)}
                                    >
                                        <ChildElement active={childActive}>{child.title}</ChildElement>
                                    </ChildContainer>
                                );
                            })}
                    </ElementContainer>
                );
            })}
        </Container>
    );
}
