import React, { useEffect, useState } from 'react';
import CalendarComponent from '../../../../../../domain/Photographer/Portfolio/components/Calendar';
import styled from 'styled-components';
import { Button, Select } from 'antd';
import moment, { Moment } from 'moment';
import { getCurrentWeekOfMonth, getMondaysOfMonth } from '../../../../../../services/Weeks';
import { useGlobalState } from '../../../../../../state';
import get from '../../../../../../services/Requests/get';
import Heading from '../../../../../../components/Heading';
import Client_CalendarModal from './bookModal';

const SelectWeekContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 2em;
`;

const SelectWeekManipulationButtons = styled.div`
    display: flex;
    flex-direction: row;
    margin-right: 1.5em;
    flex-wrap: wrap;

    & > * {
        margin-right: 0.5em;
        margin-bottom: 0.5em;
    }
`;

const SelectWeekSelects = styled.div`
    display: flex;
    flex-direction: row;
    margin-right: 1.5em;
    flex-wrap: wrap;
    margin-bottom: 0.7em;

    & > * {
        margin-right: 0.5em;
        margin-bottom: 0.5em;
    }
`;

const { Option } = Select;

export default function Client_Calendar() {
    const [appLoading, setAppLoading] = useGlobalState('appLoading');
    const [portfolio, setPortoflio] = useGlobalState('portfolio');
    const [calendarSettings, setCalendarSettings] = useGlobalState('calendarSettings');

    const [selectedYear, setSelectedYear] = useState(moment().format('YYYY'));
    const [selectedMonth, setSelectedMonth] = useState(moment().format('MM'));
    const [selectedWeek, setSelectedWeek] = useState(getCurrentWeekOfMonth());
    const [selectedDate, setSelectedDate]: [Moment | undefined, Function] = useState();

    const [week, setWeek]: [any, Function] = useState(null);
    const [weekLoading, setWeekLoading] = useState(false);
    const [weeksList, setWeeksList] = useState([]);

    const [availableWeeks, setAvailableWeeks]: [Moment[], Function] = useState([]);
    const availableYears = [
        moment().format('YYYY'),
        moment().add(1, 'years').format('YYYY'),
        moment().add(2, 'years').format('YYYY'),
    ];
    const availableMonths = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

    useEffect(() => {
        let currentWeek;
        if (availableWeeks.length > 0 && typeof selectedWeek != 'undefined') {
            if (typeof availableWeeks[selectedWeek - 1] !== 'undefined') {
                currentWeek = availableWeeks[selectedWeek - 1];
            }
        }

        setSelectedDate(currentWeek);
    }, [selectedWeek, availableWeeks]);

    useEffect(() => {
        setAppLoading(true);
        (async () => {
            setWeekLoading(true);

            try {
                const response = await get(`calendar/availability?photographer=${portfolio.slug}`, { useToken: false });

                if (response.success) {
                    setWeeksList(response.data.weeks);
                    setCalendarSettings(response.data.calendarSettings);

                    let week = response.data.weeks.find((el: any) => {
                        return el.year == selectedYear && el.month == selectedMonth && el.week == selectedWeek;
                    });

                    if (typeof week != 'undefined') {
                        setWeek(week);
                    } else {
                        setWeek(null);
                    }
                }
            } catch (err) {
                /// TODO: handle error
            }

            setWeekLoading(false);
            setAppLoading(false);
        })();
    }, []);

    useEffect(() => {
        handleWeekAndYearChange(selectedYear, selectedMonth);
    }, []);

    useEffect(() => {
        let week = weeksList.find((el: any) => {
            return el.year == selectedYear && el.month == selectedMonth && el.week == selectedWeek;
        });

        if (typeof week != 'undefined') {
            setWeek(week);
        } else {
            setWeek(null);
        }
    }, [selectedYear, selectedMonth, selectedWeek]);

    const handleWeekAndYearChange = (selectedYear: any, selectedMonth: any) => {
        let mondays = getMondaysOfMonth(selectedYear, selectedMonth);

        setAvailableWeeks(mondays);

        if (selectedYear === moment().format('YYYY') && selectedMonth === moment().format('MM')) {
            setSelectedWeek(getCurrentWeekOfMonth());
        } else {
            setSelectedWeek(1);
        }
    };

    const handleChangeToday = () => {
        const currentWeek = getCurrentWeekOfMonth();
        setSelectedYear(moment().format('YYYY'));
        setSelectedMonth(parseInt(moment().format('MM')).toString());
        setSelectedWeek(currentWeek);
    };

    const handleChangePreviousWeek = () => {
        let mondays = getMondaysOfMonth(selectedYear, selectedMonth);
        let currentWeek = mondays[(selectedWeek as number) - 1];

        currentWeek.subtract(1, 'week');

        setSelectedYear(currentWeek.format('YYYY'));
        //@ts-ignore
        setSelectedMonth(parseInt(currentWeek.format('MM')).toString());

        let newMondays = getMondaysOfMonth(currentWeek.format('YYYY'), currentWeek.format('MM'));

        if ((selectedWeek as number) > 1) {
            setSelectedWeek((selectedWeek as number) - 1);
        } else {
            setSelectedWeek(newMondays.length);
        }
    };

    const handleChangeNextWeek = () => {
        let mondays = getMondaysOfMonth(selectedYear, selectedMonth);
        let currentWeek = mondays[(selectedWeek as number) - 1];

        currentWeek.add(1, 'week');

        setSelectedYear(currentWeek.format('YYYY'));
        //@ts-ignore
        setSelectedMonth(parseInt(currentWeek.format('MM')).toString());

        let newMondays = getMondaysOfMonth(currentWeek.format('YYYY'), currentWeek.format('MM'));
        if ((selectedWeek as number) < mondays.length) {
            setSelectedWeek((selectedWeek as number) + 1);
        } else {
            setSelectedWeek(1);
        }
    };

    return (
        <div style={{ marginTop: '2em' }}>
            <Heading>Kalendarz</Heading>
            <SelectWeekContainer>
                <SelectWeekSelects>
                    <Select
                        value={selectedYear}
                        onChange={(year: any) => {
                            setSelectedYear(year);
                            handleWeekAndYearChange(year, selectedMonth);
                        }}
                    >
                        {availableYears.map((year, index) => (
                            <Option key={`select-year-option-${index}`} value={year}>
                                {year}
                            </Option>
                        ))}
                    </Select>
                    <Select
                        value={selectedMonth}
                        style={{ width: '120px' }}
                        onChange={(month: any) => {
                            setSelectedMonth(month);
                            handleWeekAndYearChange(selectedYear, month);
                        }}
                    >
                        {availableMonths.map((month, index) => (
                            <Option key={`select-month-option-${index}`} value={month.toString()}>
                                {moment()
                                    .month(month - 1)
                                    .format('MMMM')}
                            </Option>
                        ))}
                    </Select>
                    <Select
                        value={selectedWeek}
                        style={{ width: '100px' }}
                        onChange={(week: any) => setSelectedWeek(week)}
                    >
                        {availableWeeks.map((monday, index) => (
                            <Option key={`select-week-option-${index}`} value={index + 1}>
                                tydzień {index + 1}
                            </Option>
                        ))}
                    </Select>
                </SelectWeekSelects>

                <SelectWeekManipulationButtons>
                    <Button onClick={handleChangeToday} size="small">
                        Dzisiaj
                    </Button>
                    <Button onClick={handleChangePreviousWeek} size="small">
                        Poprzedni tydzień
                    </Button>
                    <Button onClick={handleChangeNextWeek} size="small">
                        Następny tydzień
                    </Button>
                </SelectWeekManipulationButtons>
            </SelectWeekContainer>
            {week ? (
                <CalendarComponent clientPanel={true} modal={<Client_CalendarModal />} week={week} />
            ) : (
                <div>Brak dostępności w tym tygodniu.</div>
            )}
        </div>
    );
}
