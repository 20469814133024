import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Heading from '../../../../../../../components/Heading';
import { Tabs, Button, Badge, Table, message, Popover, Space, Popconfirm } from 'antd';
import { Photoshoot, PhotoshootStatus } from '../../../../../../../interfaces/Photoshoot';
import { Link, useHistory } from 'react-router-dom';
import Client from '../../../../../../../interfaces/Client';
import { useGlobalState } from '../../../../../../../state';
import moment from 'moment';
import { get, post } from '../../../../../../../services/Requests';

const Container = styled.div`
    margin-top: 2em;
`;

const Content = styled.div`
    margin-top: 2em;
`;

export default function Client_PhotoshootsList() {
    const { TabPane } = Tabs;
    const [activeTab, setActiveTab]: [string, Function] = useState('photoshoots-list-awaiting');

    const [appLoading, setAppLoading] = useGlobalState('appLoading');
    const [portfolio] = useGlobalState('portfolio');
    const history = useHistory();

    const [tableLoading, setTableLoading] = useState(false);
    const [photoshoots, setPhotoshoots] = useState([]);

    useEffect(() => {
        loadPhotoshoots();
    }, []);

    const loadPhotoshoots = async () => {
        setTableLoading(true);
        try {
            let response = await get(`photoshoots`, { client: true });

            if (!response.success) {
                message.error('Nie udało się załadować sesji. Spróbuj ponownie później.');
                return;
            }

            setPhotoshoots(response.data);
            setTableLoading(false);
        } catch (err) {
            message.error('Wystąpił błąd podczas ładowania sesji.');
        }
    };

    const onTabChange = (activeTab: string) => {
        setActiveTab(activeTab);
    };

    const handleConfirm = async (id: any) => {
        setAppLoading(true);
        try {
            let response = await post(`photoshoots/${id}/confirm`, {}, { client: true });
            if (!response.success) {
                let error = response.message;
                message.error(`Wystąpił błąd podczas potwierdzania sesji: ${error}`);
                setAppLoading(false);
                return;
            }

            message.success('Pomyślnie potwierdzono sesję.');
            setAppLoading(false);

            loadPhotoshoots();
        } catch (err) {
            message.error(`Wystąpił błąd podczas potwierdzania sesji. Spróbuj ponownie później.`);
            setAppLoading(false);
        }
    };

    const PhotoshootTable = (props: any) => {
        let buttons: any = [];

        if (props.status == PhotoshootStatus.AWAITING_CLIENT_CONFIRMATION) {
            buttons = [
                ...buttons,
                (record: any) => (
                    <Popconfirm
                        cancelText="Nie"
                        okText="Tak"
                        title="Czy jesteś pewny/a, że chcesz potwierdzić tą sesję zdjęciową?"
                        onConfirm={() => handleConfirm(record._id)}
                    >
                        <Button size="small">Potwierdź sesję</Button>
                    </Popconfirm>
                ),
            ];
        }

        if (props.status == PhotoshootStatus.FINISHED) {
            buttons = [
                ...buttons,
                (record: any) => (
                    <Button
                        size="small"
                        onClick={() => history.push(`/user/${portfolio.slug}/client/sesje/${record._id}`)}
                    >
                        Zobacz zdjęcia
                    </Button>
                ),
            ];
        }

        const columns: any[] = [
            {
                title: 'Data',
                sort: true,
                render: (data: any, record: Photoshoot) => {
                    return `${moment(record.datetime).format('YYYY-MM-DD (dddd)')}, ${moment(record.datetime).format(
                        'HH:mm',
                    )}`;
                },
            },
            {
                title: 'Rodzaj sesji',
                render: (data: any, record: Photoshoot) => {
                    return record.type.name;
                },
            },
            {
                title: 'Akcja',
                render: (data: any, record: Photoshoot) => {
                    if (buttons.length == 0) return `-`;

                    return (
                        <Space>
                            {buttons.map((btn: any, index: any) => {
                                return btn(record);
                            })}
                        </Space>
                    );
                },
            },
        ];

        return (
            <Table
                loading={tableLoading}
                dataSource={photoshoots && photoshoots.filter((photoshoot: any) => photoshoot.status == props.status)}
                columns={columns}
                rowClassName={(photoshoot: Photoshoot) => {
                    if (photoshoot.status == PhotoshootStatus.FINISHED) {
                        return `cursor-pointer`;
                    }

                    return ``;
                }}
                onRow={(photoshoot: Photoshoot, rowIndex) => {
                    return {
                        onClick: (e: any) => {
                            if (photoshoot.status == PhotoshootStatus.FINISHED) {
                                if (
                                    e.target.nodeName == 'BUTTON' ||
                                    e.target.parentNode.nodeName == 'BUTTON' ||
                                    e.target.nodeName == 'A'
                                ) {
                                    return;
                                }

                                history.push(`/user/${portfolio.slug}/client/sesje/${photoshoot._id}`);
                            }
                        },
                    };
                }}
            ></Table>
        );
    };

    return (
        <Container>
            <Heading>Sesje zdjęciowe</Heading>

            <Content>
                <Tabs onChange={onTabChange} defaultActiveKey={activeTab} tabBarStyle={{ marginBottom: '3em' }}>
                    <TabPane tab="Oczekujące Twojego potwierdzenia" key="photoshoot-awaiting-client">
                        <PhotoshootTable status={PhotoshootStatus.AWAITING_CLIENT_CONFIRMATION} />
                    </TabPane>
                    <TabPane tab="Oczekujące potwierdzenia fotografa" key="photoshoot-awaiting-user">
                        <PhotoshootTable status={PhotoshootStatus.AWAITING_USER_CONFIRMATION} />
                    </TabPane>
                    <TabPane tab="Odrzucone" key="photoshoot-rejected">
                        <PhotoshootTable status={PhotoshootStatus.REJECTED} />
                    </TabPane>
                    <TabPane tab="Anulowane" key="photoshoot-canceled">
                        <PhotoshootTable status={PhotoshootStatus.CANCELED} />
                    </TabPane>
                    <TabPane tab="Potwierdzone" key="photoshoot-confirmed">
                        <PhotoshootTable status={PhotoshootStatus.CONFIRMED} />
                    </TabPane>
                    <TabPane tab="Zakończone" key="photoshoot-finished">
                        <PhotoshootTable status={PhotoshootStatus.FINISHED} />
                    </TabPane>
                </Tabs>
            </Content>
        </Container>
    );
}
