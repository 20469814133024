import React from 'react';
import './App.css';
import Views from './views';
import AppLoading from './components/AppLoading';
import FullScreenImage from './components/FullScreenImage';
import { createStore } from 'redux';
import rootReducer from './reducers/rootReducer';
import { Provider } from 'react-redux';
//@ts-ignore
import SimpleReactLightbox from 'simple-react-lightbox';

const store = createStore(rootReducer);

function App() {
    return (
        <Provider store={store}>
            <SimpleReactLightbox>
                <Views />
                <FullScreenImage />
                <AppLoading />
            </SimpleReactLightbox>
        </Provider>
    );
}

export default App;
