import moment from 'moment';

export const getDaysAndTimeLeft = (date: any) => {
    date = moment(date);

    let diffInDays = date.diff(moment(), 'days');
    let diffInHours = date.diff(moment(), 'hours');
    let diffInMinutes = date.diff(moment(), 'minutes');

    if (diffInDays > 1) {
        return `za ${diffInDays} dni`;
    }

    if (diffInDays == 1) {
        diffInHours = diffInHours - 24;

        if (
            diffInHours < 5 ||
            (diffInHours > 21 && diffInHours < 25) ||
            (diffInHours > 31 && diffInHours < 35) ||
            (diffInHours > 41 && diffInHours < 45) ||
            (diffInHours > 51 && diffInHours < 55)
        ) {
            return `za 1 dzień i ${diffInHours} godziny`;
        }

        return `za 1 dzień i ${diffInHours} godzin`;
    }

    if (diffInHours > 1) {
        if (
            diffInHours < 5 ||
            (diffInHours > 21 && diffInHours < 25) ||
            (diffInHours > 31 && diffInHours < 35) ||
            (diffInHours > 41 && diffInHours < 45) ||
            (diffInHours > 51 && diffInHours < 55)
        ) {
            return `za ${diffInHours} godziny`;
        }

        return `za ${diffInHours} godzin`;
    }

    if (diffInHours == 1) {
        diffInMinutes = diffInMinutes - 60;

        if (
            diffInMinutes < 5 ||
            (diffInMinutes > 21 && diffInMinutes < 25) ||
            (diffInMinutes > 31 && diffInMinutes < 35) ||
            (diffInMinutes > 41 && diffInMinutes < 45) ||
            (diffInMinutes > 51 && diffInMinutes < 55)
        ) {
            return `za godzinę i ${diffInMinutes} minuty`;
        }

        return `za godzinę i ${diffInMinutes} minut`;
    }

    if (diffInMinutes > 1) {
        if (diffInMinutes < 5) {
            return `za ${diffInMinutes} minuty`;
        }

        return `za ${diffInMinutes} minut`;
    }

    if (diffInMinutes == 1) {
        return `za minutę`;
    }

    if (diffInMinutes > 0) {
        return `za mniej niż minutę`;
    }

    if (diffInMinutes == 0) {
        return `Mniej niż minutę temu`;
    }

    // Przeszłość
    diffInDays = -1 * diffInDays;
    diffInHours = -1 * diffInHours;
    diffInMinutes = -1 * diffInMinutes;

    if (diffInDays > 1) {
        return `${diffInDays} dni temu`;
    }

    if (diffInDays == 1) {
        diffInHours = diffInHours - 24;

        if (
            diffInHours < 5 ||
            (diffInHours > 21 && diffInHours < 25) ||
            (diffInHours > 31 && diffInHours < 35) ||
            (diffInHours > 41 && diffInHours < 45) ||
            (diffInHours > 51 && diffInHours < 55)
        ) {
            return `1 dzień i ${diffInHours} godziny temu`;
        }

        return `1 dzień i ${diffInHours} godzin temu`;
    }

    if (diffInHours > 1) {
        if (
            diffInHours < 5 ||
            (diffInHours > 21 && diffInHours < 25) ||
            (diffInHours > 31 && diffInHours < 35) ||
            (diffInHours > 41 && diffInHours < 45) ||
            (diffInHours > 51 && diffInHours < 55)
        ) {
            return `${diffInHours} godziny temu`;
        }

        return `${diffInHours} godzin temu`;
    }

    if (diffInHours == 1) {
        diffInMinutes = diffInMinutes - 60;

        if (
            diffInMinutes < 5 ||
            (diffInMinutes > 21 && diffInMinutes < 25) ||
            (diffInMinutes > 31 && diffInMinutes < 35) ||
            (diffInMinutes > 41 && diffInMinutes < 45) ||
            (diffInMinutes > 51 && diffInMinutes < 55)
        ) {
            return `Godzinę i ${diffInMinutes} minuty temu`;
        }

        return `Godzinę i ${diffInMinutes} minut temu`;
    }

    if (diffInMinutes > 1) {
        if (diffInMinutes < 5) {
            return `${diffInMinutes} minuty temu`;
        }

        return `${diffInMinutes} minut temu`;
    }

    if (diffInMinutes == 1) {
        return `Minutę temu`;
    }

    if (diffInMinutes > 0) {
        return `Mniej niż minutę temu`;
    }
};
