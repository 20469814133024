import axios from 'axios';
import BadRequestException from '../../interfaces/BadRequestException';
import Response from '../../interfaces/Response';
import NotFoundException from '../../interfaces/NotFoundException';

interface GetOptions {
    useToken?: boolean;
    client?: boolean;
}

export default async function get(endpoint: string, options?: GetOptions) {
    options = { ...{ useToken: true, client: false }, ...options };

    let token;
    if (options.useToken) {
        if (options.client === true) {
            token = localStorage.getItem('jwt-client');
        } else {
            token = localStorage.getItem('jwt');
        }

        if (!token) {
            throw new Error('No user token');
        }
    }

    let response;
    try {
        response = await axios.get(
            `${process.env.REACT_APP_API as string}/${endpoint}`,
            options.useToken
                ? {
                      headers: { Authorization: `Bearer ${token}` },
                  }
                : {},
        );
    } catch (err) {
        if (err.response && err.response.data && err.response.status === 400) {
            return { success: false, ...err.response.data } as BadRequestException;
        }

        if (err.response && err.response.data && err.response.status === 401) {
            return { success: false, ...err.response.data };
        }

        if (err.response && err.response.data && err.response.status === 404) {
            return { success: false, ...err.response.data } as NotFoundException;
        }

        throw new Error(err.message);
    }

    return { success: true, ...response } as Response;
}
