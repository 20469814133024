import { Table } from 'antd';
import React, { PropsWithChildren, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Photoshoot, translatePhotoshootStatus } from '../../interfaces/Photoshoot';
import { getDaysAndTimeLeft } from '../../services/Date';
import { capitalize } from '../../services/Strings';

interface PhotoshootsListComponentProps {
    photoshoots: Photoshoot[];
    clients?: boolean;
    status?: boolean;
}

export default function PhotoshootsListComponent(props: PropsWithChildren<PhotoshootsListComponentProps>) {
    const history = useHistory();
    const [columns, setColumns]: [any, Function] = useState([]);

    let { clients, status } = props;

    if (typeof clients == 'undefined') {
        clients = true;
    }
    if (typeof status == 'undefined') {
        status = true;
    }

    useEffect(() => {
        let columns: any = [];

        let types: any = [];
        let statuses: any = [];
        for (let photoshoot of props.photoshoots) {
            if (types.findIndex((type: any) => type.value == photoshoot.type._id) === -1) {
                types = [...types, { text: photoshoot.type.name, value: photoshoot.type._id }];
            }

            if (statuses.findIndex((status: any) => status.value == photoshoot.status) === -1) {
                statuses = [
                    ...statuses,
                    {
                        text: capitalize(translatePhotoshootStatus(photoshoot.status) as String),
                        value: photoshoot.status,
                    },
                ];
            }
        }

        columns = [
            {
                title: 'Kiedy',
                dataIndex: 'datetime',
                render: (date: any) => {
                    return getDaysAndTimeLeft(date);
                },
            },
            {
                title: 'Rodzaj',
                dataIndex: 'type',
                render: (type: any) => {
                    return type.name;
                },
                filters: types,
                onFilter: (value: any, record: any) => record.type._id == value,
            },
        ];

        if (clients) {
            columns = [
                ...columns,
                {
                    title: 'Klient',
                    dataIndex: 'client',
                    render: (client: any) => (
                        <Link to={`/app/clients/${client._id}`}>{client.fullNameAlias || client.email}</Link>
                    ),
                },
            ];
        }

        if (status) {
            columns = [
                ...columns,
                {
                    title: 'Status',
                    dataIndex: 'status',
                    render: (status: any) => capitalize(translatePhotoshootStatus(status) as String),
                    filters: statuses,
                    onFilter: (value: any, record: any) => record.status == value,
                },
            ];
        }

        setColumns(columns);
    }, [props.photoshoots]);

    return (
        <Table
            rowClassName="cursor-pointer"
            onRow={(photoshoot: Photoshoot) => ({
                onClick: (e: any) => {
                    if (e.target.nodeName == 'A') {
                        return;
                    }

                    history.push(`/app/photoshoots/${photoshoot._id}`);
                },
            })}
            size="small"
            columns={columns}
            dataSource={props.photoshoots}
        ></Table>
    );
}
