import React from 'react';
import styled from 'styled-components';
import Colors from '../../../../../../../constants/Colors';
import Avatar from '../../../../../../../components/Avatar';
import Badge from '../../../../../../../components/Badge';
import LogoutButton from '../../../../../../../components/LogoutButton';
import { useGlobalState } from '../../../../../../../state';

const Container = styled.div`
    display: flex;
    align-items: center;
    margin-top: auto;
    background: ${Colors.greys[300]};
    width: 100%;
    padding: 1.5em;
    border-top: 0px 0px 5px 0px rgba(0, 0, 0, 0.08);
    font-family: 'Roboto';
`;

const Name = styled.span`
    font-family: 'Roboto';
    margin: 0 1em;
`;

export default function BottomBar() {
    const [user] = useGlobalState('user');

    return (
        <Container>
            {/* <Avatar /> */}
            <Name>
                {user.firstName} {user.lastName}
            </Name>
            {/* <Badge /> */}
            <div style={{ marginLeft: 'auto' }}>
                <LogoutButton />
            </div>
        </Container>
    );
}
