import moment, { Moment } from 'moment';
import { Day } from '../../interfaces/Calendar';

export function getNumberOfWeeks(year: any, month: any) {
    year = parseInt(year);
    month = parseInt(month);

    let date = new Date(year, month - 1, 1);
    let day = date.getDay();

    let numDaysInMonth = new Date(year, month, 0).getDate();

    return Math.ceil((numDaysInMonth + day) / 7);
}

export function getMondaysOfMonth(year: any, month: any) {
    let mondays: any = [];

    let monday = moment(`${year} ${month}`, 'YYYY MM').isoWeekday(8);
    if (monday.date() > 7) {
        monday.day(-6);
    }

    mondays = [...mondays, moment(monday)];

    while (monday.month() + 1 == parseInt(month)) {
        monday.add(7, 'days');

        if (monday.month() + 1 == parseInt(month)) {
            mondays = [...mondays, moment(monday)];
        }
    }

    return mondays;
}

export function getCurrentWeekOfMonth() {
    let mondays: Moment[] = getMondaysOfMonth(moment().format('YYYY'), moment().format('MM'));

    for (let monday of mondays) {
        let index = mondays.findIndex((m) => m == monday);
        let nextMonday = typeof mondays[index + 1] !== 'undefined' && mondays[index + 1];

        if (!nextMonday || moment().isBetween(monday, nextMonday)) {
            return index + 1;
        }
    }
}

export function getSlotsCountBetweenTimes(slotSize: number, timeFrom: string | Moment, timeTo: string | Moment) {
    if (typeof timeFrom === 'string') {
        timeFrom = moment(timeFrom, 'HH:mm');
    }

    if (typeof timeTo === 'string') {
        timeTo = moment(timeTo, 'HH:mm');
    }

    return Math.ceil(timeTo.diff(timeFrom, 'minutes') / slotSize);
}

export function getTimeLeft(slotSize: number, day: Day, time: Moment) {
    let timeLeft = 0;
    let canContinue = true;

    while (true) {
        time.add(slotSize, 'minutes');

        if (time.isAfter(moment(day.end, 'HH:mm'))) {
            break;
        }

        for (let limit of day.limits) {
            if (time.isBefore(moment(limit.end, 'HH:mm'))) {
                if (time.isAfter(moment(limit.start, 'HH:mm'))) {
                    canContinue = false;
                    break;
                }
            }
        }

        if (!canContinue) {
            break;
        }

        timeLeft += slotSize;
    }

    return timeLeft;
}
