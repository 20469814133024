import React, { useEffect, useRef, useState } from 'react';
import { useGlobalState, defaultState } from '../../../../../../state';
import styled from 'styled-components';
import moment from 'moment';
import Colors from '../../../../../../constants/Colors';
import TextareaAutosize from 'react-textarea-autosize';
import Message from '../../../../../../domain/App/App/components/Message';
import { FaImage, FaPaperPlane } from 'react-icons/fa';
import { get, post } from '../../../../../../services/Requests';
import { message as antdMessage } from 'antd';
import { useSocket } from 'use-socketio';
import color from 'color';

const Container = styled.div`
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: row;
`;

const MessagesContainer = styled.div`
    width: 100%;
`;

const Conversation = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
`;

const Header = styled.div`
    padding: 1em 2em;
    border-bottom: 2px solid ${Colors.greys[200]};
    font-size: 1.2em;
    font-weight: 600;
`;

const Messages = styled.div`
    height: 100%;
    overflow: auto;
    padding: 1em 2em;
    display: flex;
    flex-direction: column;
`;

const MessageBox = styled.div`
    border-top: 2px solid ${Colors.greys[200]};
    display: flex;
    flex-direction: row;
    padding: 0.5em 0.5em;
`;

const MessageInputContainer = styled.div`
    width: 100%;
    background: ${Colors.greys[200]};
    border-radius: 8px;
    padding: 0.2em 0.5em;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const MessageInput = styled(TextareaAutosize)<any>`
    border: none;
    outline: none;
    width: 100%;
    resize: none;
    background: none;
`;

const SendButton = styled.div<any>`
    margin-right: 1em;
`;

export default function Client_Messages() {
    const textareaRef = useRef(null);
    const [messages, setMessages] = useState([]) as any;
    const [portfolio] = useGlobalState('portfolio');
    const [disabled, setDisabled] = useState(false);

    const [appLoading, setAppLoading] = useGlobalState('appLoading');
    const [client] = useGlobalState('client');

    const [moreMessagesAvailable, setMoreMessagesAvailable] = useState(true);
    const [messagesLoading, setMessagesLoading] = useState(false);

    const [skip, setSkip] = useState(0);

    const chatRef = useRef(null);

    const [message, setMessage] = useState('');

    const { socket, subscribe, unsubscribe } = useSocket('message', (data) => {
        setMessages([...messages, data.message]);
        scrollToBottom();
    });

    const scrollToBottom = () => {
        if (chatRef != null && chatRef.current != null) {
            //@ts-ignore
            chatRef.current.scrollTop = chatRef.current.scrollHeight;
        }
    };

    useEffect(() => {
        (async () => {
            // Load messages
            await loadMessages();
            scrollToBottom();
        })();
    }, [client]);

    const loadMessages = async () => {
        if (typeof client.conversation !== 'undefined' && moreMessagesAvailable && !messagesLoading) {
            setMessagesLoading(true);
            setAppLoading(true);

            try {
                const response = await get(`conversations/${client.conversation}/messages?skip=${skip}&limit=${20}`, {
                    client: true,
                });

                if (!response.success) {
                    antdMessage.error(response.message);
                    return;
                }

                setMessages((messages: any) => [...response.data, ...messages]);
                setSkip((skip) => skip + 20);

                if (response.data.length < 20) {
                    setMoreMessagesAvailable(false);
                }

                setAppLoading(false);
                setMessagesLoading(false);
            } catch (err) {
                antdMessage.error(err.message);
                setMessagesLoading(false);
            }
        }
    };

    useEffect(() => {
        if (textareaRef.current !== null) {
            //@ts-ignore
            textareaRef.current.focus();
        }
    }, [textareaRef]);

    const keyPress = (event: any) => {
        if (event.key == 'Enter') {
            if (event.shiftKey) {
                return;
            }

            event.preventDefault();
            sendMessage();
        }
    };

    const sendMessage = async () => {
        let msg = message;
        setMessages([
            ...messages,
            {
                sender: 'client',
                message: msg,
                read: false,
                date: moment(),
            },
        ]);
        setMessage('');

        try {
            let response = await post(
                `conversations/message`,
                { message: msg, to: portfolio.slug, toType: 'slug' },
                { client: true },
            );

            if (!response.success) {
                setDisabled(true);
                console.log(response.message);

                // antdMessage.error(response.message);
            }
        } catch (err) {
            antdMessage.error(err.message);
        }

        scrollToBottom();
    };

    const handleScroll = async (e: any) => {
        const element = e.target;

        if (element.scrollTop < 1) {
            // Load more messages
            //@ts-ignore
            const height = chatRef.current.scrollHeight;

            await loadMessages();

            //@ts-ignore
            chatRef.current.scrollTop = chatRef.current.scrollHeight - height;
        }
    };

    return (
        <Container>
            <MessagesContainer>
                <Conversation>
                    <Header>Wiadomości</Header>
                    <Messages onScroll={handleScroll} ref={chatRef}>
                        {messages.map((message: any, index: any) => (
                            <Message
                                client={true}
                                background={color(portfolio.template.colors.primary).lighten(0.15).hex()}
                                key={`message-${index}`}
                                message={message}
                                previousMessage={messages[index - 1] && messages[index - 1]}
                            />
                        ))}
                    </Messages>
                    <MessageBox>
                        <MessageInputContainer>
                            <MessageInput
                                disabled={disabled}
                                onChange={(e: any) => {
                                    setMessage(e.target.value);
                                }}
                                value={message}
                                onKeyPress={keyPress}
                                maxRows={8}
                                ref={textareaRef}
                                placeholder="Napisz wiadomość.."
                            ></MessageInput>
                            <SendButton onClick={sendMessage}>
                                <FaPaperPlane></FaPaperPlane>
                            </SendButton>
                        </MessageInputContainer>
                    </MessageBox>
                </Conversation>
            </MessagesContainer>
        </Container>
    );
}
