import React from 'react';
import styled from 'styled-components';
import Colors from '../../../../../../../constants/Colors';
import { FaSearch } from 'react-icons/fa';
import { useGlobalState } from '../../../../../../../state';

const Container = styled.div`
    margin-bottom: 5em;
    width: 100%;
`;

const InputWrapper = styled.div`
    width: 100%;
    background: ${Colors.greys[500]};
    padding: 0.5em 1em;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const Input = styled.input`
    border: 0;
    width: 100%;
    background: transparent;
    font-family: 'Roboto';

    &:focus {
        outline: none;
    }
`;

const Icon = styled.div`
    margin-right: 1em;
    display: flex;
    align-items: center;
`;

export default function SearchBar() {
    const [searchBarVisible] = useGlobalState('searchBarVisible');

    if (!searchBarVisible) return <div />;

    return <div style={{ marginTop: '2em' }} />;

    return (
        <Container>
            <InputWrapper>
                <Icon>
                    <FaSearch />
                </Icon>
                <Input type="text" placeholder="Szukaj" />
            </InputWrapper>
        </Container>
    );
}
