import React, { PropsWithChildren } from 'react';
import ImageColor from '../../resources/images/logo-blue_transparent.png';
import styled, { CSSProperties } from 'styled-components';
import { SizeType } from 'antd/lib/config-provider/SizeContext';

interface LogoProps {
    size?: SizeType | number;
    containerStyle?: CSSProperties;
    imageStyle?: CSSProperties;
}

const Container = styled.div<{ width?: number }>`
    padding: 1em;
    width: ${(props) => props.width}px;
`;

export default function Logo(props: PropsWithChildren<LogoProps>) {
    let width;
    if (typeof props.size != 'undefined') {
        switch (props.size) {
            case 'large':
                width = 300;
                break;
            case 'middle':
                width = 200;
                break;
            case 'small':
                width = 100;
                break;
            default:
                width = props.size as number;
        }
    } else {
        width = 100;
    }

    return (
        <Container width={width} style={props.containerStyle}>
            <img
                alt="Logo aplikacji Captic"
                style={{ width: '100%', height: 'auto', ...props.imageStyle }}
                src={ImageColor}
            />
        </Container>
    );
}
