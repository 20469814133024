export default {
    min: {
        phone: 576,
        tablet: 768,
        desktops: 992,
        largeDesktops: 1200,
    },
    max: {
        phone: 575.98,
        tablet: 767.98,
        desktops: 991.98,
        largeDesktops: 1199.98,
    },
};
