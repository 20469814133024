import React, { useEffect } from 'react';
import LeftPanel from '../../../domain/App/App/components/ui/LeftPanel';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import MainPanel from '../../../domain/App/App/components/ui/MainPanel';
import AppPortfolio from './Portfolio';
import AppMessages from './Messages';
import AppCalendar from './Calendar';
import AppPhotoshoots from './Photoshoots';
import { useGlobalState } from '../../../state';
import { useSocket } from 'use-socketio';
import { useDispatch } from 'react-redux';
import AppClients from './Clients';
import AppDashboard from './Dashboard';

const Container = styled.div`
    height: 100%;
    display: flex;
    flex-direction: row;
`;

export default function AppView() {
    const match = useRouteMatch();
    const [photoshootsAwaitingConfirmation, setPhotoshootsAwaitingConfirmation] = useGlobalState(
        'photoshootsAwaitingConfirmation',
    );
    const dispatch = useDispatch();

    useSocket('newPhotoshootThatAwaitsConfirmation', (data) => {
        setPhotoshootsAwaitingConfirmation((count) => count + 1);
    });

    useSocket('newUnreadConversation', (data) => {
        dispatch({ type: 'ADD_UNREAD_CONVERSATION', payload: data });
    });

    useSocket('conversationRead', (data) => {
        dispatch({ type: 'REMOVE_UNREAD_CONVERSATION', payload: data });
    });

    useSocket('message', (data: any) => {
        dispatch({ type: 'UPDATE_LAST_MESSAGE', payload: data });
    });

    return (
        <Container>
            <LeftPanel />
            <MainPanel>
                <Switch>
                    <Route path={`${match.url}/portfolio`}>
                        <AppPortfolio />
                    </Route>
                    <Route path={`${match.url}/messages`}>
                        <AppMessages />
                    </Route>
                    <Route path={`${match.url}/calendar`}>
                        <AppCalendar />
                    </Route>
                    <Route path={`${match.url}/photoshoots`}>
                        <AppPhotoshoots />
                    </Route>
                    <Route path={`${match.url}/clients`}>
                        <AppClients />
                    </Route>
                    <Route path={`${match.url}/`}>
                        <AppDashboard />
                    </Route>
                </Switch>
            </MainPanel>
        </Container>
    );
}
