import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import Logo from '../../../../../components/Logo';
import confirm from 'antd/lib/modal/confirm';
import Breakpoints from '../../../../../constants/Breakpoints';
import Colors from '../../../../../constants/Colors';
import Photographer_Logo from '../../../../../domain/Photographer/Portfolio/components/Logo';
import Client_Navigation from './Navigation';

const LogoContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 2em 0;
`;

const Container = styled.div`
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: row;
`;

const LeftPanel = styled.div`
    height: 100%;
    background: red;
    width: 320px;
    min-width: 320px;
    background: ${Colors.greys[150]};
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3em 1em;

    @media (max-width: ${Breakpoints.max.tablet}px) {
        left: -320px;
    }
`;

const RightPanel = styled.div`
    height: 100%;
    overflow: scroll;
    padding: 2em 3em;
    width: 100%;
`;

const Title = styled.p`
    font-weight: 500;
    font-size: 0.8em;
    color: ${Colors.greys[700]};
    letter-spacing: 2px;
`;

export default function ClientLayout(props: PropsWithChildren<any>) {
    const logout = (e: any) => {
        confirm({
            title: 'Czy jesteś pewny/a, że chcesz się wylogować?',
            cancelText: 'Anuluj',
            okType: 'danger',
            okText: 'Wyloguj',
            onOk: () => {
                localStorage.removeItem('jwt-client');
                window.location.reload();
            },
        });
    };

    return (
        <Container>
            <LeftPanel>
                <Photographer_Logo />
                <Title>PANEL KLIENTA</Title>
                <Client_Navigation />
            </LeftPanel>
            <RightPanel>{props.children}</RightPanel>
        </Container>
    );
}
