import React, { PropsWithChildren, useState } from 'react';
import styled from 'styled-components';
import { useGlobalState } from '../../../../../state';

const InputContainer = styled.div<any>`
    border: 2px solid rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    padding: 4px 8px;
    height: 36px;
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 1em;
    transition: 0.3s all;

    border-color: ${(props) => (props.focused ? props.theme.primary : 'rgba(0, 0, 0, 0.2)')};
`;

const CustomInput = styled.input<any>`
    border: 0;
    outline: none;
    width: 100%;
`;

const Label = styled.label<any>`
    /* font-weight: 500; */
    color: rgba(0, 0, 0, 0.6);
`;

interface SimpleTheme_InputComponentProps {
    label?: string;
    name: string;
    onChange?: Function;
    type?: string;
    placeholder?: string;
}

export default function SimpleTheme_InputComponent(props: PropsWithChildren<SimpleTheme_InputComponentProps>) {
    const [portfolio] = useGlobalState('portfolio');
    const [focused, setFocused] = useState(false);

    return (
        <div>
            {props.label && <Label htmlFor={props.name}>{props.label}</Label>}
            <InputContainer focused={focused}>
                <CustomInput
                    placeholder={props.placeholder}
                    onBlur={() => setFocused(false)}
                    onFocus={() => setFocused(true)}
                    id={props.name}
                    name={props.name}
                    type={props.type && props.type}
                    onChange={(e: any) => props.onChange && props.onChange(e.target.value)}
                ></CustomInput>
            </InputContainer>
        </div>
    );
}
