import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Input, Checkbox, message } from 'antd';
import styled from 'styled-components';
import Label from '../../../../../components/Label';
import Breakpoints from '../../../../../constants/Breakpoints';
import { useGlobalState } from '../../../../../state';
import { get } from '../../../../../services/Requests';
import Response from '../../../../../interfaces/Response';
import { Store } from 'antd/lib/form/interface';
import put from '../../../../../services/Requests/put';
import validationHandler from '../../../../../services/ValidationHandler';

const InputContainer = styled.div`
    max-width: ${Breakpoints.max.tablet}px;
`;

const { useForm } = Form;

export default function PortfolioMain() {
    const [form] = useForm();
    const [loading, setLoading] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [success, setSuccess] = useState('');

    const [appLoading, setAppLoading] = useGlobalState('appLoading');
    const [photoshootTypes, setPhotoshootTypes] = useState([]);
    const [themes, setThemes] = useState([]);

    useEffect(() => {
        // Load available themes
        (async () => {
            try {
                setAppLoading(true);
                let response = await get('themes');

                if (response.success) {
                    response = response as Response;

                    setThemes(response.data);
                    setAppLoading(false);
                }
            } catch (err) {
                // TODO: handle error
            }
        })();

        // Load portfolio data
        (async () => {
            try {
                setAppLoading(true);
                let response = await get('portfolio');

                if (response.success) {
                    response = response as Response;

                    form.setFieldsValue({
                        slug: response.data.slug,
                        title: response.data.title,
                        description: response.data.description,
                    });

                    setAppLoading(false);
                }
            } catch (err) {
                // TODO: handle error
            }
        })();
    }, []);

    const formSubmit = async (values: Store) => {
        setLoading(true);
        setDisabled(true);

        try {
            let response = await put('portfolio', values);

            if (!response.success) {
                let errorMessage = validationHandler(response, form);
                // TODO: HANDLE ERROR

                setDisabled(false);
            } else {
                response = response as Response;

                message.success('Pomyślnie zaktualizowano portfolio.');
            }

            setLoading(false);
            setDisabled(false);
        } catch (err) {
            // TODO: HANDLE ERROR
        }
    };

    return (
        <Form id="portfolio-main" form={form} onFinish={formSubmit}>
            <InputContainer>
                <Row gutter={[0, 12]}>
                    <Col span={24}>
                        <Label
                            description={
                                <span>
                                    To uproszczona nazwa portfolio, umieszczana w odnośniku. Przykład: http://
                                    <strong>uproszczona-nazwa</strong>.captic.pl"
                                </span>
                            }
                        >
                            Uproszczona nazwa
                        </Label>
                        <Form.Item
                            name="slug"
                            help={null}
                            rules={[
                                {
                                    required: true,
                                    message: 'Musisz wprowadzić uproszczoną nazwę',
                                },
                                {
                                    pattern: /^[a-zA-Z0-9-]+$/,
                                    message: 'Może składać się tylko ze znaków alfanumerycznych oraz myślnika',
                                },
                            ]}
                        >
                            <Input disabled={disabled} placeholder="Twoja nazwa" />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Label>Tytuł strony</Label>
                        <Form.Item
                            name="title"
                            help={null}
                            rules={[
                                {
                                    required: true,
                                    message: 'Musisz wprowadzić nazwę strony',
                                },
                            ]}
                        >
                            <Input disabled={disabled} placeholder="Twoja nazwa" />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Label>Opis strony</Label>
                        <Form.Item name="description" help={null} rules={[]}>
                            <Input.TextArea rows={4} disabled={disabled} placeholder="" />
                        </Form.Item>
                    </Col>
                </Row>
            </InputContainer>
        </Form>
    );
}
