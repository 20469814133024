const initState = [] as any;

const unreadConversationsReducer = (state = initState, action: any) => {
    switch (action.type) {
        case 'ADD_UNREAD_CONVERSATION':
            if (!state.includes(action.payload)) {
                return [...state, action.payload];
            }
            return state;
        case 'REMOVE_UNREAD_CONVERSATION':
            return state.filter((c: any) => c != action.payload);
        case 'SET_UNREAD_CONVERSATIONS':
            return action.payload.map((el: any) => el._id);
        default:
            return state;
    }
};

export default unreadConversationsReducer;
