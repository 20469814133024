import unreadConversationsReducer from './unreadConversationsReducer';
import { combineReducers } from 'redux';
import lastMessageReducer from './lastMessageReducer';

const rootReducer = combineReducers({
    unreadConversations: unreadConversationsReducer,
    lastMessage: lastMessageReducer,
});

export default rootReducer;
