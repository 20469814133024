import React from 'react';
import styled from 'styled-components';
import { FaSignOutAlt } from 'react-icons/fa';
import Colors from '../../constants/Colors';
import { useGlobalState } from '../../state';
import User from '../../interfaces/User';

const Container = styled.div`
    width: 32px;
    display: flex;
    align-items: center;
    cursor: pointer;

    &:hover {
        color: ${Colors.primary[500]};
    }
`;

export default function LogoutButton() {
    const [user, setUser] = useGlobalState('user'); // eslint-disable-line @typescript-eslint/no-unused-vars

    const logout = () => {
        setUser({} as User);
        localStorage.removeItem('jwt');

        window.location.replace('/app/login');
    };

    return (
        <div onClick={logout}>
            <Container>
                <FaSignOutAlt />
            </Container>
        </div>
    );
}
