import React from 'react';
import styled from 'styled-components';
import SimpleTheme_Navbar from './components/Navbar';
import { useGlobalState } from '../../../../state';
import { BrowserRouter as Router, Switch, Route, useRouteMatch } from 'react-router-dom';
import SimpleTheme_PortfolioView from './views/Portfolio';
import SimpleTheme_ContactView from './views/Contact';
import SimpleTheme_Calendar from './views/Calendar';
import Breakpoints from '../../../../constants/Breakpoints';

const Container = styled.div<any>`
    background: white;
    padding: 4em 5em;
    height: 100%;
    background-color: ${(props) => props.theme.background} !important;

    @media (max-width: ${Breakpoints.max.tablet}px) {
        padding: 2em 0.8em;
    }
`;

const Content = styled.div`
    padding: 3em 0;
`;

export default function SimpleTheme() {
    const [portfolio] = useGlobalState('portfolio');
    const match = useRouteMatch();

    return (
        <Container>
            <SimpleTheme_Navbar />

            <Content>
                <Switch>
                    <Route exact path={`${match.url}/`}>
                        <SimpleTheme_PortfolioView></SimpleTheme_PortfolioView>
                    </Route>
                    <Route path={`${match.url}/contact`}>
                        <SimpleTheme_ContactView></SimpleTheme_ContactView>
                    </Route>
                    <Route path={`${match.url}/calendar`}>
                        <SimpleTheme_Calendar />
                    </Route>
                </Switch>
            </Content>
        </Container>
    );
}
