import React, { useEffect, useRef, useState } from 'react';
import Heading from '../../../../../components/Heading';
import styled from 'styled-components';
import { Button, message, Select } from 'antd';
import moment, { Moment } from 'moment';
import { getCurrentWeekOfMonth, getMondaysOfMonth } from '../../../../../services/Weeks';
import AvailabilitySetupCalendar from '../../../../../domain/App/App/components/Calendar/AvailabilitySetupCalendar';
import { useGlobalState } from '../../../../../state';
import confirm from 'antd/lib/modal/confirm';
import { get, post, put } from '../../../../../services/Requests';
import Response from '../../../../../interfaces/Response';
import BadRequestException from '../../../../../interfaces/BadRequestException';

const { Option } = Select;

const Container = styled.div`
    margin-top: -1em;
`;

const WeekSetupContainer = styled.div`
    margin-top: 2em;
`;

const SelectWeekContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 2em;
`;

const SelectWeekManipulationButtons = styled.div`
    display: flex;
    flex-direction: row;
    margin-right: 1.5em;
    flex-wrap: wrap;

    & > * {
        margin-right: 0.5em;
        margin-bottom: 0.5em;
    }
`;

const SelectWeekSelects = styled.div`
    display: flex;
    flex-direction: row;
    margin-right: 1.5em;
    flex-wrap: wrap;
    margin-bottom: 0.7em;

    & > * {
        margin-right: 0.5em;
        margin-bottom: 0.5em;
    }
`;

const ActionButtons = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    flex: 1;

    & > * {
        margin-right: 0.5em;
        margin-bottom: 0.5em;
    }
`;

export default function CalendarAvailabilityView() {
    const [appLoading, setAppLoading] = useGlobalState('appLoading');

    const [week, setWeek]: [any, Function] = useState(null);
    const [weekLoading, setWeekLoading] = useState(false);

    const [selectedYear, setSelectedYear] = useState(moment().format('YYYY'));
    const [selectedMonth, setSelectedMonth] = useState(moment().format('MM'));
    const [selectedWeek, setSelectedWeek] = useState(getCurrentWeekOfMonth());
    const [selectedDate, setSelectedDate]: [Moment | undefined, Function] = useState();

    const [availableWeeks, setAvailableWeeks]: [Moment[], Function] = useState([]);
    const setupCalendarRef = useRef(null);

    const availableYears = [
        moment().format('YYYY'),
        moment().add(1, 'years').format('YYYY'),
        moment().add(2, 'years').format('YYYY'),
    ];

    const availableMonths = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

    const handleAddWeek = async () => {
        confirm({
            title: 'Czy jesteś pewny/a, że chcesz utworzyć tydzień?',
            content: 'Utworzony tydzień będzie oparty o domyślny szablon.',
            cancelText: 'Anuluj',
            okText: 'Utwórz',
            onOk: async () => {
                setAppLoading(true);

                try {
                    const response: Response = await post(`calendar/week`, {
                        year: selectedYear,
                        month: selectedMonth,
                        week: selectedWeek,
                    });

                    if (!response.success) {
                        message.error(`Nie udało się utworzyć tygodnia: ${(response as BadRequestException).message}`);
                    } else {
                        setWeek(response.data);
                    }

                    setAppLoading(false);
                } catch (err) {
                    /// TODO: handle err...
                }
            },
        });
    };

    const handleWeekAndYearChange = () => {
        let mondays = getMondaysOfMonth(selectedYear, selectedMonth);
        setAvailableWeeks(mondays);

        if (selectedYear === moment().format('YYYY') && selectedMonth === moment().format('MM')) {
            setSelectedWeek(getCurrentWeekOfMonth());
        } else {
            setSelectedWeek(1);
        }
    };

    useEffect(() => {
        let currentWeek;
        if (availableWeeks.length > 0 && typeof selectedWeek != 'undefined') {
            if (typeof availableWeeks[selectedWeek - 1] !== 'undefined') {
                currentWeek = availableWeeks[selectedWeek - 1];
            }
        }

        setSelectedDate(currentWeek);
    }, [selectedWeek, availableWeeks]);

    useEffect(() => {
        handleWeekAndYearChange();
    }, []);

    useEffect(() => {
        setAppLoading(true);

        // Get week
        (async () => {
            setWeekLoading(true);

            try {
                const response = await get(
                    `calendar/week?year=${selectedYear}&month=${selectedMonth}&week=${selectedWeek}`,
                );

                if (response.success) {
                    setWeek(response.data);
                } else {
                    setWeek(null);
                }
            } catch (err) {
                /// TODO: handle error
            }

            setWeekLoading(false);
            setAppLoading(false);
        })();
    }, [selectedYear, selectedMonth, selectedWeek]);

    const handleSave = async () => {
        if (setupCalendarRef !== null && setupCalendarRef.current !== null) {
            setAppLoading(true);
            //@ts-ignore
            let days = setupCalendarRef.current.getDays();

            try {
                //@ts-ignore
                let response = await put(`calendar/week`, { week: { ...week, days } });
                if (response.success) {
                    message.success('Pomyślnie zaktualizowano tydzień.');
                } else {
                    message.error(`Nie udało się zaktualizować tygodnia: ${(response as BadRequestException).message}`);
                }

                setAppLoading(false);
            } catch (err) {
                /// todo: handle error..
            }
        }
    };

    const handleSetDefault = async () => {
        setAppLoading(true);

        try {
            //@ts-ignore
            let response = await post(`calendar/changeDefaultWeek`, { week: week._id });
            if (response.success) {
                setWeek(response.data);
                message.success('Pomyślnie zaktualizowano tydzień.');
            } else {
                message.error(`Nie udało się zaktualizować tygodnia: ${(response as BadRequestException).message}`);
            }

            setAppLoading(false);
        } catch (err) {
            /// todo: handle error..
        }
    };

    const handleChangeToday = () => {
        const currentWeek = getCurrentWeekOfMonth();
        setSelectedYear(moment().format('YYYY'));
        setSelectedMonth(parseInt(moment().format('MM')).toString());
        setSelectedWeek(currentWeek);
    };

    const handleChangePreviousWeek = () => {
        let mondays = getMondaysOfMonth(selectedYear, selectedMonth);
        let currentWeek = mondays[(selectedWeek as number) - 1];

        currentWeek.subtract(1, 'week');

        setSelectedYear(currentWeek.format('YYYY'));
        //@ts-ignore
        setSelectedMonth(parseInt(currentWeek.format('MM')).toString());

        let newMondays = getMondaysOfMonth(currentWeek.format('YYYY'), currentWeek.format('MM'));
        if ((selectedWeek as number) > 1) {
            setSelectedWeek((selectedWeek as number) - 1);
        } else {
            setSelectedWeek(newMondays.length);
        }
    };

    const handleChangeNextWeek = () => {
        let mondays = getMondaysOfMonth(selectedYear, selectedMonth);
        let currentWeek = mondays[(selectedWeek as number) - 1];

        currentWeek.add(1, 'week');

        setSelectedYear(currentWeek.format('YYYY'));
        //@ts-ignore
        setSelectedMonth(parseInt(currentWeek.format('MM')).toString());

        let newMondays = getMondaysOfMonth(currentWeek.format('YYYY'), currentWeek.format('MM'));
        if ((selectedWeek as number) < mondays.length) {
            setSelectedWeek((selectedWeek as number) + 1);
        } else {
            setSelectedWeek(1);
        }
    };

    const WeekSetup = () => {
        if (weekLoading) {
            return <div />;
        }

        if (week === null) {
            return (
                <WeekSetupContainer>
                    <Button onClick={handleAddWeek}>Utwórz tydzień</Button>
                </WeekSetupContainer>
            );
        }

        return <AvailabilitySetupCalendar ref={setupCalendarRef} week={week} />;
    };

    return (
        <Container>
            <Heading size="small" weight="normal">
                Ustawianie dostępności
            </Heading>

            <SelectWeekContainer>
                <SelectWeekSelects>
                    <Select
                        value={selectedYear}
                        onChange={(year: any) => {
                            setSelectedYear(year);
                            handleWeekAndYearChange();
                        }}
                    >
                        {availableYears.map((year, index) => (
                            <Option key={`select-year-option-${index}`} value={year}>
                                {year}
                            </Option>
                        ))}
                    </Select>
                    <Select
                        value={selectedMonth}
                        style={{ width: '120px' }}
                        onChange={(month: any) => {
                            setSelectedMonth(month);
                            handleWeekAndYearChange();
                        }}
                    >
                        {availableMonths.map((month, index) => (
                            <Option key={`select-month-option-${index}`} value={month.toString()}>
                                {moment()
                                    .month(month - 1)
                                    .format('MMMM')}
                            </Option>
                        ))}
                    </Select>
                    <Select
                        value={selectedWeek}
                        style={{ width: '100px' }}
                        onChange={(week: any) => setSelectedWeek(week)}
                    >
                        {availableWeeks.map((monday, index) => (
                            <Option key={`select-week-option-${index}`} value={index + 1}>
                                tydzień {index + 1}
                            </Option>
                        ))}
                    </Select>
                </SelectWeekSelects>

                <SelectWeekManipulationButtons>
                    <Button onClick={handleChangeToday}>Dzisiaj</Button>
                    <Button onClick={handleChangePreviousWeek}>Poprzedni tydzień</Button>
                    <Button onClick={handleChangeNextWeek}>Następny tydzień</Button>
                </SelectWeekManipulationButtons>

                <ActionButtons>
                    {week && week.default === false && <Button onClick={handleSetDefault}>Ustaw jako domyślny</Button>}
                    <Button type="primary" onClick={handleSave}>
                        Zapisz
                    </Button>
                </ActionButtons>
            </SelectWeekContainer>

            <WeekSetup />
        </Container>
    );
}
