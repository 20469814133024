import React, { PropsWithChildren, useEffect, useReducer, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Photoshoot, PhotoshootPhoto } from '../../../../../interfaces/Photoshoot';
import { Upload, message, Form, Row, Col, Progress, Button, Modal, Space, Popconfirm, Table } from 'antd';
import { InboxOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import Colors from '../../../../../constants/Colors';
import { useGlobalState } from '../../../../../state';
import deleteRequest from '../../../../../services/Requests/delete';
import validationHandler from '../../../../../services/ValidationHandler';
import { FaCheckCircle } from 'react-icons/fa';
import { post } from '../../../../../services/Requests';

interface PhotoshootsPhotoshootAddPhotosProps {
    photoshoot: Photoshoot;
}

const Images = styled.div`
    margin-top: 2em;
`;

const MiniThumb = styled.img`
    max-width: 30px;
    max-height: 30px;
`;

const PreviewContainer = styled.div`
    display: flex;
    padding: 8px;
    background: ${Colors.greys[200]};
    border-radius: 6px;
    align-items: center;
    flex-direction: column;
`;

const PreviewImage = styled.img`
    height: 220px;
    max-width: 100%;
    object-fit: contain;
    cursor: pointer;
`;

const PreviewButtons = styled.div`
    margin-top: 1em;
`;

const { Dragger } = Upload;

const initialState: any = [];
const fileListActionTypes = {
    updateFile: 'UPDATE_FILE',
    setInitialFiles: 'SET_INITIAL_FILES',
    deleteFile: 'DELETE_FILE',
    deleteFiles: 'DELETE_FILES',
};

function fileListReducer(prevState = initialState, action: any) {
    switch (action.type) {
        case fileListActionTypes.updateFile:
            action.payload.key = action.payload.uid;

            return [
                action.payload,
                ...prevState.filter((f: any) => f.uid !== action.payload.uid && f.uid !== action.payload.oldUid),
            ];
        case fileListActionTypes.setInitialFiles:
            action.payload = action.payload.map((el: any) => ({ ...el, key: el.uid }));
            return [...action.payload];
        case fileListActionTypes.deleteFile:
            return [...prevState.filter((f: any) => f.uid !== action.payload.uid)];
        case fileListActionTypes.deleteFiles:
            return [...prevState.filter((f: any) => !action.payload.includes(f.uid))];
        default:
            return prevState;
    }
}

export default function PhotoshootsPhotoshootAddPhotos(props: PropsWithChildren<PhotoshootsPhotoshootAddPhotosProps>) {
    const history = useHistory();
    const { photoshoot } = props;

    const [appLoading, setAppLoading] = useGlobalState('appLoading');
    const [fullScreenImage, setFullScreenImage] = useGlobalState('fullScreenImage');
    const [preview, setPreview]: [any, Function] = useState(null);
    const [selectedRows, setSelectedRows]: [any, Function] = useState([]);

    const openFullScreen = (src: any) => {
        setFullScreenImage(src);
    };

    const [fileList, dispatch] = useReducer(fileListReducer, initialState);

    useEffect(() => {
        dispatch({
            type: fileListActionTypes.setInitialFiles,
            payload: props.photoshoot.photos.map((photo: any) => {
                return {
                    status: 'saved',
                    name: photo.originalName,
                    uid: photo._id,
                    thumbnail: photo.thumbnail,
                    width: photo.width,
                    height: photo.height,
                };
            }),
        });
    }, [props.photoshoot]);

    let token = localStorage.getItem('jwt');
    const opts = {
        name: 'file',
        accept: '.jpg,.JPG,.jpeg,.JPEG,.png,.PNG',
        showUploadList: false,
        beforeUpload,
        headers: {
            Authorization: `Bearer ${token}`,
        },
        multiple: true,
        action: `${process.env.REACT_APP_API as string}/photoshoots/${photoshoot._id}/photos`,
        onChange(info: any) {
            let files = info.fileList
                .map((file: any) => {
                    if (typeof file.status === 'undefined') {
                        return null;
                    }

                    return {
                        ...file,
                    };
                })
                .filter(Boolean);

            if (info.file.status === 'done') {
                info.file.oldUid = info.file.uid;
                info.file.uid = info.file.response._id;
            }

            dispatch({ type: fileListActionTypes.updateFile, payload: info.file });
        },
    };

    function beforeUpload(file: File) {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('Możesz dodać tylko pliki jpg, jpeg oraz png!');
        }

        return isJpgOrPng;
    }

    const deletePhoto = async (file: any) => {
        setAppLoading(true);
        try {
            let response = await deleteRequest(`photoshoots/photos/${file.uid}`);

            if (!response.success) {
                message.error(response.message);
                // TODO: HANDLE ERROR

                setAppLoading(false);
            } else {
                response = response as Response;
                message.success('Pomyślnie usunięto zdjęcie.');

                dispatch({ type: fileListActionTypes.deleteFile, payload: file });
            }

            setAppLoading(false);

            if (file.uid == preview.uid) {
                setPreview(null);
            }
        } catch (err) {
            // TODO: HANDLE ERROR
        }
    };

    const handleBulkDelete = async () => {
        setAppLoading(true);
        try {
            let response = await post(`photoshoots/photos/bulkDelete`, { photos: selectedRows });

            if (!response.success) {
                message.error(response.message);
                setAppLoading(false);
            } else {
                response = response as Response;
                message.success('Pomyślnie usunięto zdjęcia.');

                dispatch({ type: fileListActionTypes.deleteFiles, payload: selectedRows });

                if (preview && selectedRows.includes(preview.uid)) {
                    setPreview(null);
                }

                setSelectedRows([]);
            }

            setAppLoading(false);
        } catch (err) {
            console.log(err);

            message.error('Wystąpił błąd podczas usuwania zdjęć. Spróbuj ponownie później.');
            setAppLoading(false);
        }
    };

    const columns = [
        {
            title: '',
            dataIndex: 'uid',
            render: (data: any, file: any) => {
                let photo =
                    file.status === 'saved' || file.status === 'done'
                        ? file.thumbnail
                            ? `${process.env.REACT_APP_API as string}/photoshoots/photos/${file.thumbnail._id}`
                            : `${process.env.REACT_APP_API as string}/photoshoots/photos/${file.uid}`
                        : '';

                return <MiniThumb id={`thumb-${file.uid}`} src={photo} />;
            },
        },
        {
            title: 'Nazwa pliku',
            dataIndex: 'name',
        },
        {
            title: 'Wymiary',
            render: (data: any, record: any) => {
                if (record.width && record.height) {
                    return `${record.width}x${record.height}`;
                }

                return '-';
            },
        },
        {
            title: 'Postęp',
            dataIndex: 'percent',
            render: (data: any, record: any) => {
                if (record.status == 'saved' || record.status == 'done') {
                    return <FaCheckCircle style={{ color: 'green' }} />;
                }

                let percent = record.percent;
                let status;

                if (record.status == 'error') {
                    status = 'exception';
                } else {
                    status = 'active';
                }

                //@ts-ignore
                return <Progress showInfo={false} percent={percent} status={status}></Progress>;
            },
        },
    ];

    const Preview = () => {
        if (!preview) return <div />;

        return (
            <PreviewContainer>
                <PreviewImage
                    onClick={() =>
                        setFullScreenImage(`${process.env.REACT_APP_API as string}/photoshoots/photos/${preview.uid}`)
                    }
                    src={
                        preview.thumbnail
                            ? `${process.env.REACT_APP_API as string}/photoshoots/photos/${preview.thumbnail._id}`
                            : `${process.env.REACT_APP_API as string}/photoshoots/photos/${preview.uid}`
                    }
                ></PreviewImage>
                <PreviewButtons>
                    <Space>
                        <Popconfirm
                            okText="Usuń"
                            cancelText="Anuluj"
                            title="Czy jesteś pewny/a, że chcesz usunąć zdjęcie?"
                            onConfirm={() => deletePhoto(preview)}
                        >
                            <Button size="small" danger>
                                Usuń
                            </Button>
                        </Popconfirm>
                    </Space>
                </PreviewButtons>
            </PreviewContainer>
        );
    };

    return (
        <div>
            <Space>
                <Button onClick={() => history.push(`/app/photoshoots/${photoshoot._id}`)}>Wróć</Button>
                <Popconfirm
                    okText="Usuń"
                    cancelText="Anuluj"
                    title="Czy jesteś pewny/a, że chcesz usunąć zaznaczone zdjęcia? Operacja ta jest nieodwracalna."
                    onConfirm={handleBulkDelete}
                >
                    <Button disabled={selectedRows.length == 0} danger>
                        Usuń zaznaczone
                    </Button>
                </Popconfirm>
            </Space>
            <div style={{ marginTop: '2em' }}>
                <Dragger {...opts}>
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">Kliknij albo upuść pliki</p>
                    <p className="ant-upload-hint">Dostępne formaty: *.jpg, *.jpeg, *.png</p>
                </Dragger>
                <Images>
                    <Row gutter={[12, 12]}>
                        <Col
                            xs={{ span: 24, order: 2 }}
                            sm={{ span: 24, order: 2 }}
                            md={{ span: 24, order: 2 }}
                            lg={{ span: preview ? 16 : 24, order: 1 }}
                        >
                            <Table
                                rowClassName={(row: any) => {
                                    if (row.status == 'saved' || row.status == 'done') {
                                        return `cursor-pointer`;
                                    }

                                    return ``;
                                }}
                                onRow={(row, rowIndex) => {
                                    return {
                                        onClick: (e: any) => {
                                            if (row.status == 'saved' || row.status == 'done') {
                                                setPreview(row);
                                            }
                                        },
                                    };
                                }}
                                dataSource={fileList}
                                rowSelection={{
                                    type: 'checkbox',
                                    onChange: (data) => {
                                        setSelectedRows(data);
                                    },
                                    getCheckboxProps: (record) => {
                                        return {
                                            disabled: record.status !== 'saved' && record.status !== 'done',
                                            name: 'selected_photos',
                                            value: record.uid,
                                        };
                                    },
                                }}
                                size="small"
                                columns={columns}
                            />
                        </Col>
                        <Col
                            xs={{ span: 24, order: 1 }}
                            sm={{ span: 24, order: 1 }}
                            md={{ span: 24, order: 1 }}
                            lg={{ span: preview ? 8 : 24, order: 2 }}
                        >
                            <Preview />
                        </Col>
                    </Row>
                </Images>
            </div>
        </div>
    );
}
