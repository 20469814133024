import React, { useEffect, useRef, useState } from 'react';
import Heading from '../../../../../components/Heading';
import styled from 'styled-components';
import { useGlobalState } from '../../../../../state';
import { Tabs, Button } from 'antd';
import CalendarConfigurationMain from './MainTab';

const Container = styled.div`
    margin-top: -1em;
`;

const Content = styled.div`
    margin-top: 2em;
`;

export default function CalendarConfigurationView() {
    const { TabPane } = Tabs;
    const [activeTab, setActiveTab]: [string, Function] = useState('calendar-configuration-main');

    const [appLoading, setAppLoading] = useGlobalState('appLoading');
    const [user] = useGlobalState('user');

    const onTabChange = (activeTab: string) => {
        setActiveTab(activeTab);
    };

    return (
        <Container>
            <Heading size="small" weight="normal">
                Ustawienia kalendarza
            </Heading>

            <Content>
                <Tabs
                    onChange={onTabChange}
                    tabBarExtraContent={
                        //@ts-ignore
                        activeTab == 'calendar-configuration-main' && (
                            <Button form={activeTab} htmlType="submit" type="primary">
                                Zapisz
                            </Button>
                        )
                    }
                    defaultActiveKey={activeTab}
                    tabBarStyle={{ marginBottom: '3em' }}
                >
                    <TabPane tab="Ogólne" key="calendar-configuration-main">
                        <CalendarConfigurationMain />
                    </TabPane>
                </Tabs>
            </Content>
        </Container>
    );
}
