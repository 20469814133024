import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import Colors from '../../../../../../constants/Colors';
import Breakpoints from '../../../../../../constants/Breakpoints';
import Logo from '../../../../../../components/Logo';
import Navigation from './Navigation';
import BottomBar from './BottomBar';
import { MdMenu } from 'react-icons/md';
import { useOutsideAlerter } from '../../../../../../hooks/useOutsideAlerter';
import { usePrevious } from '../../../../../../hooks/usePrevious';

const Container = styled.div`
    width: 320px;
    min-width: 320px;
    height: 100%;
    display: flex;
    background: ${Colors.greys['100']};
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.15);
    position: relative;
    align-items: center;
    flex-direction: column;

    @media (max-width: ${Breakpoints.max.tablet}px) {
        left: -320px;
        z-index: 9;
        width: 250px;
        min-width: 250px;

        &.closed {
            animation: moveNavigationOnClosed 0.5s forwards;
        }

        &.open {
            animation: moveNavigationOnOpen 0.5s forwards;
            position: fixed;
        }
    }

    @keyframes moveNavigationOnOpen {
        from {
            left: -320px;
        }
        to {
            left: 0;
        }
    }

    @keyframes moveNavigationOnClosed {
        from {
            left: 0;
        }
        to {
            left: -320px;
        }
    }
`;

const MenuButton = styled.div`
    position: fixed;
    z-index: 9;
    background: ${Colors.greys['100']};
    padding-left: 12px;
    padding-right: 6px;
    padding-top: 4px;
    left: 0px;
    top: 12px;
    border-radius: 6px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    opacity: 0.9;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    cursor: pointer;

    &.closed {
        animation: moveMenuButtonOnClosed 0.5s forwards;
    }

    &.open {
        animation: moveMenuButtonOnOpen 0.5s forwards;
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
        padding-left: 6px;
    }

    @media (min-width: ${Breakpoints.min.tablet}px) {
        display: none;
    }

    @keyframes moveMenuButtonOnOpen {
        from {
            left: 0;
        }
        to {
            left: 260px;
        }
    }

    @keyframes moveMenuButtonOnClosed {
        from {
            left: 260px;
        }
        to {
            left: 0;
        }
    }
`;

export default function LeftPanel() {
    const containerRef = useRef(null);
    const buttonRef = useRef(null);
    const [leftPanelOpen, setLeftPanelOpen] = useState(false);
    const prevLeftPanelOpen = usePrevious({ leftPanelOpen });

    useOutsideAlerter(containerRef, () => {
        setLeftPanelOpen(false);
    });

    useEffect(() => {
        console.log('previous:', prevLeftPanelOpen);

        if (leftPanelOpen) {
            if (containerRef != null && containerRef.current != null) {
                //@ts-ignore
                containerRef.current.classList.add('open');
            }

            if (buttonRef != null && buttonRef.current != null) {
                //@ts-ignore
                buttonRef.current.classList.add('open');
            }
        } else {
            if (containerRef != null && containerRef.current != null) {
                //@ts-ignore
                containerRef.current.classList.remove('open');

                if (prevLeftPanelOpen?.leftPanelOpen === true) {
                    //@ts-ignore
                    containerRef.current.classList.add('closed');
                }
            }

            if (buttonRef != null && buttonRef.current != null) {
                //@ts-ignore
                buttonRef.current.classList.remove('open');

                if (prevLeftPanelOpen?.leftPanelOpen === true) {
                    //@ts-ignore
                    buttonRef.current.classList.add('closed');
                }
            }
        }
    }, [leftPanelOpen]);

    const handleMenuClick = () => {
        setLeftPanelOpen(true);
    };

    return (
        <Container ref={containerRef}>
            <MenuButton ref={buttonRef} onClick={handleMenuClick}>
                <MdMenu size={32} />
            </MenuButton>
            <Logo containerStyle={{ paddingTop: '64px', paddingBottom: '64px' }} size={160} />
            <Navigation />
            <BottomBar />
        </Container>
    );
}
