import { Conversation } from '../interfaces/Conversation';

const initState = [] as any;

const lastMessageReducer = (state = initState, action: any) => {
    switch (action.type) {
        case 'UPDATE_LAST_MESSAGE':
            let conversationIndex = state.findIndex((c: any) => c.conversation == action.payload.conversation);

            if (conversationIndex !== -1) {
                return state.map((item: any, index: any) => {
                    if (index !== conversationIndex) {
                        return item;
                    }

                    return action.payload;
                });
            } else {
                return [...state, action.payload];
            }
        default:
            return state;
    }
};

export default lastMessageReducer;
