import React, { useEffect, useState } from 'react';
import { Row, Col, Input, Form, Button, message } from 'antd';
import styled from 'styled-components';
import { useForm } from 'antd/lib/form/Form';
import { useParams, useHistory, useRouteMatch, Link } from 'react-router-dom';
import { post } from '../../../../../../services/Requests';
import { Store } from 'antd/lib/form/interface';
import Colors from '../../../../../../constants/Colors';
import validationHandler from '../../../../../../services/ValidationHandler';
import { useGlobalState } from '../../../../../../state';

const InputsContainer = styled.div`
    margin: 2rem 0;
    padding: 3em 3em;
    background: ${Colors.greys[200]};
    border-radius: 6px;
    max-width: 550px;
    margin: 2em;
`;

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Title = styled.p`
    font-size: 2em;
    font-weight: 500;
`;

const LinksContainer = styled.div`
    margin-top: 2em;
`;

const Back = styled.div`
    margin-bottom: 1em;
    font-weight: 700;
`;

export default function Client_Auth(props: any) {
    const [token, setToken] = useState('');
    const [disabled, setDisabled] = useState(false);
    const [loading, setLoading] = useState(false);
    const [form] = useForm();

    const [portfolio] = useGlobalState('portfolio');

    const history = useHistory();
    const match = useRouteMatch();
    const params = useParams() as any;

    const formSubmit = async (values: any) => {
        setLoading(true);
        setDisabled(true);

        try {
            let response = await post(
                `auth/client/login`,
                { ...values, photographer: props.slug },
                { useToken: false },
            );

            if (!response.success) {
                let errorMessage = validationHandler(response, form);

                if (errorMessage) {
                    form.setFields([
                        {
                            name: 'password',
                            errors: ['Podane hasło jest niepoprawne.'],
                        },
                    ]);
                }

                setDisabled(false);
            } else {
                response = response as Response;
                const token = response.data.token;

                localStorage.setItem('jwt-client', token);
                history.push(`../client`);
            }

            setLoading(false);
        } catch (err) {
            message.error(err.message);
            // TODO: HANDLE ERROR
        }
    };

    return (
        <Container>
            <Form form={form} onFinish={formSubmit}>
                <InputsContainer>
                    <Back>
                        <Link to={`/user/${portfolio.slug}`}>&larr; Wróć</Link>
                    </Back>
                    <Title>Zaloguj się na konto klienta</Title>
                    <Row>
                        <Col span={24}>
                            <Form.Item
                                name="email"
                                help={null}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Musisz podać adres e-mail.',
                                    },
                                ]}
                            >
                                <Input disabled={disabled} placeholder="Adres e-mail" />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="password"
                                help={null}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Musisz podać hasło.',
                                    },
                                ]}
                            >
                                <Input type="password" disabled={disabled} placeholder="Hasło" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Button
                        loading={loading}
                        disabled={disabled}
                        htmlType="submit"
                        type="primary"
                        size="large"
                        block={false}
                    >
                        Zaloguj
                    </Button>

                    <LinksContainer>
                        <a href="#">Nie znasz hasła?</a>
                    </LinksContainer>
                </InputsContainer>
            </Form>
        </Container>
    );
}
