import React, { useEffect } from 'react';
import Client_AuthProvider from './components/AuthProvider';
import { useGlobalState } from '../../../../state';
import { BrowserRouter as Router, Switch, Route, useRouteMatch, useParams, useHistory } from 'react-router-dom';
import Client_Auth from './views/Auth';
import Client_Home from './views/Home';
import Client_ChangePassword from './components/ChangePassword';
import Client_Confirm from './views/Confirm';

export default function Photographer_Client() {
    const match = useRouteMatch();
    const params: any = useParams();

    return (
        <Switch>
            <Route path={`${match.url}/login`}>
                <Client_Auth slug={params.slug} />
            </Route>
            <Route path={`${match.url}/confirm/:id`}>
                <Client_Confirm />
            </Route>
            <Route path={`${match.url}/`}>
                <Client_AuthProvider>
                    <Client_ChangePassword>
                        <Client_Home />
                    </Client_ChangePassword>
                </Client_AuthProvider>
            </Route>
        </Switch>
    );
}
