import { Col, message, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Heading from '../../../../components/Heading';
import Colors from '../../../../constants/Colors';
import { Photoshoot, PhotoshootStatus } from '../../../../interfaces/Photoshoot';
import { get } from '../../../../services/Requests';
import { useGlobalState } from '../../../../state';
import UpcomingPhotoshoots from './Data/UpcomingPhotoshoots';
import moment from 'moment';
import RecentMessages from './Data/RecentMessages';
import { useSocket } from 'use-socketio';
import { useDispatch } from 'react-redux';

const Container = styled.div``;
const Card = styled.div`
    border-radius: 6px;
    padding: 12px;
    background-color: ${Colors.greys[100]};
`;
const Label = styled.div`
    font-size: 0.8rem;
    font-weight: 400;
    /* text-transform: uppercase; */
    margin-bottom: 1em;
`;

export default function AppDashboard() {
    const [appLoading, setAppLoading] = useGlobalState('appLoading');
    const dispatch = useDispatch();

    const [photoshoots, setPhotoshoots] = useState([]);
    const [photoshootsLoading, setPhotoshootsLoading] = useState(true);

    const [conversations, setConversations]: [any, Function] = useState([]);
    const [conversationsLoading, setConversationsLoading] = useState(true);

    useSocket('newConversation', (data) => {
        setConversations([data, ...conversations]);
    });

    const loadPhotoshoots = async () => {
        setPhotoshootsLoading(true);
        try {
            let response = await get(`photoshoots`);

            if (!response.success) {
                message.error('Nie udało się załadować sesji. Spróbuj ponownie później.');
                return;
            }

            setPhotoshoots(response.data);
            setPhotoshootsLoading(false);
        } catch (err) {
            message.error('Wystąpił błąd podczas ładowania sesji.');
        }
    };

    const loadConversations = async () => {
        setConversationsLoading(true);
        try {
            let response = await get(`conversations`);

            if (!response.success) {
                message.error('Nie udało się załadować wiadomości. Spróbuj ponownie później.');
                return;
            }

            setConversations(response.data);
            setConversationsLoading(false);
        } catch (err) {
            message.error('Wystąpił błąd podczas ładowania wiadomości.');
        }
    };

    useEffect(() => {
        for (let conversation of conversations) {
            dispatch({
                type: 'UPDATE_LAST_MESSAGE',
                payload: {
                    conversation: conversation._id,
                    message: conversation.messages[0],
                },
            });
        }
    }, [conversations]);

    useEffect(() => {
        loadPhotoshoots();
        loadConversations();
    }, []);

    return (
        <Container>
            <Heading>Panel</Heading>
            <Row gutter={[12, 12]}>
                <Col xs={24} sm={24} md={24} lg={12}>
                    <Row gutter={[12, 12]}>
                        <Col span={24}>
                            <Card>
                                <Label>Nadchodzące sesje</Label>
                                <UpcomingPhotoshoots
                                    loading={photoshootsLoading}
                                    photoshoots={photoshoots.filter(
                                        (photoshoot: Photoshoot) =>
                                            moment(photoshoot.datetime).isSameOrAfter(moment()) &&
                                            photoshoot.status == PhotoshootStatus.CONFIRMED,
                                    )}
                                />
                            </Card>
                        </Col>
                        <Col span={24}>
                            <Card>
                                <Label>Nieukończone sesje</Label>
                                <UpcomingPhotoshoots
                                    loading={photoshootsLoading}
                                    photoshoots={photoshoots.filter(
                                        (photoshoot: Photoshoot) =>
                                            moment(photoshoot.datetime).isBefore(moment()) &&
                                            photoshoot.status == PhotoshootStatus.CONFIRMED,
                                    )}
                                />
                            </Card>
                        </Col>
                    </Row>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12}>
                    <Row gutter={[12, 12]}>
                        <Col span={24}>
                            <Card>
                                <Label>Ostatnie wiadomości</Label>
                                <RecentMessages
                                    loading={conversationsLoading}
                                    conversations={conversations.sort((a: any, b: any) => {
                                        if (a.messages.length == 0) {
                                            return b;
                                        }

                                        if (b.messages.length == 0) {
                                            return a;
                                        }

                                        //@ts-ignore
                                        return new Date(b.messages[0].updatedAt) - new Date(a.messages[0].updatedAt);
                                    })}
                                />
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
}
