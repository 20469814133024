import React, { useState, useEffect, useReducer } from 'react';
import styled from 'styled-components';
import { Upload, message, Form, Row, Col, Progress, Button, Modal, Space, Popconfirm, Table } from 'antd';
import { InboxOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import Colors from '../../../../../constants/Colors';
import { get, post } from '../../../../../services/Requests';
import { useGlobalState } from '../../../../../state';
import deleteMethod from '../../../../../services/Requests/delete';
import validationHandler from '../../../../../services/ValidationHandler';
import { FaCheckCircle } from 'react-icons/fa';

const Images = styled.div`
    margin-top: 2em;
`;

const MiniThumb = styled.img`
    max-width: 30px;
    max-height: 30px;
`;

const PreviewContainer = styled.div`
    display: flex;
    padding: 8px;
    background: ${Colors.greys[200]};
    border-radius: 6px;
    align-items: center;
    flex-direction: column;
`;

const PreviewImage = styled.img`
    height: 220px;
    max-width: 100%;
    object-fit: contain;
    cursor: pointer;
`;

const PreviewButtons = styled.div`
    margin-top: 1em;
`;

const { Dragger } = Upload;
const { useForm } = Form;
const { confirm } = Modal;

const initialState: any = [];
const fileListActionTypes = {
    updateFile: 'UPDATE_FILE',
    setInitialFiles: 'SET_INITIAL_FILES',
    deleteFile: 'DELETE_FILE',
    deleteFiles: 'DELETE_FILES',
};

function fileListReducer(prevState = initialState, action: any) {
    switch (action.type) {
        case fileListActionTypes.updateFile:
            action.payload.key = action.payload.uid;

            return [
                action.payload,
                ...prevState.filter((f: any) => f.uid !== action.payload.uid && f.uid !== action.payload.oldUid),
            ];
        case fileListActionTypes.setInitialFiles:
            action.payload = action.payload.map((el: any) => ({ ...el, key: el.uid }));
            return [...action.payload];
        case fileListActionTypes.deleteFile:
            return [...prevState.filter((f: any) => f.uid !== action.payload.uid)];
        case fileListActionTypes.deleteFiles:
            return [...prevState.filter((f: any) => !action.payload.includes(f.uid))];
        default:
            return prevState;
    }
}

export default function PortfolioPhotos() {
    const [form] = useForm();
    const [loading, setLoading] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [appLoading, setAppLoading] = useGlobalState('appLoading');
    const [fullScreenImage, setFullScreenImage] = useGlobalState('fullScreenImage');

    const [preview, setPreview]: [any, Function] = useState(null);
    const [selectedRows, setSelectedRows]: [any, Function] = useState([]);

    const openFullScreen = (src: any) => {
        setFullScreenImage(src);
    };

    const [fileList, dispatch] = useReducer(fileListReducer, initialState);

    useEffect(() => {
        // Load photos
        (async () => {
            try {
                setAppLoading(true);
                let response = await get('portfolio/photos');

                if (response.success) {
                    response = response as Response;

                    dispatch({
                        type: fileListActionTypes.setInitialFiles,
                        payload: response.data.map((photo: any) => {
                            return {
                                status: 'saved',
                                name: photo.originalName,
                                uid: photo._id,
                                thumbnail: photo.thumbnail,
                            };
                        }),
                    });

                    setAppLoading(false);
                }
            } catch (err) {
                // TODO: handle error
            }
        })();
    }, []);

    let token = localStorage.getItem('jwt');
    const opts = {
        name: 'file',
        accept: '.jpg,.JPG,.jpeg,.JPEG,.png,.PNG',
        showUploadList: false,
        beforeUpload,
        headers: {
            Authorization: `Bearer ${token}`,
        },
        multiple: true,
        action: (process.env.REACT_APP_API as string) + '/portfolio/photos/upload',
        onChange(info: any) {
            let files = info.fileList
                .map((file: any) => {
                    if (typeof file.status === 'undefined') {
                        return null;
                    }

                    return {
                        ...file,
                    };
                })
                .filter(Boolean);

            if (info.file.originFileObj && typeof info.file.status !== 'undefined' && info.file.status !== 'done') {
                const reader = new FileReader();
                reader.onloadend = (e) => {
                    let img = document.getElementById(`thumb-${info.file.uid}`) as any;
                    if (typeof img !== 'undefined' && img !== null) {
                        img.src = e.target && e.target.result;
                    }
                };
                reader.readAsDataURL(info.file.originFileObj);
            }

            if (info.file.status === 'done') {
                info.file.oldUid = info.file.uid;
                info.file.uid = info.file.response._id;
            }

            dispatch({ type: fileListActionTypes.updateFile, payload: info.file });
        },
    };

    function beforeUpload(file: File) {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('Możesz dodać tylko pliki jpg, jpeg oraz png!');
        }
        const isLt4M = file.size / 1024 / 1024 < 4;
        if (!isLt4M) {
            message.error('Zdjęcie nie może przekraczać 4mb!');
        }

        return isJpgOrPng && isLt4M;
    }

    const deletePhoto = async (file: any) => {
        try {
            setDisabled(true);
            let response = await deleteMethod(`portfolio/photos/${file.uid}`);

            if (!response.success) {
                let errorMessage = validationHandler(response, form);
                message.error(errorMessage);
                // TODO: HANDLE ERROR

                setDisabled(false);
            } else {
                response = response as Response;
                message.success('Pomyślnie usunięto zdjęcie.');

                dispatch({ type: fileListActionTypes.deleteFile, payload: file });

                if (file.uid == preview.uid) {
                    setPreview(null);
                }
            }

            setLoading(false);
            setDisabled(false);
        } catch (err) {
            // TODO: HANDLE ERROR
        }
    };

    const columns = [
        {
            title: '',
            dataIndex: 'uid',
            render: (data: any, file: any) => {
                let photo =
                    file.status === 'saved' || file.status === 'done'
                        ? file.thumbnail
                            ? `${process.env.REACT_APP_API as string}/portfolio/photos/${file.thumbnail._id}`
                            : `${process.env.REACT_APP_API as string}/portfolio/photos/${file.uid}`
                        : '';

                return <MiniThumb id={`thumb-${file.uid}`} src={photo} />;
            },
        },
        {
            title: 'Nazwa pliku',
            dataIndex: 'name',
        },
        {
            title: 'Wymiary',
            render: (data: any, record: any) => {
                if (record.width && record.height) {
                    return `${record.width}x${record.height}`;
                }

                return '-';
            },
        },
        {
            title: 'Postęp',
            dataIndex: 'percent',
            render: (data: any, record: any) => {
                if (record.status == 'saved' || record.status == 'done') {
                    return <FaCheckCircle style={{ color: 'green' }} />;
                }

                let percent = record.percent;
                let status;

                if (record.status == 'error') {
                    status = 'exception';
                } else {
                    status = 'active';
                }

                //@ts-ignore
                return <Progress showInfo={false} percent={percent} status={status}></Progress>;
            },
        },
    ];

    const handleBulkDelete = async () => {
        setAppLoading(true);
        try {
            let response = await post(`portfolio/photos/bulkDelete`, { photos: selectedRows });

            if (!response.success) {
                message.error(response.message);
                setAppLoading(false);
            } else {
                response = response as Response;
                message.success('Pomyślnie usunięto zdjęcia.');

                dispatch({ type: fileListActionTypes.deleteFiles, payload: selectedRows });

                if (preview && selectedRows.includes(preview.uid)) {
                    setPreview(null);
                }

                setSelectedRows([]);
            }

            setAppLoading(false);
        } catch (err) {
            message.error('Wystąpił błąd podczas usuwania zdjęć. Spróbuj ponownie później.');
            setAppLoading(false);
        }
    };

    const Preview = () => {
        if (!preview) return <div />;

        return (
            <PreviewContainer>
                <PreviewImage
                    onClick={() =>
                        setFullScreenImage(`${process.env.REACT_APP_API as string}/portfolio/photos/${preview.uid}`)
                    }
                    src={
                        preview.thumbnail
                            ? `${process.env.REACT_APP_API as string}/portfolio/photos/${preview.thumbnail._id}`
                            : `${process.env.REACT_APP_API as string}/portfolio/photos/${preview.uid}`
                    }
                ></PreviewImage>
                <PreviewButtons>
                    <Space>
                        <Popconfirm
                            okText="Usuń"
                            cancelText="Anuluj"
                            title="Czy jesteś pewny/a, że chcesz usunąć zdjęcie?"
                            onConfirm={() => deletePhoto(preview)}
                        >
                            <Button size="small" danger>
                                Usuń
                            </Button>
                        </Popconfirm>
                    </Space>
                </PreviewButtons>
            </PreviewContainer>
        );
    };

    return (
        <div>
            <Space>
                <Popconfirm
                    disabled={selectedRows.length == 0}
                    okText="Usuń"
                    cancelText="Anuluj"
                    title="Czy jesteś pewny/a, że chcesz usunąć zaznaczone zdjęcia? Operacja ta jest nieodwracalna."
                    onConfirm={handleBulkDelete}
                >
                    <Button disabled={selectedRows.length == 0} danger>
                        Usuń zaznaczone
                    </Button>
                </Popconfirm>
            </Space>
            <div style={{ marginTop: '2em' }}>
                <Dragger {...opts}>
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">Kliknij albo upuść pliki</p>
                    <p className="ant-upload-hint">Dostępne formaty: *.jpg, *.jpeg, *.png</p>
                </Dragger>
                <Images>
                    <Row gutter={[12, 12]}>
                        <Col
                            xs={{ span: 24, order: 2 }}
                            sm={{ span: 24, order: 2 }}
                            md={{ span: 24, order: 2 }}
                            lg={{ span: preview ? 16 : 24, order: 1 }}
                        >
                            <Table
                                rowClassName={(row: any) => {
                                    if (row.status == 'saved' || row.status == 'done') {
                                        return `cursor-pointer`;
                                    }

                                    return ``;
                                }}
                                onRow={(row, rowIndex) => {
                                    return {
                                        onClick: (e: any) => {
                                            if (row.status == 'saved' || row.status == 'done') {
                                                setPreview(row);
                                            }
                                        },
                                    };
                                }}
                                dataSource={fileList}
                                rowSelection={{
                                    type: 'checkbox',
                                    onChange: (data) => {
                                        setSelectedRows(data);
                                    },
                                    getCheckboxProps: (record) => {
                                        return {
                                            disabled: record.status !== 'saved' && record.status !== 'done',
                                            name: 'selected_photos',
                                            value: record.uid,
                                        };
                                    },
                                }}
                                size="small"
                                columns={columns}
                            />
                        </Col>
                        <Col
                            xs={{ span: 24, order: 1 }}
                            sm={{ span: 24, order: 1 }}
                            md={{ span: 24, order: 1 }}
                            lg={{ span: preview ? 8 : 24, order: 2 }}
                        >
                            <Preview />
                        </Col>
                    </Row>
                </Images>
            </div>
        </div>
    );
}
