import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import Breakpoints from '../../../../../../constants/Breakpoints';
import SearchBar from './SearchBar';
import { useGlobalState } from '../../../../../../state';

interface MainPanelProps {}

const Container = styled.div<any>`
    position: relative;
    display: flex;
    flex-direction: column;
    padding: ${(props) => props.padding};
    width: 100%;
    overflow: auto;

    @media (max-width: ${Breakpoints.max.tablet}px) {
        left: 0;
        position: absolute;
        padding: ${(props) => (props.padding === '0' ? '0' : '2em 1em')};
        height: 100%;
    }
`;

export default function MainPanel(props: PropsWithChildren<MainPanelProps>) {
    const [mainPanelPadding] = useGlobalState('mainPanelPadding');

    return (
        <Container padding={mainPanelPadding}>
            <SearchBar />
            {props.children}
        </Container>
    );
}
