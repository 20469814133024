import React, { useEffect, useState } from 'react';
import Portfolio from './Portfolio';
import { createGlobalStyle } from 'styled-components';
import { useGlobalState } from '../../state';
import color from 'color';

const GlobalStyle = createGlobalStyle`
    body {
        overflow: unset;
    }

    ::selection {
        background: ${(props: any) => (props.primary ? props.primary : 'black')};
    }

    input:focus {
        border-color: ${(props) => props.primary} !important;
    }

    input:hover {
        border-color: ${(props) => props.primary} !important;
    }

    a {
        color: ${(props) => props.primary};

        &:hover {
            color: ${(props) => color(props.primary).darken(0.1).hex()}
        }
    }

    button.ant-btn {
        background-color: ${(props) => props.primary} !important;
        border-color: ${(props) => props.primary} !important;
        color: ${(props) => color(props.primary).negate().hex()} !important;

        &:hover {
            background-color: ${(props) => color(props.primary).darken(0.1).hex()} !important;
        }
    }
`;

export default function Photographer() {
    const [portfolio] = useGlobalState('portfolio');

    return (
        <div>
            <GlobalStyle primary={portfolio && portfolio.template && portfolio.template.colors.primary} />
            <Portfolio />
        </div>
    );
}
