import React, { useEffect, useRef, useState } from 'react';
import Heading from '../../../../../../../components/Heading';
import styled from 'styled-components';
import { useGlobalState } from '../../../../../../../state';
import { Tabs, Button, Badge, Table, message, Popover, Space, Popconfirm, Descriptions } from 'antd';
import { get, post } from '../../../../../../../services/Requests';
import moment from 'moment';
import { Link, useHistory, useParams } from 'react-router-dom';
import deleteRequest from '../../../../../../../services/Requests/delete';
import { Photoshoot, PhotoshootStatus, translatePhotoshootStatus } from '../../../../../../../interfaces/Photoshoot';
import Client from '../../../../../../../interfaces/Client';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import GalleryComponent from '../../../../../../../components/Gallery';

const Container = styled.div`
    margin-top: -1em;
`;

const Content = styled.div`
    margin-top: 2em;
`;

export default function Client_PhotoshootsPhotoshoot() {
    const [user] = useGlobalState('user');
    const [appLoading, setAppLoading] = useGlobalState('appLoading');
    const [photoshootsAwaitingConfirmation, setPhotoshootsAwaitingConfirmation] = useGlobalState(
        'photoshootsAwaitingConfirmation',
    );
    const match = useRouteMatch();

    //@ts-ignore
    const [photoshoot, setPhotoshoot]: [Photoshoot, Function] = useState({});
    const history = useHistory();
    const params: any = useParams();

    const [buttons, setButtons]: [any, Function] = useState([]);

    useEffect(() => {
        loadPhotoshoot(params.id);
    }, [params]);

    const handleConfirm = async (id: any) => {
        setAppLoading(true);
        try {
            let response = await post(`photoshoots/${id}/confirm`, {}, { client: true });
            if (!response.success) {
                let error = response.message;
                message.error(`Wystąpił błąd podczas potwierdzania sesji: ${error}`);
                setAppLoading(false);
                return;
            }

            message.success('Pomyślnie potwierdzono sesję.');
            setAppLoading(false);

            loadPhotoshoot(photoshoot._id);
        } catch (err) {
            message.error(`Wystąpił błąd podczas potwierdzania sesji. Spróbuj ponownie później.`);
            setAppLoading(false);
        }
    };

    const loadPhotoshoot = async (id: any) => {
        try {
            let response = await get(`photoshoots/${id}`, { client: true });

            if (!response.success) {
                message.error('Nie udało się załadować danych o sesji. Spróbuj ponownie później.');
                return;
            }

            setPhotoshoot(response.data);
            let btns: any = [];
            if (response.data && response.data.status == PhotoshootStatus.AWAITING_CLIENT_CONFIRMATION) {
                if (photoshoot.status == PhotoshootStatus.AWAITING_CLIENT_CONFIRMATION) {
                    btns = [
                        ...btns,
                        (record: any) => (
                            <Popconfirm
                                cancelText="Nie"
                                okType="danger"
                                okText="Tak"
                                title="Czy jesteś pewny/a, że chcesz potwierdzić tą sesję zdjęciową?"
                                onConfirm={() => handleConfirm(record._id)}
                            >
                                <Button danger>Potwierdź sesję</Button>
                            </Popconfirm>
                        ),
                    ];
                }
            }

            setButtons(btns);
        } catch (err) {
            message.error('Wystąpił błąd podczas ładowania danych o sesji.');
        }
    };

    if (!photoshoot._id) {
        return <div />;
    }

    return (
        <Container>
            <Heading>
                {photoshoot.type.name} ({translatePhotoshootStatus(photoshoot.status)})
            </Heading>

            <Content>
                <Switch>
                    <Route path={`${match.url}`}>
                        <Space>
                            {buttons.map((btn: any, index: any) => {
                                return btn(photoshoot);
                            })}
                        </Space>

                        <Descriptions style={{ marginTop: '2em' }} title="Dane o sesji">
                            <Descriptions.Item label="Data">
                                {moment(photoshoot.datetime).format('YYYY-MM-DD HH:mm')}
                            </Descriptions.Item>
                        </Descriptions>

                        {photoshoot.status == PhotoshootStatus.FINISHED ? (
                            <div>
                                <Descriptions
                                    style={{ marginTop: '2em' }}
                                    title={`Zdjęcia ${
                                        photoshoot.photos.length > 0 ? `(${photoshoot.photos.length} zdjęć)` : ``
                                    }`}
                                ></Descriptions>
                                {photoshoot.photos && photoshoot.photos.length > 0 ? (
                                    <GalleryComponent photos={photoshoot.photos} />
                                ) : (
                                    <div>Brak zdjęć</div>
                                )}
                            </div>
                        ) : (
                            <div>Gdy tylko fotograf ukończy pracę nad sesją, zostaniesz o tym poinformowany/a.</div>
                        )}
                    </Route>
                </Switch>
            </Content>
        </Container>
    );
}
