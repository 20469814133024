import { FormInstance } from 'antd/lib/form';
import BadRequestException, { ErrorMessage } from '../../interfaces/BadRequestException';

export default function validationHandler(response: BadRequestException, form: FormInstance) {
    if (response.message) {
        if (typeof response.message === 'string') {
            return response.message as string;
        } else {
            let messages: ErrorMessage[] = response.message;

            form.setFields([
                ...messages.map((message: ErrorMessage) => {
                    return {
                        name: message.property,
                        errors: message.messages,
                    };
                }),
            ]);
        }
    }
}
