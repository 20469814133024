import React, { useState } from 'react';
import styled from 'styled-components';
import { useGlobalState } from '../../../../../../state';
import SimpleTheme_InputComponent from '../../components/Input';
import { Row, Col } from 'antd';
import SimpleTheme_SmallMasonryComponent from '../../components/SmallMasonry';
import SimpleTheme_TextareaComponent from '../../components/Textarea';
import SimpleTheme_ButtonComponent from '../../components/Button';
import { post } from '../../../../../../services/Requests';
import BadRequestException from '../../../../../../interfaces/BadRequestException';

const Container = styled.div`
    width: 100%;
`;

const FormContainer = styled.div`
    position: relative;
`;

const SuccessOverlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    z-index: 8;

    background: rgba(255, 255, 255, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    color: green;
`;

const ContactForm = styled(Row)``;

const ErrorContainer = styled.div`
    color: red;
    margin-top: 1em;
`;

const SuccessContainer = styled.div`
    color: green;
    margin-top: 1em;
`;

const Heading = styled.p`
    letter-spacing: 0.3px;
    font-weight: 600;

    margin-bottom: 24px;
`;

export default function SimpleTheme_ContactView() {
    const [portfolio] = useGlobalState('portfolio');
    const [appLoading, setAppLoading] = useGlobalState('appLoading');

    const [fullName, setFullName] = useState('');
    const [message, setMessage] = useState('');
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [disabled, setDisabled] = useState(false);

    function validateEmail(email: string) {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    const handleSendMessage = async () => {
        setError('');
        setAppLoading(true);

        if (email.length == 0 || fullName.length == 0 || message.length == 0) {
            setError('Musisz wypełnić wszystkie pola!');
            setAppLoading(false);
            return;
        }

        if (!validateEmail(email)) {
            setError('Musisz podać poprawny adres email!');
            setAppLoading(false);
            return;
        }

        try {
            let response = await post(
                `conversations/${portfolio.slug}`,
                {
                    email,
                    fullName,
                    message,
                },
                { useToken: false },
            );

            if (!response.success) {
                response = response as BadRequestException;

                if (typeof response.message === 'string') {
                    setError(response.message as string);
                }

                setAppLoading(false);
                return;
            }

            setSuccess(
                'Wiadomość została pomyślnie wysłana! Na podany adres e-mail został wysłany link do konwersacji.',
            );
            setAppLoading(false);
        } catch (err) {
            setError('Wystąpił nieoczekiwany błąd. Spróbuj ponownie później.');
            setAppLoading(false);
            setDisabled(true);
        }
    };

    return (
        <Container>
            <FormContainer>
                {success && <SuccessOverlay></SuccessOverlay>}
                <Row>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12} span={12}>
                        <ContactForm>
                            <Col span={24}>
                                <Heading>
                                    👋 <br /> Cześć, chcesz o coś zapytać?
                                </Heading>
                            </Col>
                            <Col span={24}>
                                <SimpleTheme_InputComponent
                                    name="email"
                                    type="email"
                                    label="Adres e-mail"
                                    onChange={(value: string) => setEmail(value)}
                                ></SimpleTheme_InputComponent>
                            </Col>

                            <Col span={24}>
                                <SimpleTheme_InputComponent
                                    name="fullName"
                                    label="Imię i nazwisko"
                                    onChange={(value: string) => setFullName(value)}
                                ></SimpleTheme_InputComponent>
                            </Col>
                            <Col span={24}>
                                <SimpleTheme_TextareaComponent
                                    name="message"
                                    label="Treść wiadomości"
                                    onChange={(value: string) => setMessage(value)}
                                ></SimpleTheme_TextareaComponent>
                            </Col>

                            <Col xs={24} sm={24} md={12} lg={8} span={4}>
                                <SimpleTheme_ButtonComponent disabled={disabled} onClick={handleSendMessage}>
                                    Wyślij
                                </SimpleTheme_ButtonComponent>
                            </Col>
                        </ContactForm>
                    </Col>
                    <Col xs={0} sm={0} md={0} lg={12} xl={12} span={12}></Col>
                </Row>
            </FormContainer>
            {error && <ErrorContainer>{error}</ErrorContainer>}
            {success && <SuccessContainer>{success}</SuccessContainer>}
        </Container>
    );
}
