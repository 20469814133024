import React, { PropsWithChildren, useEffect, useState } from 'react';
import { useGlobalState } from '../../../../../state';
import { Row, Col, Input, Form, Button, message } from 'antd';
import styled from 'styled-components';
import { useForm } from 'antd/lib/form/Form';
import { useParams, useHistory, useRouteMatch } from 'react-router-dom';
import { put } from '../../../../../services/Requests';
import { Store } from 'antd/lib/form/interface';
import Colors from '../../../../../constants/Colors';
import validationHandler from '../../../../../services/ValidationHandler';

const InputsContainer = styled.div`
    margin: 2rem 0;
    padding: 3em 3em;
    background: ${Colors.greys[200]};
    border-radius: 6px;
    width: 100%;
`;

const FormContainer = styled(Form)`
    max-width: 550px;
    width: 550px;
    margin: 2em;
`;

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Title = styled.p`
    font-size: 2em;
    font-weight: 500;
`;

const Description = styled.p`
    font-size: 1em;
    font-weight: 300;
`;

const LinksContainer = styled.div`
    margin-top: 2em;
`;

export default function Client_ChangePassword(props: PropsWithChildren<any>) {
    const [loading, setLoading] = useState(true);
    const [success, setSuccess] = useState(false);

    const [disabled, setDisabled] = useState(false);
    const [formLoading, setFormLoading] = useState(false);

    const [form] = useForm();

    const [client, setClient] = useGlobalState('client');
    useEffect(() => {
        if (typeof client._id != 'undefined') {
            if (client.passwordChanged === true) {
                setLoading(false);
                setSuccess(true);
            } else {
                setLoading(false);
                setSuccess(false);
            }
        }
    }, [client]);

    if (!loading && success) {
        return props.children;
    }

    const formSubmit = (values: any) => {
        setFormLoading(true);
        setDisabled(true);

        if (values.password.length < 8) {
            form.setFields([
                {
                    name: 'password',
                    errors: ['Hasło musi mieć przynajmniej 8 znaków.'],
                },
            ]);
            setFormLoading(false);
            setDisabled(false);

            return;
        }

        (async () => {
            try {
                let response = await put(`clients/password`, { ...values }, { client: true });

                if (!response.success) {
                    let errorMessage = validationHandler(response, form);
                    console.log(response);

                    setDisabled(false);
                } else {
                    response = response as Response;
                    message.success('Hasło zostało zmienione');

                    setClient({ ...client, passwordChanged: true });
                }

                setFormLoading(false);
            } catch (err) {
                message.error(err.message);
            }
        })();
    };

    if (!loading && !success) {
        return (
            <Container>
                <FormContainer form={form} onFinish={formSubmit}>
                    <InputsContainer>
                        <Title>Zmień hasło</Title>
                        <Row>
                            <Col span={24}>
                                <Form.Item
                                    name="oldPassword"
                                    help={null}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Musisz podać swoje tymczasowe hasło.',
                                        },
                                    ]}
                                >
                                    <Input type="password" disabled={disabled} placeholder="Hasło tymczasowe" />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    name="password"
                                    help={null}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Musisz podać nowe hasło.',
                                        },
                                    ]}
                                >
                                    <Input type="password" disabled={disabled} placeholder="Nowe hasło" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Button
                            loading={formLoading}
                            disabled={disabled}
                            htmlType="submit"
                            type="primary"
                            size="large"
                            block={false}
                        >
                            Zmień hasło
                        </Button>

                        <LinksContainer>
                            <a href="#">Nie znasz hasła tymczasowego?</a>
                        </LinksContainer>
                    </InputsContainer>
                </FormContainer>
            </Container>
        );
    }

    return <div />;
}
