export default {
    primary: {
        900: '#00565f',
        800: '#00656f',
        700: '#00737e',
        600: '#00828e',
        500: '#00909e',
        400: '#1a9ba8',
        300: '#33a6b1',
        200: '#4db1bb',
        100: '#66bcc5',
    },
    accents: {
        main: {
            900: '#717f7f',
            800: '#849494',
            700: '#97aaa9',
            600: '#aabfbe',
            500: '#bdd4d3',
            400: '#cadddc',
            300: '#d7e5e5',
            200: '#e5eeed',
            100: '#f2f6f6',
        },
    },
    greys: {
        900: '#000',
        800: '#888888',
        700: '#AAAAAA',
        600: '#EEEEEE',
        500: '#F1F1F1',
        300: '#F6F6F6',
        200: '#F9F9F9',
        150: '#FBFBFB',
        100: '#FDFDFD',
    },
};
