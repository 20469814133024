import React, { PropsWithChildren, useEffect, useState } from 'react';
import { Store } from 'antd/lib/form/interface';
import { useGlobalState } from '../../../../../state';
import { get, post, put } from '../../../../../services/Requests';
import Response from '../../../../../interfaces/Response';
import styled from 'styled-components';
import { Row, Col, Form, Input, Checkbox, message } from 'antd';
import Label from '../../../../../components/Label';
import Modal from 'antd/lib/modal/Modal';
import validationHandler from '../../../../../services/ValidationHandler';

const InputContainer = styled.div``;

const slotSize = 15;

const { useForm } = Form;

interface PhotoshootTypesProps {
    visible: boolean;
    hideModalFunction: Function;
    loadTypesFunction: Function;
    editType: any;
}

const { TextArea } = Input;

export default function PhotoshootTypes(props: PropsWithChildren<PhotoshootTypesProps>) {
    const [form] = useForm();
    const [loading, setLoading] = useState(false);
    const [disabled, setDisabled] = useState(false);

    const formSubmit = async (values: Store) => {
        setLoading(true);
        setDisabled(true);

        if (parseInt(values.duration) % slotSize != 0) {
            form.setFields([
                {
                    name: 'duration',
                    errors: [`Długość sesji musi być podzielna przez ${slotSize}`],
                },
            ]);

            setLoading(false);
            setDisabled(false);

            return;
        }

        try {
            if (props.editType) {
                let response = await put('photoshoots/types', { ...values, _id: props.editType._id });

                if (!response.success) {
                    let errorMessage = validationHandler(response, form);
                    // TODO: HANDLE ERROR

                    setDisabled(false);
                } else {
                    response = response as Response;

                    message.success('Pomyślnie edytowano rodzaj.');
                    props.hideModalFunction();
                    props.loadTypesFunction();
                }
            } else {
                let response = await post('photoshoots/types', values);

                if (!response.success) {
                    let errorMessage = validationHandler(response, form);
                    // TODO: HANDLE ERROR

                    setDisabled(false);
                } else {
                    response = response as Response;

                    message.success('Pomyślnie dodano rodzaj.');
                    props.hideModalFunction();
                    props.loadTypesFunction();
                }
            }

            setLoading(false);
            setDisabled(false);
        } catch (err) {
            // TODO: HANDLE ERROR
        }
    };

    useEffect(() => {
        form.setFields([
            {
                name: 'name',
                value: null,
            },
            {
                name: 'description',
                value: null,
            },
            {
                name: 'duration',
                value: null,
            },
        ]);
    }, [props.visible]);

    useEffect(() => {
        if (props.editType) {
            form.setFields([
                {
                    name: 'name',
                    value: props.editType.name,
                },
                {
                    name: 'description',
                    value: props.editType.description,
                },
                {
                    name: 'duration',
                    value: props.editType.duration,
                },
            ]);
        }
    }, [props.editType]);

    return (
        <Modal
            title={props.editType ? 'Edytuj rodzaj' : 'Dodaj rodzaj'}
            cancelText="Anuluj"
            okText={props.editType ? 'Zapisz' : 'Dodaj'}
            onOk={() => {
                form.submit();
            }}
            okButtonProps={{ loading }}
            onCancel={() => props.hideModalFunction()}
            visible={props.visible}
        >
            <Form form={form} onFinish={formSubmit}>
                <InputContainer>
                    <Row gutter={[0, 6]}>
                        <Col span={24}>
                            <Label description={<span>To nazwa sesji wyświetlania podczas rezerwacji terminu.</span>}>
                                Nazwa
                            </Label>
                            <Form.Item
                                name="name"
                                help={null}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Musisz wprowadzić nazwę sesji',
                                    },
                                ]}
                            >
                                <Input disabled={disabled} placeholder="Twoja nazwa" />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Label description="Podawany w minutach, pozwala dopasować możliwość rezerwacji terminu w zależności od planowanej sesji.">
                                Przybliżony czas sesji
                            </Label>
                            <Form.Item
                                name="duration"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Musisz wprowadzić przybliżony czas sesji',
                                    },
                                ]}
                                help={null}
                            >
                                <Input placeholder="Np. 45 (min)" type="number" step={slotSize} disabled={disabled} />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Label>Opis</Label>
                            <Form.Item name="description" help={null}>
                                <TextArea style={{ resize: 'none' }} rows={4} disabled={disabled} placeholder="Opis" />
                            </Form.Item>
                        </Col>
                    </Row>
                </InputContainer>
            </Form>
        </Modal>
    );
}
