import React, { PropsWithChildren, useEffect, useState } from 'react';
import { Store } from 'antd/lib/form/interface';
import { useGlobalState } from '../../../../../state';
import { get, post, put } from '../../../../../services/Requests';
import Response from '../../../../../interfaces/Response';
import styled from 'styled-components';
import { Row, Col, Form, Input, Checkbox, message } from 'antd';
import Label from '../../../../../components/Label';
import Modal from 'antd/lib/modal/Modal';
import validationHandler from '../../../../../services/ValidationHandler';
import Client from '../../../../../interfaces/Client';

const InputContainer = styled.div``;

const slotSize = 15;

const { useForm } = Form;

interface AddClientProps {
    visible: boolean;
    hideModalFunction?: Function;
    edit?: Client;
    onFinished?: Function;
    new?: boolean;
    email?: string;
}

const CheckboxLabel = styled.span`
    font-weight: 500;
    margin-bottom: 0.7em;
`;

const { TextArea } = Input;

export default function AddClient(props: PropsWithChildren<AddClientProps>) {
    const [form] = useForm();
    const [loading, setLoading] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [emailDisabled, setEmailDisabled] = useState(false);

    const formSubmit = async (values: Store) => {
        setLoading(true);
        setDisabled(true);

        try {
            let response;

            if (props.edit) {
                response = await put(`clients/${props.edit._id}`, values);

                if (!response.success) {
                    let errorMessage = validationHandler(response, form);
                    if (errorMessage) {
                        message.error('Nie udało się edytować klienta: ' + errorMessage);
                    }

                    setDisabled(false);
                } else {
                    response = response as Response;

                    message.success('Pomyślnie edytowano klienta.');
                    props.hideModalFunction && props.hideModalFunction();
                    props.onFinished && props.onFinished(response.data);
                }
            } else {
                response = await post('clients', values);

                if (!response.success) {
                    let errorMessage = validationHandler(response, form);
                    if (errorMessage) {
                        message.error('Nie udało się dodać nowego klienta: ' + errorMessage);
                    }

                    setDisabled(false);
                } else {
                    response = response as Response;

                    message.success('Pomyślnie dodano klienta.');
                    props.hideModalFunction && props.hideModalFunction();
                    props.onFinished && props.onFinished(response.data);
                }
            }

            setLoading(false);
            setDisabled(false);
        } catch (err) {
            if (props.edit) {
                message.error('Wystąpił błąd podczas próby edycji klienta. Spróbuj ponownie później.');
            } else {
                message.error('Wystąpił błąd podczas próby dodania nowego klienta. Spróbuj ponownie później.');
            }
        }
    };

    useEffect(() => {
        form.setFields([
            {
                name: 'email',
                value: props.email ? props.email : null,
            },
            {
                name: 'fullNameAlias',
                value: props.edit ? props.edit.fullNameAlias : null,
            },
            {
                name: 'phoneNumber',
                value: props.edit ? props.edit.phoneNumber : null,
            },
            {
                name: 'sendEmail',
                value: ['true'],
            },
        ]);
    }, [props.visible]);

    useEffect(() => {
        if (typeof props.email != 'undefined') {
            form.setFields([
                {
                    name: 'email',
                    value: props.email,
                },
            ]);
            setEmailDisabled(true);
        } else {
            setEmailDisabled(false);
        }
    }, [props.email]);

    return (
        <Modal
            title={props.edit ? 'Edytuj klienta' : 'Dodaj klienta'}
            cancelText="Anuluj"
            okText={props.edit ? 'Zapisz' : 'Dodaj'}
            onOk={() => {
                form.submit();
            }}
            okButtonProps={{ loading }}
            onCancel={() => props.hideModalFunction && props.hideModalFunction()}
            visible={props.visible}
        >
            <Form form={form} onFinish={formSubmit}>
                <InputContainer>
                    <Row gutter={[0, 6]}>
                        <Col span={24}>
                            <Label>Adres e-mail</Label>
                            <Form.Item
                                name="email"
                                help={null}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Musisz wprowadzić adres e-mail',
                                    },
                                ]}
                            >
                                <Input
                                    type="email"
                                    disabled={disabled || emailDisabled}
                                    placeholder="kowalski@mail.com"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Label>Imię i nazwisko</Label>
                            <Form.Item
                                name="fullNameAlias"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Musisz wprowadzić imię i nazwisko',
                                    },
                                ]}
                                help={null}
                            >
                                <Input placeholder="Jan Kowalski" disabled={disabled} />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Label>Numer telefonu</Label>
                            <Form.Item name="phoneNumber" rules={[]} help={null}>
                                <Input placeholder="700100100" disabled={disabled} />
                            </Form.Item>
                        </Col>
                        {props.new && (
                            <Col span={24}>
                                <Form.Item name="sendEmail" help={null}>
                                    <Checkbox.Group>
                                        <Checkbox value="true" disabled={disabled}>
                                            <CheckboxLabel>
                                                Czy wysłać klientowi e-maila z danymi do logowania?
                                            </CheckboxLabel>
                                        </Checkbox>
                                    </Checkbox.Group>
                                </Form.Item>
                            </Col>
                        )}
                    </Row>
                </InputContainer>
            </Form>
        </Modal>
    );
}
