import { useEffect, useState } from 'react';
import Breakpoints from '../constants/Breakpoints';
import { useWindowSize } from './useWindowSize';

export function useWindowBreakpoint() {
    const windowSize = useWindowSize();

    const [isMobile, setIsMobile] = useState(false);
    const [isTablet, setIsTablet] = useState(false);
    const [isDesktop, setIsDesktop] = useState(false);
    const [isLargeDesktop, setIsLargeDesktop] = useState(false);

    const [isLessEqTablet, setIsLessEqTablet] = useState(false);
    const [isLessEqDesktop, setIsLessEqDesktop] = useState(false);

    useEffect(() => {
        let isMobile = false,
            isTablet = false,
            isDesktop = false,
            isLargeDesktop = false,
            isLessEqTablet = false,
            isLessEqDesktop = false;

        if (Breakpoints.max.phone > windowSize.width) {
            isMobile = true;
            isLessEqDesktop = true;
            isLessEqTablet = true;
        } else if (Breakpoints.max.tablet > windowSize.width) {
            isTablet = true;
            isLessEqDesktop = true;
            isLessEqTablet = true;
        } else if (Breakpoints.max.desktops > windowSize.width) {
            isDesktop = true;
            isLessEqDesktop = true;
        } else {
            isLargeDesktop = true;
        }

        setIsMobile(isMobile);
        setIsTablet(isTablet);
        setIsDesktop(isDesktop);
        setIsLargeDesktop(isLargeDesktop);

        setIsLessEqTablet(isLessEqTablet);
        setIsLessEqDesktop(isLessEqDesktop);
    }, [windowSize]);

    return { isMobile, isTablet, isDesktop, isLargeDesktop, isLessEqTablet, isLessEqDesktop };
}
