import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import AppRoute from './App';
import Photographer from './Photographer';

export default function Views() {
    return (
        <Router>
            <Switch>
                <Route path="/app">
                    <AppRoute />
                </Route>
                <Route path="/user/:slug">
                    <Photographer />
                </Route>
                <Route path="/">
                    <Redirect to="/app" />
                </Route>
            </Switch>
        </Router>
    );
}
