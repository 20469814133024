import React, { CSSProperties } from 'react';
import styled from 'styled-components';
import Colors from '../../constants/Colors';

interface ContainerProps {
    color: string;
    padding: number;
}

const Container = styled.span<ContainerProps>`
    padding: 0px ${(props) => props.padding}px;
    background-image: linear-gradient(
        transparent 0%,
        transparent 50%,
        ${(props) => props.color} 50%,
        ${(props) => props.color} 90%,
        transparent 90%,
        transparent
    );
`;

interface UnderlinedTextProps {
    color?: string;
    padding?: number;
    style?: CSSProperties;
}

export default function UnderlinedText(props: React.PropsWithChildren<UnderlinedTextProps>) {
    const color = props.color ? props.color : Colors.accents.main[300];
    const padding = props.padding ? props.padding : 4;

    return (
        <Container style={props.style} color={color} padding={padding}>
            {props.children}
        </Container>
    );
}
