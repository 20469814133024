import { Empty, Skeleton, Spin, Table } from 'antd';
import React, { PropsWithChildren, useEffect, useState } from 'react';
import { Photoshoot, PhotoshootType } from '../../../../../interfaces/Photoshoot';
import moment from 'moment';
import { getDaysAndTimeLeft } from '../../../../../services/Date';
import Client from '../../../../../interfaces/Client';
import { Link, useHistory } from 'react-router-dom';
import { prototype } from 'events';
import Spinner from '../../../../../components/Spinner';

interface UpcomingPhotoshootsProps {
    photoshoots: Photoshoot[];
    loading?: boolean;
}

export default function UpcomingPhotoshoots(props: PropsWithChildren<UpcomingPhotoshootsProps>) {
    const history = useHistory();

    const columns = [
        {
            title: 'Kiedy',
            dataIndex: 'datetime',
            render: (date: any) => {
                return getDaysAndTimeLeft(date);
            },
        },
        {
            title: 'Rodzaj',
            dataIndex: 'type',
            render: (type: any) => {
                return type.name;
            },
        },
        {
            title: 'Klient',
            dataIndex: 'client',
            render: (client: any) => (
                <Link to={`/app/clients/${client._id}`}>{client.fullNameAlias || client.email}</Link>
            ),
        },
    ];

    if (props.loading) {
        return <Spinner />;
    }

    if (!props.photoshoots || props.photoshoots.length == 0) {
        return <Empty description="Brak sesji zdjęciowych" />;
    }

    return (
        <Table
            rowClassName="cursor-pointer"
            onRow={(photoshoot: Photoshoot) => ({
                onClick: (e: any) => {
                    if (e.target.nodeName == 'A') {
                        return;
                    }

                    history.push(`/app/photoshoots/${photoshoot._id}`);
                },
            })}
            size="small"
            columns={columns}
            dataSource={props.photoshoots}
        ></Table>
    );
}
