import { Button, Checkbox, Col, Input, Row, Table, TimePicker } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import moment from 'moment';
import React, { PropsWithChildren, useEffect, useState } from 'react';
import styled from 'styled-components';
import { capitalize } from '../../../../../../services/Strings';

interface AvailabilityCalendarSetupDayProps {
    day: any;
    updateDay: Function;
    cancel: Function;
}

const Label = styled.div`
    font-weight: 500;
    margin-bottom: 1em;
`;

const Description = styled.div`
    font-weight: 400;
    font-size: 0.8em;
`;

const SettingsContainer = styled.div`
    position: relative;
`;
const DisabledOverlay = styled.div<any>`
    position: absolute;
    top: 0;
    left: -8px;
    right: -8px;
    bottom: -8px;
    height: 100%;
    background: rgba(0, 0, 0, 0.03);
    z-index: 3;
    border-radius: 8px;
    display: ${(props) => (props.disabled ? 'block' : 'none')};
`;

const slotSize = 15;

export default function AvailabilityCalendarSetupDay(props: PropsWithChildren<AvailabilityCalendarSetupDayProps>) {
    const [day, setDay]: [any, Function] = useState(null);
    const [addLimitModalVisible, setAddLimitModalVisible] = useState(false);

    const [limitStartValue, setLimitStartValue]: [any, Function] = useState(null);
    const [limitEndValue, setLimitEndValue]: [any, Function] = useState(null);
    const [limitError, setLimitError] = useState('');

    useEffect(() => {
        setDay({ ...props.day });
    }, [props.day]);

    const handleOk = () => {
        props.updateDay(day);
    };

    const handleCancel = () => {
        props.cancel();
    };

    const handleDeleteLimit = (_id: any) => {
        let limits = day.limits.filter((limit: any) => limit._id != _id);
        setDay((day: any) => ({ ...day, limits }));
    };

    const handleAddLimitModal = () => {
        setAddLimitModalVisible(true);
    };

    const handleAddLimit = () => {
        setLimitStartValue(null);
        setLimitEndValue(null);

        if (limitStartValue === null || limitEndValue === null) {
            setLimitError('Musisz podać obie daty.');
            return;
        }

        if (limitError === '') {
            let limit = {
                _id: day.limits.length + 1,
                start: limitStartValue.format('HH:mm'),
                end: limitEndValue.format('HH:mm'),
            };

            setDay((day: any) => ({ ...day, limits: [...day.limits, limit] }));
            setAddLimitModalVisible(false);
        }
    };

    const limitsTableColumns = [
        {
            title: 'Godzina od',
            dataIndex: 'start',
            key: 'start',
        },
        {
            title: 'Godzina do',
            dataIndex: 'end',
            key: 'end',
        },
        {
            title: '',
            render: (data: any) => {
                return (
                    <Button onClick={() => handleDeleteLimit(data._id)} size="small" type="default" danger>
                        Usuń
                    </Button>
                );
            },
        },
    ];

    useEffect(() => {
        if (limitStartValue === null && limitEndValue === null) {
            return;
        }

        if (limitStartValue.isSameOrAfter(limitEndValue)) {
            setLimitError('Data początku musi być przed datą końca.');
            return;
        }

        setLimitError('');
    }, [limitStartValue, limitEndValue]);

    if (!props.day || typeof props.day === 'undefined' || props.day === null) {
        return <div />;
    }

    return (
        <Modal
            title={`${capitalize(moment(day.date)?.format('dddd'))}, ${moment(day.date)?.format('DD.MM.YYYY')}`}
            visible={true}
            onOk={handleOk}
            onCancel={handleCancel}
            okText="Potwierdź"
            cancelText="Anuluj"
        >
            <Row gutter={[12, 24]}>
                <Col span={24}>
                    <Checkbox
                        onChange={(enabled) => {
                            setDay((day: any) => ({ ...day, enabled: enabled.target.checked }));
                        }}
                        checked={day.enabled}
                    >
                        Dostępność w tym dniu
                    </Checkbox>
                </Col>
            </Row>
            <SettingsContainer>
                <Row gutter={[12, 24]}>
                    <Col span={12}>
                        <Label>Początek dnia</Label>
                        <TimePicker
                            onSelect={(time) => {
                                setDay((day: any) => ({ ...day, start: time?.format('HH:mm') }));
                            }}
                            value={moment(day.start, 'HH:mm')}
                            format={'HH:mm'}
                            minuteStep={slotSize}
                            allowClear={false}
                            showNow={false}
                            placeholder="Wybierz godzinę"
                        />
                    </Col>
                    <Col span={12}>
                        <Label>Koniec dnia</Label>
                        <TimePicker
                            onSelect={(time) => {
                                setDay((day: any) => ({ ...day, end: time?.format('HH:mm') }));
                            }}
                            value={moment(day.end, 'HH:mm')}
                            format={'HH:mm'}
                            minuteStep={slotSize}
                            allowClear={false}
                            showNow={false}
                            placeholder="Wybierz godzinę"
                        />
                    </Col>
                    <Col span={24}>
                        <Label style={{ marginBottom: 0 }}>Ograniczenia dostępności</Label>
                        <Description style={{ marginBottom: '1.5em' }}>
                            Możesz sprecyzować w jakich godzinach tego dnia jesteś niedostępny/a - w tym okresie nie
                            będzie możliwości rezerwowania terminów.
                        </Description>
                        <Button onClick={handleAddLimitModal} style={{ marginBottom: '2em' }} size="small">
                            Dodaj
                        </Button>
                        <Table pagination={false} columns={limitsTableColumns} dataSource={day.limits}></Table>
                    </Col>
                </Row>
                <DisabledOverlay disabled={!day.enabled} />
            </SettingsContainer>

            <Modal
                okText="Dodaj"
                cancelText="Anuluj"
                onOk={handleAddLimit}
                onCancel={() => setAddLimitModalVisible(false)}
                title="Dodaj limit dostępności"
                centered={true}
                style={{ maxWidth: '300px' }}
                visible={addLimitModalVisible}
            >
                <Row gutter={[12, 12]}>
                    <Col span={12}>
                        <Label>Początek</Label>
                        <TimePicker
                            onSelect={(time) => {
                                setLimitStartValue(time);
                            }}
                            format={'HH:mm'}
                            minuteStep={slotSize}
                            allowClear={false}
                            value={limitStartValue}
                            showNow={false}
                            placeholder="Wybierz godzinę"
                        />
                    </Col>
                    <Col span={12}>
                        <Label>Koniec</Label>
                        <TimePicker
                            onSelect={(time) => {
                                setLimitEndValue(time);
                            }}
                            format={'HH:mm'}
                            minuteStep={slotSize}
                            allowClear={false}
                            value={limitEndValue}
                            showNow={false}
                            placeholder="Wybierz godzinę"
                        />
                    </Col>
                    <Col span={24}>{limitError && limitError}</Col>
                </Row>
            </Modal>
        </Modal>
    );
}
