import { Day } from './Calendar';
import Client from './Client';
import User from './User';

export interface PhotoshootType {
    _id: string;
    name: string;
    duration: number;
    description: string;
}

export interface PhotoshootPhoto {
    _id: string;
    path: string;
    originalName: string;
    width: number;
    height: number;
    exif: object;
    thumbnail?: PhotoshootPhoto;
}

export interface Photoshoot extends Document {
    _id: string;
    user: User | string;
    client: Client | string;
    datetime: Date;
    status: PhotoshootStatus;
    confirmationToken?: string;
    createdAt: Date;
    deletedAt: Date;
    deleted: boolean;
    type: PhotoshootType;
    photos: PhotoshootPhoto[];
}

export enum PhotoshootStatus {
    AWAITING_CLIENT_CONFIRMATION = 1,
    AWAITING_USER_CONFIRMATION = 2,
    CONFIRMED = 3,
    REJECTED = 4,
    CANCELED = 5,
    FINISHED = 7,
}

export const translatePhotoshootStatus = (status: PhotoshootStatus, plural = false) => {
    if (!plural) {
        switch (status) {
            case PhotoshootStatus.AWAITING_USER_CONFIRMATION:
            case PhotoshootStatus.AWAITING_CLIENT_CONFIRMATION:
                return 'oczekująca potwierdzenia';
            case PhotoshootStatus.CONFIRMED:
                return 'potwierdzona';
            case PhotoshootStatus.REJECTED:
                return 'odrzucona';
            case PhotoshootStatus.CANCELED:
                return 'anulowana';
            case PhotoshootStatus.FINISHED:
                return 'zakończona';
        }
    }
};
