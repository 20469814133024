import React, { PropsWithChildren, useEffect, useState } from 'react';
import styled from 'styled-components';
import Colors from '../../../../../constants/Colors';
//@ts-ignore
import * as contrast from 'contrast';
import { Conversation, Message, MessageStatus } from '../../../../../interfaces/Conversation';
import Client from '../../../../../interfaces/Client';
import moment from 'antd/node_modules/moment';
import { useSelector } from 'react-redux';

const Container = styled.div<any>`
    display: flex;
    width: 100%;
    padding: 1.5em 1em;
    padding-top: 1em;
    flex-direction: column;
    border-bottom: 1px solid ${Colors.greys[200]};
    cursor: pointer;
    transition: 0.1s all;
    background-color: ${(props) => (props.selected ? Colors.greys[150] : 'transparent')};

    &:hover {
        background-color: ${Colors.greys[150]};
    }
`;

const DateContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    color: ${Colors.greys[700]};
    font-size: 0.8em;
`;

const LeftPanelContainer = styled.div`
    height: 100%;
    margin-right: 1em;
`;

const RightPanelContainer = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
`;

const Avatar = styled.div<any>`
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background: ${(props) => props.color};
    color: ${(props) => {
        if (contrast(props.color) === 'light') return '#000';
        return '#fff';
    }};

    font-weight: 600;
    justify-content: center;
    align-items: center;
    display: flex;
    text-transform: uppercase;
`;

const ContentContainer = styled.div`
    display: flex;
    flex-direction: row;
`;

const Email = styled.div<any>`
    font-weight: ${(props) => (props.read ? 400 : 800)};
    word-break: break-all;
`;

const MessageContainer = styled.div<any>`
    font-weight: ${(props) => (props.read ? 400 : 700)};
    font-size: 0.8em;
    color: ${Colors.greys[800]};
`;

interface ConversationThumbProps {
    conversation: Conversation;
    onClick: Function;
    selected: boolean;
}

export default function ConversationThumb(props: PropsWithChildren<ConversationThumbProps>) {
    const [lastMessage, setLastMessage]: [Message, Function] = useState({} as Message);
    const [read, setRead] = useState(false);

    const conversations = useSelector((state: any) => state.lastMessage);
    const unreadConversations = useSelector((state: any) => state.unreadConversations);

    useEffect(() => {
        if (props.conversation && conversations.length > 0) {
            let conversation = conversations.find((c: any) => c.conversation == props.conversation._id);

            if (typeof conversation != 'undefined') {
                setLastMessage(conversation.message);
            }
        }
    }, [conversations]);

    useEffect(() => {
        if (unreadConversations.includes(props.conversation._id)) {
            setRead(false);
        } else {
            setRead(true);
        }
    }, [unreadConversations]);

    if (!props.conversation) return <div />;

    return (
        <Container selected={props.selected} onClick={props.onClick}>
            <DateContainer>{moment(lastMessage.createdAt).format('HH:mm')}</DateContainer>
            <ContentContainer>
                <LeftPanelContainer>
                    <Avatar color={(props.conversation.client as Client).color}>
                        {(props.conversation.client as Client).confirmedByUser
                            ? (props.conversation.client as Client).fullNameAlias.substring(0, 1)
                            : (props.conversation.client as Client).email.substring(0, 1)}
                    </Avatar>
                </LeftPanelContainer>
                <RightPanelContainer>
                    <Email read={read}>
                        {(props.conversation.client as Client).confirmedByUser
                            ? (props.conversation.client as Client).fullNameAlias
                            : (props.conversation.client as Client).email}
                    </Email>
                    {lastMessage && typeof lastMessage.sender != 'undefined' && (
                        <MessageContainer read={read}>
                            {lastMessage.sender === 'user' && 'Ty: '}
                            {lastMessage.message.substring(0, 255)}
                            {lastMessage.message.length > 255 && '...'}
                        </MessageContainer>
                    )}
                </RightPanelContainer>
            </ContentContainer>
        </Container>
    );
}
