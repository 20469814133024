import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useGlobalState } from '../../state';
import Indicator from './indicator';

const Loading = styled.div<any>`
    z-index: 999999;
    //@ts-ignore
    background: ${(props) => `rgba(255, 255, 255, ${props.opacity})`};
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    //@ts-ignore
    visibility: ${(props) => `${props.visible ? 'visible' : 'hidden'}`};
    display: flex;
    justify-content: center;
    align-items: center;

    transition: 0.5s all;
`;

export default function AppLoading() {
    const [isAppLoading] = useGlobalState('appLoading');
    const [opacity, setOpacity] = useState(0);
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        let timeout: number;
        let visibilityTimeout: number;

        if (isAppLoading) {
            timeout = setTimeout(() => {
                setOpacity(0.7);
                setVisible(true);
            }, 200);
        } else {
            timeout = setTimeout(() => {
                setOpacity(0);

                visibilityTimeout = setTimeout(() => {
                    setVisible(false);
                }, 50);
            }, 200);
        }

        return () => {
            setOpacity(0);
            clearTimeout(timeout);
            clearTimeout(visibilityTimeout);
        };
    }, [isAppLoading]);

    return (
        <div>
            {
                //@ts-ignore
                <Loading opacity={opacity} visible={visible}>
                    <Indicator visibility={opacity > 0 ? true : false} />
                </Loading>
            }
        </div>
    );
}
