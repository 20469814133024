import { Col, Modal, Row, Space } from 'antd';
import { Moment } from 'moment';
import React, { PropsWithChildren, useEffect, useState } from 'react';
import Label from '../../../../../components/Label';
import { Day } from '../../../../../interfaces/Calendar';
import get from '../../../../../services/Requests/get';
import { useGlobalState } from '../../../../../state';
import SimpleTheme_InputComponent from './Input';
import SimpleTheme_SelectComponent from './Select';
import styled from 'styled-components';
import SimpleTheme_ButtonComponent from './Button';
import moment from 'moment';
import { capitalize } from '../../../../../services/Strings';
import { getTimeLeft } from '../../../../../services/Weeks';

const Description = styled.span``;
const slotSize = 15;

const Container = styled.div`
    position: relative;
`;

const ErrorMessage = styled.span`
    color: red;
`;
const SuccessMessage = styled.span`
    color: green;
`;
const DisabledOverlay = styled.div<any>`
    position: absolute;
    top: 0;
    left: -8px;
    right: -8px;
    bottom: -8px;
    height: 100%;
    background: rgba(0, 0, 0, 0.03);
    z-index: 3;
    border-radius: 8px;
    display: ${(props) => (props.disabled ? 'block' : 'none')};
`;

export default function SimpleTheme_BookModalComponent(props: PropsWithChildren<any>) {
    const [appLoading, setAppLoading] = useGlobalState('appLoading');
    const [portfolio] = useGlobalState('portfolio');
    const [calendarSettings] = useGlobalState('calendarSettings');

    const { day, time, visible }: { day: Day; time: string; visible: boolean } = props;

    const [disabled, setDisabled] = useState(false);

    const [photoshootTypesList, setPhotoshootTypesList] = useState([]);
    const [photoshootTypes, setPhotoshootTypes] = useState([]);

    const [photoshootType, setPhotoshootType] = useState();
    const [photoshootTypeDescription, setPhotoshootTypeDescription] = useState(null);

    const [email, setEmail] = useState(null);

    const [error, setError] = useState('');

    useEffect(() => {
        (async () => {
            try {
                setAppLoading(true);
                let response = await get(`photoshoots/types/${portfolio.slug}`, { useToken: false });

                if (response.success) {
                    response = response as Response;
                    setPhotoshootTypesList(response.data);
                    setAppLoading(false);
                }
            } catch (err) {
                // TODO: handle error
            }
        })();
    }, []);

    useEffect(() => {
        setPhotoshootType(undefined);
        setEmail(null);
        setError('');
        setPhotoshootTypeDescription(null);

        if (typeof day != 'undefined' && typeof calendarSettings != 'undefined') {
            if (calendarSettings.listLongerPhotoshoots === false) {
                let timeLeft = getTimeLeft(slotSize, day, moment(time, 'HH:mm'));
                setPhotoshootTypes(photoshootTypesList.filter((a: any) => a.duration <= timeLeft));
            } else {
                setPhotoshootTypes(photoshootTypesList);
            }
        }
    }, [day, time]);

    useEffect(() => {
        let photoshootTypeObject = photoshootTypes.find((el: any) => el._id == photoshootType);
        if (typeof photoshootTypeObject != 'undefined') {
            //@ts-ignore
            setPhotoshootTypeDescription(photoshootTypeObject.description);
        }
    }, [photoshootType]);

    useEffect(() => {
        setError(props.error);
    }, [props.error]);

    useEffect(() => {
        if (props.success != '') {
            setDisabled(true);
        }
    }, [props.success]);

    const handlePhotoshootTypeChange = (_id: any) => {
        setPhotoshootType(_id);
    };
    const handleEmailChange = (email: any) => {
        setEmail(email);
    };

    const handleOk = () => {
        setError('');

        //validation
        if (!photoshootType || !email) {
            setError('Musisz wypełnić wszystkie pola!');
            return;
        }

        props.handleOk({ photoshootType, email });
    };

    //@t
    if (typeof day == 'undefined' || typeof time == 'undefined') {
        return <div />;
    }

    return (
        <Modal
            footer={
                <Space>
                    <SimpleTheme_ButtonComponent disabled={disabled} onClick={props.handleCancel}>
                        Anuluj
                    </SimpleTheme_ButtonComponent>
                    <SimpleTheme_ButtonComponent disabled={disabled} onClick={handleOk}>
                        Rezerwuj
                    </SimpleTheme_ButtonComponent>
                </Space>
            }
            title="Rezerwacja terminu sesji"
            onCancel={props.handleCancel}
            visible={visible}
        >
            <Container>
                <Row gutter={[12, 24]}>
                    <Col span={24}>
                        <Label description="Jesteś w trakcie rezerwacji terminu sesji zdjęciowej. Po ukończeniu procesu rezerwacji, otrzymasz wiadomość e-mail z linkiem potwierdzającym rezerwację.">
                            {capitalize(moment(day.date).format('dddd DD.MM.YYYY'))}, {time}
                        </Label>
                    </Col>
                    <Col span={24}>
                        <Label>Wybierz rodzaj sesji</Label>
                        <SimpleTheme_SelectComponent
                            placeholder="Rodzaj sesji"
                            value={photoshootType}
                            name="photoshootTypes"
                            onChange={(el: any) => handlePhotoshootTypeChange(el.value)}
                            width="100%"
                            options={photoshootTypes.map((photoshootType: any, index: any) => ({
                                value: photoshootType._id,
                                text: photoshootType.name,
                            }))}
                        />
                    </Col>
                    {photoshootTypeDescription != null && (
                        <Col style={{ paddingTop: 0 }} span={24}>
                            <Label>Opis</Label>
                            <Description>{photoshootTypeDescription}</Description>
                        </Col>
                    )}
                    <Col span={24}>
                        <Label>Adres e-mail</Label>
                        <SimpleTheme_InputComponent
                            onChange={handleEmailChange}
                            placeholder="kowalski@mail.com"
                            name="email"
                        />
                    </Col>
                </Row>
                <DisabledOverlay disabled={disabled} />
            </Container>
            <Row gutter={[12, 24]}>
                {error != '' && (
                    <Col span={24}>
                        <ErrorMessage>{error}</ErrorMessage>
                    </Col>
                )}
                {props.success != '' && (
                    <Col span={24}>
                        <SuccessMessage>{props.success}</SuccessMessage>
                    </Col>
                )}
            </Row>
        </Modal>
    );
}
