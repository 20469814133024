import React, { PropsWithChildren, useState, useEffect } from 'react';
import styled from 'styled-components';
import Colors from '../../../../../constants/Colors';
import moment from 'moment';
import 'moment/locale/pl';
moment.locale('pl');

const Container = styled.div<any>`
    max-width: 80%;
    margin-bottom: 0.5em;

    align-self: ${(props) =>
        (props.sender === 'client' && props.client) || (props.sender === 'user' && !props.client)
            ? 'flex-end'
            : 'flex-start'};
`;

const MessageContainer = styled.div<any>`
    background-color: ${(props) => props.background || Colors.accents.main[100]};
    border-radius: 8px;
    padding: 0.7em 1em;
`;

const DateContainer = styled.div<any>`
    margin-top: 1em;
    margin-bottom: 1em;
    font-size: 0.7em;

    align-self: ${(props) => (props.sender === 'user' ? 'flex-end' : 'flex-start')};
`;

interface MessageProps {
    message: any;
    previousMessage?: any;
    background?: any;
    client?: boolean;
}

export default function Message(props: PropsWithChildren<MessageProps>) {
    const [date, setDate] = useState(null) as any;

    useEffect(() => {
        if (!props.previousMessage) {
            setDate(moment(props.message.createdAt).format('D MMMM HH:mm'));
            return;
        }

        if (moment(props.message.createdAt).diff(moment(props.previousMessage.createdAt)) > 1000 * 60) {
            if (!moment(props.message.createdAt).isSame(moment(props.previousMessage.createdAt), 'day')) {
                setDate(moment(props.message.createdAt).format('D MMMM HH:mm'));
                return;
            }

            setDate(moment(props.message.createdAt).format('HH:mm'));
            return;
        }
    }, []);

    return (
        <Container client={props.client || false} sender={props.message.sender}>
            {date && <DateContainer sender={props.message.sender}>{date}</DateContainer>}
            <MessageContainer background={props.background || null}>{props.message.message}</MessageContainer>
        </Container>
    );
}
