import React, { useState } from 'react';
import Heading from '../../../../../../components/Heading';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import Client_PhotoshootsList from './List';
import Client_PhotoshootsPhotoshoot from './Photoshoot';

export default function Client_Photoshoots() {
    const match = useRouteMatch();

    return (
        <div>
            <div style={{ marginTop: 24 }}>
                <Switch>
                    <Route path={`${match.url}/:id`}>
                        <Client_PhotoshootsPhotoshoot />
                    </Route>
                    <Route path={`${match.url}`}>
                        <Client_PhotoshootsList />
                    </Route>
                </Switch>
            </div>
        </div>
    );
}
