import React, { useState } from 'react';
import styled from 'styled-components';
import Colors from '../../../constants/Colors';
import LogoImage from '../../../resources/images/logo-black_transparent.png';
import LogoColorImage from '../../../resources/images/logo-blue_transparent.png';
import Heading from '../../../components/Heading';
import { Input, Button, Form, Row, Col, Alert, message } from 'antd';

import Shape_1 from '../../../domain/App/Register/resources/images/shape_1.png';
import Shape_2 from '../../../domain/App/Register/resources/images/shape_2.png';
import Breakpoints from '../../../constants/Breakpoints';

import { Link } from 'react-router-dom';
import BackgroundElement from '../../../components/BackgroundElement';
import { post } from '../../../services/Requests';
import BadRequestException from '../../../interfaces/BadRequestException';

const { useForm } = Form;

const Container = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
`;

const LeftPanel = styled.div`
    width: 40%;
    height: 100%;
    background: url('https://images.pexels.com/photos/305086/pexels-photo-305086.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940');
    background-position: 100% 0;
    background-repeat: no-repeat;
    background-size: cover;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    position: relative;

    &::after {
        background-color: ${Colors.accents.main[500]};
        content: '';
        display: block;
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        z-index: 1;
        opacity: 0.9;
    }

    @media (max-width: ${Breakpoints.max.desktops}px) {
        width: 40%;
    }

    @media (max-width: ${Breakpoints.max.tablet}px) {
        display: none;
    }
`;

const RightPanel = styled.div`
    width: 60%;
    height: 100%;
    background: #fff;
    box-sizing: border-box;
    align-items: center;
    position: relative;

    @media (max-width: ${Breakpoints.max.desktops}px) {
        width: 60%;
    }

    @media (max-width: ${Breakpoints.max.tablet}px) {
        width: 100%;
    }

    @media (min-width: ${Breakpoints.min.tablet}px) {
        display: flex;
    }
`;

const LogoContainer = styled.div`
    width: 100%;
    padding: 32px;
    z-index: 5;
    position: relative;
`;

const LogoRightPanelContainer = styled.div`
    width: 100%;
    padding: 32px;
    z-index: 5;
    position: relative;

    @media (min-width: ${Breakpoints.min.tablet}px) {
        display: none;
    }
`;

const Logo = styled.img`
    width: 90px;
`;

const FormContainer = styled(Form)`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 5rem;
    position: relative;
    z-index: 5;

    @media (max-width: ${Breakpoints.max.desktops}px) {
        padding: 0 2.5rem;
    }
`;

const InputsContainer = styled.div`
    margin: 2rem 0;
`;

const ButtonContainer = styled.div`
    margin-bottom: 2.5rem;
`;

const CatchPhraseContainer = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 3rem;
    font-weight: 500;
    font-family: 'Roboto';
    color: black;
    padding: 0 3rem;
    text-align: right;
    line-height: 3.7rem;
`;

const FormLabel = styled.span`
    font-family: 'Roboto';
    font-size: 16px;
    font-weight: 600;
`;

export default function RegisterView() {
    const [form] = useForm();
    const [loading, setLoading] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    const formSubmit = async (values: any) => {
        setLoading(true);
        setDisabled(true);
        setSuccess('');
        setError('');

        try {
            let response = await post('auth/register', values, { useToken: false });

            if (!response.success) {
                response = response as BadRequestException;

                if (typeof response.message === 'string') {
                    setError(response.message as string);
                }

                setLoading(false);
                setDisabled(false);

                return;
            }

            setSuccess(
                'Na podany adres e-mail został wysłany link aktywacyjny. ' +
                    'Potwierdź swoje konto w ciągu 24 godzin i zacznij korzystać z aplikacji!',
            );
            setLoading(false);
        } catch (err) {
            message.error('Wystąpił błąd podczas próby rejestracji. Spróbuj ponownie później.');
        }
    };

    return (
        <Container>
            <LeftPanel>
                <LogoContainer>
                    <Logo alt="Logo captic" src={LogoImage} />
                </LogoContainer>
                <CatchPhraseContainer></CatchPhraseContainer>
            </LeftPanel>
            <RightPanel>
                <BackgroundElement left={0} bottom={150}>
                    <img alt="Kształt" src={Shape_1} />
                </BackgroundElement>
                <BackgroundElement right={100} top={0}>
                    <img alt="Kształt" src={Shape_2} />
                </BackgroundElement>
                <LogoRightPanelContainer>
                    <Logo alt="Logo captic" src={LogoColorImage} />
                </LogoRightPanelContainer>
                <FormContainer hideRequiredMark={true} layout="vertical" form={form} onFinish={formSubmit}>
                    <Heading>Załóż konto</Heading>
                    {error !== '' && <Alert type="error" message={error} banner />}
                    {success !== '' && <Alert type="success" message={success} banner />}
                    <InputsContainer>
                        <Row gutter={[24, 12]}>
                            <Col xs={24} md={12} span={12}>
                                <Form.Item
                                    label={<FormLabel>Imię</FormLabel>}
                                    name="firstName"
                                    help={null}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Musisz podać imię.',
                                        },
                                    ]}
                                >
                                    <Input disabled={disabled} placeholder="Jan" />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12} span={12}>
                                <Form.Item
                                    label={<FormLabel>Nazwisko</FormLabel>}
                                    name="lastName"
                                    help={null}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Musisz podać nazwisko.',
                                        },
                                    ]}
                                >
                                    <Input disabled={disabled} placeholder="Kowalski" />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    label={<FormLabel>Adres e-mail</FormLabel>}
                                    name="email"
                                    validateTrigger="onSubmit"
                                    help={null}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Musisz podać adres e-mail.',
                                        },
                                        {
                                            type: 'email',
                                            message: 'Podano niepoprawny adres e-mail.',
                                        },
                                    ]}
                                >
                                    <Input disabled={disabled} placeholder="przyklad@email.pl" />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    label={<FormLabel>Hasło</FormLabel>}
                                    name="password"
                                    validateTrigger="onSubmit"
                                    help={null}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Musisz podać hasło.',
                                        },
                                        {
                                            min: 8,
                                            message: 'Hasło musi składać się z conajmniej 8 znaków.',
                                        },
                                        {
                                            max: 128,
                                            message: 'Hasło może mieć maksymalnie 128 znaków.',
                                        },
                                    ]}
                                >
                                    <Input disabled={disabled} type="password" placeholder="Hasło" />
                                </Form.Item>
                            </Col>
                        </Row>
                    </InputsContainer>
                    <ButtonContainer>
                        <Button
                            loading={loading}
                            disabled={!loading && disabled}
                            htmlType="submit"
                            type="primary"
                            size="large"
                            block={false}
                        >
                            Załóż konto
                        </Button>
                    </ButtonContainer>
                    <span>
                        Masz już konto? <Link to="/app/login">Zaloguj się.</Link>
                    </span>
                </FormContainer>
            </RightPanel>
        </Container>
    );
}
