import React, { useEffect, useState } from 'react';
import { useGlobalState, defaultState } from '../../../../state';
import styled from 'styled-components';
import Colors from '../../../../constants/Colors';
import MessagesSearchBar from '../../../../domain/App/App/components/MessagesSearchBar';
import moment from 'moment';
import ConversationThumb from '../../../../domain/App/App/components/ConversationThumb';
import Conversation from '../../../../domain/App/App/components/Conversation';
import { message } from 'antd';
import { get } from '../../../../services/Requests';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Breakpoints from '../../../../constants/Breakpoints';
import ConversationThumbSmall from '../../../../domain/App/App/components/ConversationThumbSmall';
import Heading from '../../../../components/Heading';
import { useSocket } from 'use-socketio';

const Container = styled.div`
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: row;

    @media (max-width: ${Breakpoints.max.desktops}px) {
        flex-direction: column;
    }
`;

const ConversationsContainer = styled.div`
    width: 25%;
    border-right: 2px solid ${Colors.greys[200]};
    padding: 3em 0em;
    overflow: auto;

    @media (max-width: ${Breakpoints.max.desktops}px) {
        display: none;
    }
`;

const ConversationsSmall = styled.div`
    display: flex;
    padding: 2em;
    padding-bottom: 0;
    overflow-x: auto;

    @media (min-width: ${Breakpoints.min.desktops}px) {
        display: none;
    }
`;

const ConversationsCounter = styled.span`
    color: ${Colors.greys[700]};
    font-weight: 700;
    margin-left: 0.8em;
`;

const MessagesContainer = styled.div`
    width: 75%;

    @media (max-width: ${Breakpoints.max.desktops}px) {
        width: 100%;
        flex: 1;
    }
`;

const SearchBarContainer = styled.div`
    padding: 0 1em;
`;

const ConversationsSeparator = styled.div`
    margin-top: 2em;
    padding: 0.8em 2em;
    background: ${Colors.greys[200]};
    font-size: 0.7em;
    text-transform: uppercase;
    font-weight: 500;
`;

export default function AppMessages() {
    const [appLoading, setAppLoading] = useGlobalState('appLoading');

    // Hide main search bar
    const [searchBarVisible, setSearchBarVisible] = useGlobalState('searchBarVisible');
    const [mainPanelPadding, setMainPanelPadding] = useGlobalState('mainPanelPadding');
    useEffect(() => {
        setSearchBarVisible(false);
        setMainPanelPadding('0');

        return () => {
            setSearchBarVisible(true);
            setMainPanelPadding(defaultState.mainPanelPadding);
        };
    }, []);

    const { socket, subscribe, unsubscribe } = useSocket('newConversation', (data) => {
        setConversations([data, ...conversations]);
    });

    const [selectedConversation, setSelectedConversation] = useState(null) as any;
    const [conversations, setConversations] = useState([]) as any;

    const selectConversation = (conversation: any) => {
        setSelectedConversation(conversation);
        history.push(`/app/messages?client=${conversation.client._id}`);
    };

    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        for (let conversation of conversations) {
            dispatch({
                type: 'UPDATE_LAST_MESSAGE',
                payload: {
                    conversation: conversation._id,
                    message: conversation.messages[0],
                },
            });
        }
    }, [conversations]);

    useEffect(() => {
        setAppLoading(true);
        (async () => {
            try {
                let response = await get(`conversations`);
                if (response.success) {
                    setConversations(
                        response.data.sort((a: any, b: any) => {
                            if (a.messages.length == 0) {
                                return b;
                            }

                            if (b.messages.length == 0) {
                                return a;
                            }

                            //@ts-ignore
                            return new Date(b.messages[0].updatedAt) - new Date(a.messages[0].updatedAt);
                        }),
                    );

                    if (response.data.length > 0) {
                        // Set the conversation if client_id passed
                        try {
                            const queryParams = new URLSearchParams(history.location.search);
                            if (queryParams.get('client') != null) {
                                let conversation = response.data.find(
                                    (c: any) => c.client._id == queryParams.get('client'),
                                );

                                if (typeof conversation !== 'undefined') {
                                    setSelectedConversation(conversation);
                                } else {
                                    let response = await get(`clients/${queryParams.get('client')}`);
                                    if (response.success) {
                                        setSelectedConversation({
                                            new: true,
                                            client: response.data,
                                            messages: [],
                                        });
                                    }
                                }
                            } else {
                                setSelectedConversation(response.data[0]);
                            }
                        } catch (err) {
                            setSelectedConversation(response.data[0]);
                        }
                    }
                    setAppLoading(false);
                }
            } catch (err) {
                message.error(err.message);
            }
        })();
    }, []);

    return (
        <Container>
            <ConversationsContainer>
                <SearchBarContainer>
                    <MessagesSearchBar />
                </SearchBarContainer>
                <ConversationsSeparator>
                    Wszystkie wiadomości <ConversationsCounter>{conversations.length}</ConversationsCounter>
                </ConversationsSeparator>
                {conversations.map((conversation: any, index: number) => (
                    <ConversationThumb
                        selected={selectedConversation && conversation._id === selectedConversation._id}
                        onClick={() => selectConversation(conversation)}
                        key={`conversation-${index}`}
                        conversation={conversation}
                    />
                ))}
            </ConversationsContainer>
            <ConversationsSmall>
                {conversations.length == 0 && <Heading size="small">Brak konwersacji</Heading>}
                {conversations.map((conversation: any, index: number) => (
                    <ConversationThumbSmall
                        selected={selectedConversation && conversation._id === selectedConversation._id}
                        onClick={() => selectConversation(conversation)}
                        key={`conversation-${index}`}
                        conversation={conversation}
                    />
                ))}
            </ConversationsSmall>
            <MessagesContainer>
                <Conversation
                    addConversation={(conversation: any) =>
                        setConversations((conversations: any) => [conversation, ...conversations])
                    }
                    updateConversationFunction={(conversation: any) => setSelectedConversation(conversation)}
                    conversation={selectedConversation}
                ></Conversation>
            </MessagesContainer>
        </Container>
    );
}
