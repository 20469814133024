import React, { useEffect, useRef, useState } from 'react';
import Heading from '../../../../../components/Heading';
import styled from 'styled-components';
import { useGlobalState } from '../../../../../state';
import { Tabs, Button, Badge, Table, message, Popover, Space, Popconfirm } from 'antd';
import Colors from '../../../../../constants/Colors';
import Client from '../../../../../interfaces/Client';
import { Photoshoot, PhotoshootStatus } from '../../../../../interfaces/Photoshoot';
import { get, post } from '../../../../../services/Requests';
import moment from 'moment';
import { Day } from '../../../../../interfaces/Calendar';
import { Link, useHistory } from 'react-router-dom';

const Container = styled.div`
    margin-top: -1em;
`;

const Content = styled.div`
    margin-top: 2em;
`;

export default function PhotoshootsList() {
    const { TabPane } = Tabs;
    const [activeTab, setActiveTab]: [string, Function] = useState('photoshoots-list-awaiting');

    const [photoshootsAwaitingConfirmation, setPhotoshootsAwaitingConfirmation] = useGlobalState(
        'photoshootsAwaitingConfirmation',
    );
    const [appLoading, setAppLoading] = useGlobalState('appLoading');
    const history = useHistory();

    const [tableLoading, setTableLoading] = useState(false);
    const [user] = useGlobalState('user');
    const [photoshoots, setPhotoshoots] = useState([]);

    useEffect(() => {
        loadPhotoshoots();
    }, []);

    const loadPhotoshoots = async () => {
        setTableLoading(true);
        try {
            let response = await get(`photoshoots`);

            if (!response.success) {
                message.error('Nie udało się załadować sesji. Spróbuj ponownie później.');
                return;
            }

            setPhotoshoots(response.data);
            setTableLoading(false);
        } catch (err) {
            message.error('Wystąpił błąd podczas ładowania sesji.');
        }
    };

    const onTabChange = (activeTab: string) => {
        setActiveTab(activeTab);
    };

    const handleConfirm = async (id: any, status: any) => {
        setAppLoading(true);
        try {
            let response = await post(`photoshoots/${id}/confirm`);
            if (!response.success) {
                let error = response.message;
                message.error(`Wystąpił błąd podczas potwierdzania sesji: ${error}`);
                setAppLoading(false);
                return;
            }

            message.success('Pomyślnie potwierdzono sesję.');
            setAppLoading(false);
            if (status == PhotoshootStatus.AWAITING_USER_CONFIRMATION) {
                setPhotoshootsAwaitingConfirmation((photoshoots) => photoshoots - 1);
            }

            loadPhotoshoots();
        } catch (err) {
            message.error(`Wystąpił błąd podczas potwierdzania sesji. Spróbuj ponownie później.`);
            setAppLoading(false);
        }
    };

    const handleReject = async (id: any, status: any) => {
        setAppLoading(true);
        try {
            let response = await post(`photoshoots/${id}/reject`);
            if (!response.success) {
                let error = response.message;
                message.error(`Wystąpił błąd podczas odrzucania sesji: ${error}`);
                setAppLoading(false);
                return;
            }

            message.success('Pomyślnie odrzucono sesję.');
            setAppLoading(false);
            if (status == PhotoshootStatus.AWAITING_USER_CONFIRMATION) {
                setPhotoshootsAwaitingConfirmation((photoshoots) => photoshoots - 1);
            }

            loadPhotoshoots();
        } catch (err) {
            message.error(`Wystąpił błąd podczas odrzucania sesji. Spróbuj ponownie później.`);
            setAppLoading(false);
        }
    };

    const handleCancel = async (id: any) => {
        setAppLoading(true);
        try {
            let response = await post(`photoshoots/${id}/cancel`);
            if (!response.success) {
                let error = response.message;
                message.error(`Wystąpił błąd podczas anulowania sesji: ${error}`);
                setAppLoading(false);
                return;
            }

            message.success('Pomyślnie anulowano sesję.');
            setAppLoading(false);

            loadPhotoshoots();
        } catch (err) {
            message.error(`Wystąpił błąd podczas anulowania sesji. Spróbuj ponownie później.`);
            setAppLoading(false);
        }
    };

    const PhotoshootTable = (props: any) => {
        let buttons: any[] = [];

        if (props.status == PhotoshootStatus.AWAITING_USER_CONFIRMATION) {
            buttons = [
                ...buttons,
                (record: any) => (
                    <Popconfirm
                        cancelText="Anuluj"
                        okType="danger"
                        okText="Odrzuć"
                        title="Czy jesteś pewny/a, że chcesz odrzucić tą sesję zdjęciową?"
                        onConfirm={() => handleReject(record._id, record.status)}
                    >
                        <Button danger size="small">
                            Odrzuć
                        </Button>
                    </Popconfirm>
                ),
            ];
        }

        if (props.status == PhotoshootStatus.AWAITING_USER_CONFIRMATION || props.status == PhotoshootStatus.REJECTED) {
            buttons = [
                ...buttons,
                (record: any) => (
                    <Popconfirm
                        cancelText="Anuluj"
                        okText="Potwierdź"
                        title="Czy jesteś pewny/a, że chcesz potwierdzić tą sesję zdjęciową?"
                        onConfirm={() => handleConfirm(record._id, record.status)}
                    >
                        <Button size="small">Potwierdź</Button>
                    </Popconfirm>
                ),
            ];
        }

        if (props.status == PhotoshootStatus.CONFIRMED) {
            buttons = [
                ...buttons,
                (record: any) => (
                    <Popconfirm
                        cancelText="Nie"
                        okType="danger"
                        okText="Tak"
                        title="Czy jesteś pewny/a, że chcesz anulować tą sesję zdjęciową?"
                        onConfirm={() => handleCancel(record._id)}
                    >
                        <Button danger size="small">
                            Anuluj
                        </Button>
                    </Popconfirm>
                ),
            ];
        }

        if (props.status == PhotoshootStatus.CANCELED) {
            buttons = [
                ...buttons,
                (record: any) => (
                    <Popconfirm
                        cancelText="Anuluj"
                        okText="Przywróć"
                        title="Czy jesteś pewny/a, że chcesz przywrócić tą sesję zdjęciową?"
                        onConfirm={() => handleConfirm(record._id, record.status)}
                    >
                        <Button size="small">Przywróć</Button>
                    </Popconfirm>
                ),
            ];
        }

        const columns: any[] = [
            {
                title: 'Data',
                sort: true,
                render: (data: any, record: Photoshoot) => {
                    return `${moment(record.datetime).format('YYYY-MM-DD (dddd)')}, ${moment(record.datetime).format(
                        'HH:mm',
                    )}`;
                },
            },
            {
                title: 'Rodzaj sesji',
                render: (data: any, record: Photoshoot) => {
                    return record.type.name;
                },
            },
            {
                title: 'Klient',
                dataIndex: 'client',
                render: (data: Client) => {
                    let name = data.fullName;
                    if (name == '') {
                        name = data.email;
                    }

                    return (
                        <Space>
                            <Link to={`/app/clients/${data._id}`}>{name}</Link>
                        </Space>
                    );
                },
            },
            {
                title: 'Akcja',
                render: (data: any, record: Photoshoot) => {
                    return (
                        <Space>
                            {buttons.map((btn: any, index: any) => {
                                return btn(record);
                            })}
                        </Space>
                    );
                },
            },
        ];

        return (
            <Table
                loading={tableLoading}
                dataSource={photoshoots && photoshoots.filter((photoshoot: any) => photoshoot.status == props.status)}
                columns={columns}
                rowClassName="cursor-pointer"
                onRow={(client: Photoshoot, rowIndex) => {
                    return {
                        onClick: (e: any) => {
                            if (
                                e.target.nodeName == 'BUTTON' ||
                                e.target.parentNode.nodeName == 'BUTTON' ||
                                e.target.nodeName == 'A'
                            ) {
                                return;
                            }

                            history.push(`/app/photoshoots/${client._id}`);
                        },
                    };
                }}
            ></Table>
        );
    };

    return (
        <Container>
            <Heading size="small" weight="normal">
                Lista sesji
            </Heading>

            <Content>
                <Tabs onChange={onTabChange} defaultActiveKey={activeTab} tabBarStyle={{ marginBottom: '3em' }}>
                    <TabPane
                        tab={
                            <Badge
                                offset={[6, 4]}
                                color={photoshootsAwaitingConfirmation > 0 ? Colors.accents.main[800] : 'transparent'}
                                dot={photoshootsAwaitingConfirmation > 0}
                            >
                                Oczekujące potwierdzenia
                            </Badge>
                        }
                        key="photoshoot-awaiting"
                    >
                        <PhotoshootTable status={PhotoshootStatus.AWAITING_USER_CONFIRMATION} />
                    </TabPane>
                    <TabPane tab="Odrzucone" key="photoshoot-rejected">
                        <PhotoshootTable status={PhotoshootStatus.REJECTED} />
                    </TabPane>
                    <TabPane tab="Anulowane" key="photoshoot-canceled">
                        <PhotoshootTable status={PhotoshootStatus.CANCELED} />
                    </TabPane>
                    <TabPane tab="Potwierdzone" key="photoshoot-confirmed">
                        <PhotoshootTable status={PhotoshootStatus.CONFIRMED} />
                    </TabPane>
                    <TabPane tab="Zakończone" key="photoshoot-finished">
                        <PhotoshootTable status={PhotoshootStatus.FINISHED} />
                    </TabPane>
                </Tabs>
            </Content>
        </Container>
    );
}
