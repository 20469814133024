import React, { useEffect, useRef, useState } from 'react';
import Heading from '../../../../../components/Heading';
import styled from 'styled-components';
import { useGlobalState } from '../../../../../state';
import { Tabs, Button, Badge, Table, message, Popover, Space } from 'antd';
import { get } from '../../../../../services/Requests';
import AddClient from './addClient';
import Client from '../../../../../interfaces/Client';
import { useHistory } from 'react-router-dom';

const Container = styled.div`
    margin-top: -1em;
`;

const Content = styled.div`
    margin-top: 2em;
`;

export default function ClientsList() {
    const [user] = useGlobalState('user');

    const [tableLoading, setTableLoading] = useState(false);
    const [clients, setClients] = useState([]);
    const history = useHistory();

    const [addClientModalVisible, setAddClientModalVisible] = useState(false);

    useEffect(() => {
        loadClients();
    }, []);

    const loadClients = async () => {
        setTableLoading(true);
        try {
            let response = await get(`clients`);

            if (!response.success) {
                message.error('Nie udało się załadować listy klientów. Spróbuj ponownie później.');
                return;
            }

            setClients(response.data);

            setTableLoading(false);
        } catch (err) {
            message.error('Wystąpił błąd podczas ładowania listy klientów.');
        }
    };

    const columns: any[] = [
        {
            title: 'Adres e-mail',
            dataIndex: 'email',
        },
        {
            title: 'Imię i nazwisko',
            dataIndex: 'fullNameAlias',
        },
        {
            title: 'Akcja',
            render: (data: any, client: Client) => {
                return (
                    <Space>
                        <Button onClick={() => history.push(`/app/messages?client=${client._id}`)} size="small">
                            Napisz wiadomość
                        </Button>
                    </Space>
                );
            },
        },
    ];

    const handleAddClient = () => {
        setAddClientModalVisible(true);
    };

    const hideModal = () => {
        setAddClientModalVisible(false);
    };

    return (
        <Container>
            <Heading size="small" weight="normal">
                Lista klientów
            </Heading>

            <Content>
                <Button onClick={handleAddClient}>Dodaj klienta</Button>
                <Table
                    rowClassName="cursor-pointer"
                    onRow={(client: Client, rowIndex) => {
                        return {
                            onClick: (e: any) => {
                                if (e.target.nodeName == 'BUTTON' || e.target.parentNode.nodeName == 'BUTTON') {
                                    return;
                                }

                                history.push(`/app/clients/${client._id}`);
                            },
                        };
                    }}
                    style={{ marginTop: 24 }}
                    loading={tableLoading}
                    dataSource={clients}
                    columns={columns}
                ></Table>
            </Content>

            <AddClient
                onFinished={loadClients}
                new={true}
                visible={addClientModalVisible}
                hideModalFunction={hideModal}
            />
        </Container>
    );
}
